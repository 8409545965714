import React, { useState, useEffect } from "react";
import Event from "./Event";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import "./OfferList.css";

// Teach Autosuggest how to calculate suggestions for any given input value.

function OfferListApi(props) {
  const [offers, setOffers] = useState(null);

  const url =
    props.type === "events"
      ? "https://lb.prod.zt.softwareforge.io/api/news?wf=true"
      : "https://lb.prod.zt.softwareforge.io/api/promotions?wf=true";
  const [selected_state, setSelected] = useState(null);

  useEffect(() => {
    fetchOffers();
  });
  function fetchOffers() {
    fetch(url)
      .then((response) => response.json())
      .then((responseJson) => {
        setOffers(responseJson.results);
      });
  }
  function renderOffers() {
    return offers.map((off, i) => {
      if (i <= 15) {
        return (
          <Event
            key={"event-" + i}
            event_date={off.event_start_date}
            title={off.title}
            image={off.display ? off.display : null}
            pressEvent={() => showOffer(off)}
          ></Event>
        );
      }
      return null;
    });
  }
  function showOffer(off) {
    //   console.log(off);
    setSelected(off);
  }
  function closeOffer() {
    setSelected(null);
  }

  function renderOffer() {
    return (
      <div className="InnerContent">
        <div className="Close" onClick={closeOffer}>
          <Icon path={mdiClose} title="Back" size={2} color="black" />
        </div>
        {selected_state.display ? (
          <div className="ImageWrapper">
            <img src={selected_state.display} alt="offer_image" />
          </div>
        ) : null}
        <div className="TextWrapper">
          <div
            className="Description"
            dangerouslySetInnerHTML={{ __html: selected_state.content }}
          ></div>
        </div>
      </div>
    );
  }
  return (
    <div className="OfferList" id={props.id}>
      <div className="ListContainer">{offers ? renderOffers() : null}</div>
      <div
        className={selected_state ? "OfferContainer show" : "OfferContainer"}
      >
        {selected_state ? renderOffer() : null}
      </div>
    </div>
  );
}

export default OfferListApi;
