import React, { useState, useEffect } from "react";

import Svg, {
  G,
  Path,
  Polygon,
  Rect,
  Text as SvgText,
  Use,
  Defs,
  TSpan,
} from "react-native-svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useTranslation } from "react-i18next";

function Floor(props) {
  const { t } = useTranslation();
  const [pressed_item_state, setPressedItem] = useState(null);
  const [active_item_state, setActiveItem] = useState(null);

  //const points = getAllPoints();
  useEffect(() => {
    setPressedItem(props.pressedItem);
    setActiveItem(props.activeItem);
  }, [props]);

  function lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col && col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  function renderNavigation() {
    return (
      <Path
        fillOpacity="0"
        d={props.navigationPath}
        stroke="black"
        strokeWidth="10"
        strokeDasharray="10"
      />
    );
  }
  function renderSingleSvg(svg, number, active) {
    // console.log(number);
    switch (svg.type) {
      case "g":
        if (svg.children) {
          let children = [];
          svg.children.forEach((el) => {
            if (
              active === undefined &&
              number &&
              pressed_item_state &&
              number === pressed_item_state?.shop_id
            ) {
              active = true;
            }
            children.push(renderSingleSvg(el, number, active));
          });
          if (number) {
            return (
              <G
                onClick={() => {
                  pressStore(number);
                }}
                transform={svg.transform}
              >
                {children}
              </G>
            );
          } else {
            return <G transform={svg.transform}>{children}</G>;
          }
        }
        break;
      case "path":
        return (
          <Path
            fill={
              active
                ? svg.class.indexOf("interactive-map__shop-side") >= 0
                  ? lightenDarkenColor(pressed_item_state.category.color, -50)
                  : svg.class.indexOf("interactive-map__shop-top") >= 0
                  ? pressed_item_state.category.color
                  : svg.class_fill
                : svg.class_fill
            }
            d={svg.d}
            transform={svg.transform}
          />
        );
      case "polygon":
        return (
          <Polygon
            points={svg.points}
            fill={
              active
                ? svg.class.indexOf("interactive-map__shop-side") >= 0
                  ? lightenDarkenColor(pressed_item_state.category.color, -50)
                  : svg.class.indexOf("interactive-map__shop-top") >= 0
                  ? pressed_item_state.category.color
                  : svg.class_fill
                : svg.class_fill
            }
            transform={svg.transform}
          />
        );
      case "rect":
        return (
          <Rect
            x={svg.x}
            y={svg.y}
            width={svg.width}
            height={svg.height}
            fill={
              active
                ? svg.class.indexOf("interactive-map__shop-side") >= 0
                  ? lightenDarkenColor(pressed_item_state.category.color, -50)
                  : svg.class.indexOf("interactive-map__shop-top") >= 0
                  ? pressed_item_state.color
                  : svg.class_fill
                : svg.class_fill
            }
            transform={svg.transform}
          />
        );
      case "text":
        if (svg.children) {
          let children = [];
          svg.children.forEach((el) => {
            if (
              active === undefined &&
              number &&
              pressed_item_state &&
              number === pressed_item_state?.number
            ) {
              active = true;
            }
            children.push(renderSingleSvg(el, number, active));
          });
          return (
            <SvgText
              stroke="#ffffff"
              fontSize={10}
              fill="#ffffff"
              transform={svg.transform}
            >
              {children}
            </SvgText>
          );
        } else {
          return (
            <SvgText
              stroke="#ffffff"
              fontSize={10}
              fill="#ffffff"
              transform={svg.transform}
            >
              {svg.content}
            </SvgText>
          );
        }
      case "tspan":
        return (
          <TSpan
            stroke="#ffff"
            fontSize={10}
            fill="#ffffff"
            transform={svg.transform}
          >
            {svg.content}
          </TSpan>
        );
      default:
        return null;
    }
  }
  function unEntity(str) {
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }
  function renderSkewed(floor) {
    let newMap = [];
    Object.keys(props.shops[floor]).forEach((key) => {
      newMap.push(renderSingleSvg(props.shops[floor][key].svg_data, key));
    });

    return (
      <Svg height={1080} width={1080} viewBox="0 0 900 600">
        <Defs>
          <G id="shape">
            <G>
              <Path
                scale={0.15}
                fill="black"
                d={
                  "M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
                }
              />
            </G>
          </G>
          <G id="wfmarker">
            <G>
              <Path
                scale={0.12}
                fill="#FFDF00"
                strokeWidth="10"
                stroke="#ab8a2a"
                d={
                  "M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
                }
              />
            </G>
          </G>
        </Defs>
        {newMap}
        {props.marker ? (
          <Use href="#shape" x={props.marker.x - 10} y={props.marker.y - 60} />
        ) : null}
        {props.wfMarker && props.wfMarker.z === props.currentFloor ? (
          <Use
            href="#wfmarker"
            x={props.wfMarker.x - 20}
            y={props.wfMarker.y - 70}
          />
        ) : null}
        {props.navigationPath ? renderNavigation() : null}
        {props.floorMarker ? (
          <G
            x={
              props.floorMarker.x >= 450
                ? props.floorMarker.x - 450
                : props.floorMarker.x
            }
            y={
              props.floorMarker.y <= 100
                ? props.floorMarker.y + 100
                : props.floorMarker.y
            }
          >
            <G transform="skewX(-10)">
              <Rect
                width="450"
                height="100"
                fill="#eee"
                stroke="#ddd"
                strokeWidth="2"
              />
            </G>
            <G transform="skewX(-10)">
              <Rect
                onClick={() => {
                  props.navigateNextPath(props.nextIndex);
                }}
                width="350"
                height="50"
                x="50"
                y="75"
                fill="#000"
              />
            </G>
            <SvgText
              textAnchor="middle"
              fontSize="30"
              fontWeight="bold"
              onClick={() => {
                props.navigateNextPath(props.nextIndex);
              }}
              fontFamily="MyFont"
              fill="#000"
              x="225"
              y="50"
              letterSpacing="2"
            >
              {props.floorMarker.nextFloor > props.currentFloor
                ? t("navigation.go_to_up") +
                  " " +
                  String(parseInt(props.floorMarker.nextFloor))
                : t("navigation.go_to") +
                  " " +
                  String(parseInt(props.floorMarker.nextFloor))}
            </SvgText>
            <SvgText
              textAnchor="middle"
              fontSize="20"
              fontWeight="bold"
              letterSpacing="1"
              onClick={() => {
                props.navigateNextPath(props.nextIndex);
              }}
              fontFamily="MyFont"
              fill="#fff"
              x="150"
              y="105"
            >
              {t("common.next")}
            </SvgText>
          </G>
        ) : null}
        {pressed_item_state ? (
          <G
            id="storeInfo"
            x={
              pressed_item_state.x >= 480
                ? pressed_item_state.x - 300
                : pressed_item_state.x
            }
            y={
              pressed_item_state.y <= 100
                ? pressed_item_state.y + 100
                : pressed_item_state.y
            }
            onClick={() => {
              props.goToShop();
            }}
          >
            <G transform="skewX(-10)">
              <Rect
                width="300"
                height="80"
                fill="#eee"
                stroke="#ddd"
                strokeWidth="2"
              ></Rect>
            </G>
            <G transform="skewX(-10)">
              <Rect width="220" height="50" x="40" y="60" fill="#000"></Rect>
            </G>
            <SvgText
              textAnchor="middle"
              fontSize="20"
              fontWeight="bold"
              fontFamily="MyFont"
              fill="#000"
              x="150"
              y="40"
              letterSpacing="2"
            >
              {unEntity(pressed_item_state.shop_title)}
            </SvgText>
            <SvgText
              textAnchor="middle"
              fontSize="16"
              fontWeight="bold"
              letterSpacing="1"
              fontFamily="MyFont"
              fill="#fff"
              x="140"
              y="90"
            >
              {t("navigation.open_store")}
            </SvgText>
          </G>
        ) : null}
        {active_item_state ? (
          <G
            id="storeInfo"
            x={
              active_item_state.x >= 480
                ? active_item_state.x - 300
                : active_item_state.x
            }
            y={
              active_item_state.y <= 100
                ? active_item_state.y + 100
                : active_item_state.y
            }
          >
            <G transform="skewX(-10)">
              <Rect
                width="300"
                height="80"
                fill="#eee"
                stroke="#ddd"
                strokeWidth="2"
              ></Rect>
            </G>
            <G transform="skewX(-10)">
              <Rect width="220" height="50" x="40" y="60" fill="#000"></Rect>
            </G>
            <SvgText
              textAnchor="middle"
              fontSize="20"
              fontWeight="bold"
              fontFamily="MyFont"
              fill="#000"
              x="150"
              y="40"
              letterSpacing="2"
            >
              {unEntity(active_item_state.shop_title)}
            </SvgText>
            <SvgText
              textAnchor="middle"
              fontSize="16"
              fontWeight="bold"
              letterSpacing="1"
              fontFamily="MyFont"
              fill="#fff"
              x="140"
              y="90"
            >
              {t("navigation.open_store")}
            </SvgText>
          </G>
        ) : null}
      </Svg>
    );
  }
  function pressStore(nid) {
    // console.log("PRESED", nid);
    if (pressed_item_state && pressed_item_state.shop_id === nid) {
      props.pressStore(null);
    } else {
      setActiveItem(null);
      props.setActiveItem(null);
      try {
        let responseJson = props.findPathByNids(nid, nid);
        // console.log(responseJson);
        if (responseJson) {
          let shop_id = null;
          if (nid.startsWith("S")) {
            shop_id = props.storeMap[nid];
          } else {
            shop_id = nid;
          }
          let toShop = props.shops[props.currentFloor][shop_id];

          let found = false;
          for (const [, value] of Object.entries(props.ztShops)) {
            for (const s in value.shops) {
              for (const sl in value.shops[s].shop_locals) {
                if (value.shops[s].shop_locals[sl][0] === shop_id) {
                  found = true;
                  //console.log({category: value.category, shop_id: shop_id, name:toShop.shop_name, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, ...value.shops[s]})
                  props.setPressedItem({
                    category: value.category,
                    shop_id: shop_id,
                    name: toShop.shop_name,
                    x: responseJson[0].x,
                    y: responseJson[0].y,
                    z: parseFloat(responseJson[0].z) + 2,
                    ...value.shops[s],
                  });
                  break;
                }
              }
              if (found === true) {
                break;
              }
            }
            if (found === true) {
              break;
            }
          }
          //     let cat = props
          //     let s = props.shops[x]
          //     if(s.number === shop_id){
          //         props.setPressedItem({category: toShop.category, shop_id: nid, name:toShop.title, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, ...s})
          //     break;
          //     }
          // }

          //props.setPressedItem({category: toShop.category, shop_id: nid, name:toShop.title, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, number: nid})

          //props.pressStore(shop_id);
        }
      } catch (e) {
        // console.log(e);
        alert(t("alert.store_error_info"));
      }
    }
  }
  return (
    <div className="Floor">
      <TransformWrapper>
        <TransformComponent>
          {renderSkewed(props.currentFloor)}
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}

export default Floor;
