import React, { useState, useRef, useEffect } from "react";
import "./ShopList.css";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import NEW_SHOPS from "./constants/shops_categories";

import Back from "./Back";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const Entities = require("html-entities").AllHtmlEntities;
const entities = new Entities();
// Teach Autosuggest how to calculate suggestions for any given input value.
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function ShopList(props) {
  const node = useRef();
  const keyboardInput = useRef();
  const { t, i18n } = useTranslation();
  const [active_category_state, setActiveCat] = useState("restauracje");
  const [suggestions_state, setSuggestions] = useState([]);
  const [setSuggestion] = useState("");
  const [value_state, setValue] = useState("");
  const [selected_state, setSelected] = useState(null);
  const [show_keyboard_state, setShowKeyboard] = useState(null);

  const softKeyboard = getParameterByName("soft_keyboard");

  useEffect(() => {
    // console.log(softKeyboard)
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [softKeyboard]);
  const handleClick = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setShowKeyboard(false);
    } else {
    }
    // outside click
  };
  function hideModule() {
    if (softKeyboard) {
      setShowKeyboard(false);
    }
    props.hideModule();
  }
  function unEntity(str) {
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }
  function getShopsCats() {
    let shops = [];
    let shopCategories = {};
    let categoryColors = {};
    Object.keys(NEW_SHOPS.results).forEach((category) => {
      NEW_SHOPS.results[category].shops.forEach((shop) => {
        let img_url = "/header_logo.png";
        if (!shop.display_url) {
          shop.display_url = img_url;
        }
        shops.push(shop);
      });
    });
    // SHOPS.shops.forEach(element => {
    //     let img_url = '/header_logo.png'
    //     if(element.acf.gallery.length > 0 || element.acf.shops_logo){

    //         if(element.acf.shops_logo && element.acf.shops_logo.sizes){
    //             img_url = element.acf.shops_logo.sizes.medium
    //         }else{
    //             img_url = element.acf.gallery[0].url
    //         }
    //     }
    //         let el = {
    //             name: entities.decode(element.title),
    //             title: entities.decode(element.title),
    //             link: element.link,
    //             content: element.content,
    //             content_en: element.content_en,
    //             image: img_url,
    //             number: element.id.toString(),
    //             floor: element.acf.loc_floor[0],
    //             floors: element.acf.loc_floor,
    //             locals: element.acf.loc_local,
    //             category: element.category,
    //             opening_hours: element.acf.opening_hours,
    //             info: element.acf.info
    //         }
    //         shops.push(el);

    // });
    // shopCategories = SHOPS.categories
    // Object.keys(shopCategories).forEach( i => {
    //     let el = shopCategories[i];
    //     categoryColors[el.id] = {name: el.title, color: el.color}
    // });
    return {
      shops: shops,
      loadedShops: true,
      shopCategories: shopCategories,
      categoryColors: categoryColors,
      loadedCategories: true,
    };
  }
  const shopsData = getShopsCats();

  function getSuggestions(value) {
    // console.log(value);
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let results =
      inputLength === 0
        ? []
        : shopsData.shops
            .filter(
              (shop) => shop.shop_title.toLowerCase().indexOf(inputValue) >= 0
            )
            .map((el) => el.shop_title);
    // console.log(results);
    return results;
  }
  //   const getSuggestionValue = suggestion => suggestion.shop_title;

  // function onSuggestionsFetchRequested({value}) {
  //     setSuggestions(getSuggestions(value));

  //   };

  // Autosuggest will call this function every time you need to clear suggestions.
  //   function onSuggestionsClearRequested() {
  //     setSuggestions([]);
  //   };
  function onChange(event) {
    if (event.target.value) {
      setSuggestions(getSuggestions(event.target.value));
    } else {
      setSuggestions([]);
    }
    setValue(event.target.value);
  }
  function onChangeKeyboard(input) {
    setSuggestions(getSuggestions(input));
    setSuggestion(input);
    setValue(input);
  }
  function renderCategories() {
    return Object.keys(NEW_SHOPS.results).map((key) => {
      let value = NEW_SHOPS.results[key];
      return (
        <div
          key={key}
          onClick={() => {
            setActiveCat(key);
          }}
          className="CategoryTile"
          style={{
            flexDirection: "row",
            alignSelf: "flex-start",
            boxShadow: "rgba(0,0,0,0.25)  0px 2px 6px 2px",
            backgroundColor: value.category.color
              ? value.category.color
              : "black",
            shadowOffset: { width: -2, height: 2 },
            shadowRadius: 4,
            zIndex: 10,
            shadowOpacity: 1,
            margin: 5,
            padding: "5px 15px",
            transform: "skewX(-10deg)",
            border: active_category_state === key ? "4px solid black" : "none",
            boxSizing: "border-box",
          }}
        >
          <div
            style={
              active_category_state === key
                ? {
                    fontFamily: "MyFont",
                    color: "black",
                    letterSpacing: 2,
                    lineHeight: "18px",
                    fontSize: 20,
                    transform: "skewX(10deg)",
                  }
                : {
                    transform: "skewX(10deg)",
                    fontFamily: "MyFont",
                    color: "white",
                    letterSpacing: 2,
                    fontSize: 18,
                  }
            }
          >
            {i18n.language === "pl"
              ? entities.decode(value.category.name.pl)
              : entities.decode(value.category.name.en)}
          </div>
        </div>
      );
    });
  }
  function showShop(shop) {
    setSelected(shop);
  }
  function closeShop() {
    setSelected(null);
  }
  function renderShop() {
    return (
      <div className="InnerContent">
        <div className="Close" id="CloseShop" onClick={closeShop}>
          <Icon path={mdiClose} title="Back" size={2} color="black" />
        </div>
        <div className="Sidebar">
          <div className="TitleWrapper">
            <div className="Text">{selected_state.title}</div>
          </div>
          {selected_state.shop_info ? (
            <div className="Info">
              <div className="ContactHeader">{t("shop.contact")}</div>
              {selected_state.shop_info.map((el) => {
                if (el[0]) {
                  return (
                    <div className="InfoElement">
                      <div className="InfoLabel">{el[0]}</div>
                      <div className="InfoValue Small">{el[1]}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
          <div className="Location">
            <div className="InfoLine">
              <div className="LocationText">{t("shop.floor")}</div>
              <div className="LocationNumber">{selected_state.shop_floors}</div>
            </div>
            <div className="InfoLine">
              <div className="LocationText">{t("shop.venue")}</div>
              {selected_state.shop_numbers ? (
                <div className="LocationNumber">
                  {selected_state.shop_numbers}
                </div>
              ) : null}
            </div>
          </div>
          <div className="ButtonWrapper">
            {selected_state.locals ? (
              <div
                className="FloorButton"
                onClick={() =>
                  props.openNavigationShop(selected_state.shop_locals[0][0])
                }
              >
                <div className="Text">{t("shop.map")}</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="Content">
          <div className="ImageWrapper">
            <img src={selected_state.display_image} alt="" />
          </div>
          <div className="TextWrapper">
            {i18n.language === "en" ? (
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: unEntity(selected_state.shop_description),
                }}
              ></div>
            ) : (
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: unEntity(selected_state.shop_description),
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    );
  }
  function onFocus() {
    setShowKeyboard(true);
  }
  function renderShops() {
    return Object.keys(NEW_SHOPS.results).map((key) => {
      let value = NEW_SHOPS.results[key];
      return value.shops.map((el) => {
        if (suggestions_state.length) {
          if (suggestions_state.indexOf(el.shop_title) >= 0) {
            return (
              <div className="StoreTile" onClick={() => showShop(el)}>
                <div
                  className="Category"
                  style={{ backgroundColor: value.category.color }}
                ></div>
                <img className="TileImage" alt="" src={el.display_image} />
                <div className="Title">{entities.decode(el.shop_title)}</div>
                <div className="FloorWrapper">
                  <div className="FloorInner">
                    {t("shop.floor")} {el.shop_floors}
                  </div>
                </div>
              </div>
            );
          }
        } else if (
          active_category_state === "all" ||
          active_category_state === key
        ) {
          return (
            <div className="StoreTile" onClick={() => showShop(el)}>
              {shopsData.categoryColors[el.category] ? (
                <div
                  className="Category"
                  style={{ backgroundColor: value.category.color }}
                ></div>
              ) : null}
              <img className="TileImage" alt="" src={el.display_image} />
              <div className="Title">{entities.decode(el.shop_title)}</div>
              <div className="FloorWrapper">
                <div className="FloorInner">
                  {t("shop.floor")} {el.shop_floors}
                </div>
              </div>
            </div>
          );
        }
        return null;
      });
    });
  }

  return (
    <div className="ShopList">
      <Back onBack={() => hideModule()} id="BackShopList" />
      {show_keyboard_state && softKeyboard ? (
        <div className="Keyboard" ref={node}>
          <Keyboard
            onChange={onChangeKeyboard}
            layout={{
              default: [
                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                "Q W E R T Y U I O P",
                "A S D F G H J K L &",
                "Z X C V B N M , . /",
                "{space}",
              ],
            }}
            layoutName={"default"}
            keyboardRef={(r) => (keyboardInput.current = r)}
          />
        </div>
      ) : null}
      <div className="SearchContainer">
        <div className="Search">
          <input
            onFocus={onFocus}
            className="SearchInput"
            onChange={onChange}
            value={value_state}
            placeholder={"Szukaj..."}
          />
        </div>
      </div>
      <div
        className="Categories"
        style={{
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {renderCategories()}
      </div>
      <div className="ListContainer">{renderShops()}</div>
      <div className={selected_state ? "ShopContainer show" : "ShopContainer"}>
        {selected_state ? renderShop() : null}
      </div>
    </div>
  );
}

export default ShopList;
