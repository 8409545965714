const PROMOTIONS = {
    "results": [
    {
    "title": {
    "pl": "Super-Pharm oferta Black Week cz. II",
    "en": "Super-Pharm oferta Black Week cz. II"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/11/29/56dffbd0-2ab2-423b-9dcd-d26c0a2fc6a2.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/11/29/cache/56dffbd0-2ab2-423b-9dcd-d26c0a2fc6a2_display.jpg",
    "content": {
    "pl": "<p>Odwiedź salon Super-Pharm w Złotych Tarasach i odbierz zniżkę nawet 50% na całe marki.</p>",
    "en": "<p>Visit Super-Pharm salon in Zlote Tarasy and receive a discount of even 50% on the entire brand.</p>"
    }
    },
    {
    "title": {
    "pl": "Dzień Kobiet w Triumph",
    "en": "Women's Day at Triumph"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2022/03/07/6b808a1c-58d0-44f6-8fe7-55d38bc98cc2.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2022/03/07/cache/6b808a1c-58d0-44f6-8fe7-55d38bc98cc2_display.jpg",
    "content": {
    "pl": "<p>Odwiedź salon Triumph w Złotych Tarasach i odbierz rabat 20% przy zakupie 2 sztuk.</p>",
    "en": "<p>Visit Triumph salon in Zlote Tarasy and get 20% discount when you buy 2 pieces.</p>"
    }
    },
    {
    "title": {
    "pl": "Trendy Opticians – Jesteśmy otwarci",
    "en": "Trendy Opticians - We're open"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/0261b295-854e-4d30-970f-981e16f7ece1.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/0261b295-854e-4d30-970f-981e16f7ece1_display.jpg",
    "content": {
    "pl": "<p>Nasz salon w Złotych Tarasach dalej pozostaje otwarty. Zapraszamy na badanie wzroku już dziś.</p>\n",
    "en": "<p>Our salon in Złote Tarasy is still open. We invite you for an eye test today.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Wiosenne porządki w Super-Pharm",
    "en": "Spring cleaning in Super-Pharm"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/3cc98f23-24a7-4a8b-a465-8145e749d613.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/3cc98f23-24a7-4a8b-a465-8145e749d613_display.jpg",
    "content": {
    "pl": "<p>Zrób wiosenne porządki w swojej kosmetyczce. W Super-Pharm mnóstwo ofert z rabatem do 55%!</p>\n",
    "en": "<p>Do some spring cleaning in your make-up bag. In Super-Pharm there are plenty of offers with discount up to 55%!</p>\n"
    }
    },
    {
    "title": {
    "pl": "-50% na drugi produkt Kuchnia i jadalnia",
    "en": "-50% on the second product Kitchen &amp; Dining"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/a4a01b27-4ad1-42c6-a87a-89b1c6b301cc.png",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/a4a01b27-4ad1-42c6-a87a-89b1c6b301cc_display.png",
    "content": {
    "pl": "<p>Odwiedź lokal Homla w Złotych Tarasach od i odbierz 50% zniżki na artykuły z sekcji kuchnia i jadalnia.</p>\n",
    "en": "<p>Visit Homla outlet in Zlote Tarasy from and receive 50% discount on items from the kitchen and dining room section.</p>\n"
    }
    },
    {
    "title": {
    "pl": "5 za 5 w KFC",
    "en": "5 for 5 at KFC"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/7dd8c6a2-eb80-4f22-bc66-7faeee023073.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/7dd8c6a2-eb80-4f22-bc66-7faeee023073_display.jpg",
    "content": {
    "pl": "<div><strong>Tylko teraz!</strong></div>\n<div><strong>W KFC Collect 5 soczystych Hot Wings za jedynie 5 złotych!  </strong></div>\n",
    "en": "<div><strong>Only now!</strong></div>\n<div><strong>In KFC Collect 5 juicy Hot Wings for only 5 PLN!  </strong></div>\n"
    }
    },
    {
    "title": {
    "pl": "Nowości do włosów od L’Occitane",
    "en": "New products for hair from L'Occitane"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/7189efa1-dcd0-4178-b3ae-fb715d3deb97.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/7189efa1-dcd0-4178-b3ae-fb715d3deb97_display.jpg",
    "content": {
    "pl": "<p>Nowości do pielęgnacji włosów już dostępne w L&#8217;Occitane.</p>\n",
    "en": "<p>New hair care products now available at L'Occitane.</p>\n"
    }
    },
    {
    "title": {
    "pl": "SUMMER SALE DO -70% W SWISS!",
    "en": "SUMMER SALE UP TO -70% AT SWISS!"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/137e983b-e38f-4e86-a4d7-0b3e0fca2483.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/137e983b-e38f-4e86-a4d7-0b3e0fca2483_display.jpg",
    "content": {
    "pl": "<p>Teraz w butikach SWISS kupisz zegarki w jeszcze lepszej cenie!</p>\n<p>Niektóre modele aż do 70% taniej!</p>\n<p>Zobacz naszą ofertę w butiku SWISS lub na swiss.com.pl</p>\n",
    "en": "<p>Now you can buy watches in SWISS at even better prices!</p>\n<p>Some models up to 70% off!</p>\n<p>Check out our offer in SWISS boutique or on swiss.com.pl</p>\n"
    }
    },
    {
    "title": {
    "pl": "Wyprzedaż – bielizna nocna",
    "en": "Sale - Nightwear"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/c6b8804a-4051-45d3-9a1d-516a1a5da222.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/c6b8804a-4051-45d3-9a1d-516a1a5da222_display.jpg",
    "content": {
    "pl": "<p>Dodatkowe 20% rabatu przy zakupie 3 produktów.</p>\n<p>&nbsp;</p>\n<p>Oferta dostępna w salonach oraz w sklepie online.</p>\n",
    "en": "<p>Additional 20% discount with purchase of 3 products.</p>\n<p></p>\n<p>Offer available in showrooms and online store.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Final Okazji RTV Euro AGD",
    "en": "Final Occasions RTV Euro AGD"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/27a67996-a95b-44af-8464-6279482d6c56.png",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/27a67996-a95b-44af-8464-6279482d6c56_display.png",
    "content": {
    "pl": "<p><b>Emocje rosną! Ceny spadają! Jesteśmy w finale Euro-okazji.</b></p>\n",
    "en": "<p><b>Emotions are rising! Prices are dropping! We're in the finals of the Euro bargain.</b></p>\n"
    }
    },
    {
    "title": {
    "pl": "Aż do -40% na mleczka do ciała L’Occitane",
    "en": "Up to -40% on L'Occitane body lotions"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/397247b6-d649-417e-afd9-72aa678d318d.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/397247b6-d649-417e-afd9-72aa678d318d_display.jpg",
    "content": {
    "pl": "<p>Promocja w L&#8217;Occitane na poziomie 0 &#8211; aż do 40% zniżki na mleczka do ciała.</p>\n",
    "en": "<p>Promotion in L'Occitane on level 0 - up to 40% discount on body lotions.</p>\n"
    }
    },
    {
    "title": {
    "pl": "WITTCHEN: Kiermasz Świąteczny",
    "en": "WITTCHEN: Christmas Market"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/c0df84f7-c9be-4974-abcf-e6fe1cff5911.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/c0df84f7-c9be-4974-abcf-e6fe1cff5911_display.jpg",
    "content": {
    "pl": "<p>Bogata oferta walizek, torebek, portfeli, galanterii skórzanej i akcesoriów w cenach niższych o -70%</p>\n",
    "en": "<p>Wide range of suitcases, handbags, wallets, leather goods and accessories at prices lower by -70%.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Promocja Le Creuset",
    "en": "Le Creuset promotion"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/17f28cda-42e6-4507-898c-79a9b2ea78da.png",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/17f28cda-42e6-4507-898c-79a9b2ea78da_display.png",
    "content": {
    "pl": "<p>Odwiedź salon Le Creuset w Złotych Tarasach i odbierz zniżkę na wybrane artykuły.</p>\n",
    "en": "<p>Visit Le Creuset salon in Zlote Tarasy and receive a discount on selected items.</p>\n"
    }
    },
    {
    "title": {
    "pl": "96h turbo okazji",
    "en": "96h turbo opportunity"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2022/04/27/2a2e3a43-a089-4293-9c69-2a19280ecd5e.png",
    "display": "https://content.zlotetarasy.pl/static/media/2022/04/27/cache/2a2e3a43-a089-4293-9c69-2a19280ecd5e_display.png",
    "content": {
    "pl": "<p>96h turbo okazji w RTV EURO AGD. Tylko do soboty!</p>",
    "en": "<p>96 hours of turbo bargains in RTV EURO AGD. Only till Saturday!</p>"
    }
    },
    {
    "title": {
    "pl": "Dzień Kobiet w Shop Dent",
    "en": "Women's Day at Shop Dent"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/3d0bebb6-5588-434b-8e7e-bb8a5b576f7c.png",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/3d0bebb6-5588-434b-8e7e-bb8a5b576f7c_display.png",
    "content": {
    "pl": "<div>Dzień kobiet w Shop Dent</div>\n<div>Odwiedź Shop Dent w Złotych Tarasach i zobacz co dla Ciebie mamy!</div>\n<div></div>\n<div>Promocja ważna do 15.03, lub do wyczerpania zapasów.</div>\n",
    "en": "<div>Women's Day at Shop Dent</div>\n<div>Visit Shop Dent in Złote Tarasy and see what we have for you!</div>\n<div></div>\n<div>Promotion valid till 15.03, or while stocks last.</div>\n"
    }
    },
    {
    "title": {
    "pl": "WITTCHEN KIERMASZ DO -70%",
    "en": "WITTCHEN -70% FAIR"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/8d6f9401-b3e7-42ba-b043-0efc37145273.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/8d6f9401-b3e7-42ba-b043-0efc37145273_display.jpg",
    "content": {
    "pl": "<p>Przyjdź do salonu WITTCHEN w Złotych Tarasach i odbierz zniżkę do &#8211; 70% na wybrane artykuły.</p>\n",
    "en": "<p>Come to WITTCHEN salon in Złote Tarasy and get a discount up to 70% on selected items.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Dużo dobra w H&M",
    "en": "Lots of good stuff at H&amp;M"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/e9021c27-11af-4691-879f-c1aeccce06d9.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/e9021c27-11af-4691-879f-c1aeccce06d9_display.jpg",
    "content": {
    "pl": "<p>W tym tygodniu czeka Cię dużo dobra w salonie H&amp;M w Złotych Tarasach. Modowa marka przygotowała kolekcję kapsułową pełną dzianin wykonanych z bardziej zrównoważonych materiałów.</p>\n",
    "en": "<p>This week a lot of good awaits you in H&amp;M salon in Zlote Tarasy. The fashion brand has prepared a capsule collection full of knitwear made of more sustainable materials.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Wyjątkowa oferta rabatowa na Dzień Kobiet w @Etam",
    "en": "Unique discount offer for Women's Day at @Etam"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/da69b13f-c1cb-4a76-930b-83a89a82b73c.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/da69b13f-c1cb-4a76-930b-83a89a82b73c_display.jpg",
    "content": {
    "pl": "<p style=\"font-weight: 400;\">Wyjątkowa oferta na Dzień Kobiet! Zrób zakupy w @Etam w dniach 4-8.03. Odbierz 50 zł rabatu przy zakupie za 300 zł oraz 100 zł rabatu przy zakupie za 500 zł.</p>\n",
    "en": "<p style=\"font-weight: 400;\">Special offer for Women's Day! Do your shopping in @Etam between 4-8.03. Receive 50 PLN discount with purchase of 300 PLN and 100 PLN discount with purchase of 500 PLN.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Dzień Kobiet w Super Prezentach",
    "en": "Women's Day in Super Gifts"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/07/13/848dd450-8595-4696-bed9-d6dc00831695.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/07/13/cache/848dd450-8595-4696-bed9-d6dc00831695_display.jpg",
    "content": {
    "pl": "<p>Z okazji Dnia Kobiet -20% na wszystkie prezenty z kategorii: masaże, uroda, zdrowie i relaks z okazji Dnia Kobiet.</p>\n",
    "en": "<p>In celebration of Women's Day -20% on all gifts in the categories: massage, beauty, health and relaxation for Women's Day.</p>\n"
    }
    },
    {
    "title": {
    "pl": "Promocja w Tatuum",
    "en": "Promotion in Tatuum"
    },
    "image": "https://content.zlotetarasy.pl/static/media/2021/09/08/2b0ee6b3-08e9-4738-ab6f-4f1dee92ee67.jpg",
    "display": "https://content.zlotetarasy.pl/static/media/2021/09/08/cache/2b0ee6b3-08e9-4738-ab6f-4f1dee92ee67_display.jpg",
    "content": {
    "pl": "<p>Zajrzyj do salonu Tatuum w Złotych Tarasach i odbierz zniżkę 20% na Dzień Kobiet.</p>\n",
    "en": "<p>Visit the Tatuum salon in Złote Tarasy and get a 20% discount for Women's Day.</p>\n"
    }
    }
    ],
    "pagination": {
    "more": true
    }
    }
const PROMOTION_IMAGES = {
};
export {PROMOTIONS, PROMOTION_IMAGES};
