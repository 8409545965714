import React from 'react';
import { useTranslation } from 'react-i18next';
import './Event.css';
import moment from "moment";

// Teach Autosuggest how to calculate suggestions for any given input value.


function Event(props) {
    const { i18n } = useTranslation();
    
    function renderDate(date) {
        return (
        <div className="Day">
            <div className="DayNum">{moment(date, 'YYYY-MM-DD HH:mm:ss').locale(i18n.language).format('DD')}</div>
            <div className="DayText">{moment(date, 'YYYY-MM-DD HH:mm:ss').locale(i18n.language).format('MMM')}</div>
        </div>
        );
      }


    
    

  return (
        <div onClick={props.pressEvent} className="Event" >
                <div style={{position:'absolute', width:'100%', height: '100%', backgroundSize: 'cover', backgroundImage: 'url(/static/zlote-logo-ikona.png)'}} alt=""/>
                <div style={{position:'absolute', width:'100%', height: '100%', backgroundSize: 'cover', backgroundImage: props.image ? 'url('+ props.image +')' : 'none'}} alt=""/>
                <div className="InfoWrapper">
                    {props.event_date ? renderDate(props.event_date) : null }
                    <div className="Title" dangerouslySetInnerHTML={{__html: props.title}}></div>
                </div>
        </div>
        )
}

export default Event;
