const NAVIGATION = {
  nodes: [
    {
      id: "262",
      x: 548.3444,
      y: 308.59494,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "263",
      x: 685.9875,
      y: 267.6033,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "264",
      x: 701.0155,
      y: 260.45187,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "265",
      x: 565.551,
      y: 316.52676,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "266",
      x: 717.417,
      y: 257.6609,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "267",
      x: 731.863,
      y: 260.0072,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "268",
      x: 561.48413,
      y: 311.2424,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "712",
      x: 351.49152,
      y: 307.73575,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "873",
      x: 517.2071,
      y: 214.73303,
      nid: "WC-001",
      name: "Toaleta",
      z: -1,
    },
    {
      id: "874",
      x: 530.9295,
      y: 239.73798,
      nid: "WC-002",
      name: "Toaleta dla niepe\u0142nosprawnych",
      z: -1,
    },
    {
      id: "911",
      x: 362.88882,
      y: 426.08325,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "912",
      x: 366.36966,
      y: 410.77313,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "913",
      x: 371.20932,
      y: 396.6885,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "914",
      x: 376.97607,
      y: 384.45242,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "915",
      x: 379.22592,
      y: 374.06894,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "916",
      x: 382.14093,
      y: 363.5244,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "917",
      x: 385.28854,
      y: 353.43945,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "918",
      x: 397.03934,
      y: 351.73367,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "919",
      x: 409.223,
      y: 349.40723,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "920",
      x: 421.37933,
      y: 345.5285,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "921",
      x: 593.4023,
      y: 306.90405,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "922",
      x: 605.5465,
      y: 301.4694,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "923",
      x: 578.6038,
      y: 312.9583,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "924",
      x: 621.25903,
      y: 297.27945,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "925",
      x: 558.9726,
      y: 306.11224,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "926",
      x: 636.7277,
      y: 291.06772,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "927",
      x: 324.59802,
      y: 420.5301,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "928",
      x: 326.59973,
      y: 409.70007,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "929",
      x: 327.97046,
      y: 400.56693,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "930",
      x: 330.49966,
      y: 393.75247,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "931",
      x: 332.53568,
      y: 384.45618,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "932",
      x: 334.60367,
      y: 376.70563,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "933",
      x: 339.32846,
      y: 368.33136,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "934",
      x: 340.73465,
      y: 360.72922,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "935",
      x: 346.6205,
      y: 354.6768,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "936",
      x: 353.77646,
      y: 345.20642,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "937",
      x: 360.50256,
      y: 336.35318,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "938",
      x: 373.27185,
      y: 329.20773,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "939",
      x: 384.25137,
      y: 333.40662,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "940",
      x: 408.8821,
      y: 342.7317,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "941",
      x: 369.10394,
      y: 308.83246,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "942",
      x: 406.39993,
      y: 303.24585,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "943",
      x: 420.07913,
      y: 296.40338,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "944",
      x: 436.42172,
      y: 288.93793,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "945",
      x: 431.52658,
      y: 276.00433,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "946",
      x: 427.3467,
      y: 266.024,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "947",
      x: 418.73465,
      y: 257.59436,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "948",
      x: 400.49924,
      y: 259.76538,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "949",
      x: 379.74377,
      y: 294.3591,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "950",
      x: 375.0561,
      y: 281.8927,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "951",
      x: 370.59644,
      y: 269.88458,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "952",
      x: 437.52795,
      y: 301.70367,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "953",
      x: 438.94827,
      y: 333.1096,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "954",
      x: 507.59576,
      y: 317.59375,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "955",
      x: 455.32455,
      y: 328.76437,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "956",
      x: 466.41223,
      y: 327.214,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "958",
      x: 483.68265,
      y: 323.17972,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "959",
      x: 539.53766,
      y: 266.41296,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "960",
      x: 533.3758,
      y: 255.34079,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "961",
      x: 530.60376,
      y: 248.16399,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "962",
      x: 519.7296,
      y: 235.04495,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "963",
      x: 519.2963,
      y: 220.51797,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "964",
      x: 359.75574,
      y: 343.8097,
      nid: "s-005",
      name: "Precle Krakowskie",
      z: "-1",
    },
    {
      id: "966",
      x: 503.78525,
      y: 285.70505,
      nid: "-014",
      name: "MANICURE_EXPRESS",
      z: "-1",
    },
    {
      id: "970",
      x: 547.6103,
      y: 287.44165,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "971",
      x: 554.86115,
      y: 297.7138,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "976",
      x: 655.06647,
      y: 283.14554,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "977",
      x: 669.42303,
      y: 276.15277,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "978",
      x: 545.2086,
      y: 355.00867,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "979",
      x: 562.1037,
      y: 357.8004,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "980",
      x: 555.55695,
      y: 382.11597,
      nid: null,
      name: "_waypoint",
      z: -1,
    },
    {
      id: "1074",
      x: 321.15683,
      y: 415.11716,
      nid: "-003",
      name: "Organic",
      z: -1,
    },
    {
      id: "1075",
      x: 331.5924,
      y: 370.65787,
      nid: "-002",
      name: "MCFLEK",
      z: -1,
    },
    {
      id: "1076",
      x: 366.7477,
      y: 418.97037,
      nid: "-004",
      name: "KUCHANIE \u015aWIATA",
      z: -1,
    },
    {
      id: "1077",
      x: 373.97684,
      y: 390.33914,
      nid: "-007",
      name: "RUCH",
      z: -1,
    },
    {
      id: "1078",
      x: 379.34116,
      y: 368.3322,
      nid: "-006",
      name: "FIVE O'CLOCK",
      z: -1,
    },
    {
      id: "1079",
      x: 412.51584,
      y: 347.07938,
      nid: "-022",
      name: "ROSSMANN",
      z: -1,
    },
    {
      id: "1085",
      x: 411.02274,
      y: 338.07333,
      nid: "s-006",
      name: "KRAKOWSKI KREDENS",
      z: -1,
    },
    {
      id: "1086",
      x: 361.2497,
      y: 328.89352,
      nid: "s-008",
      name: "DAMAS",
      z: -1,
    },
    {
      id: "1089",
      x: 361.3588,
      y: 264.73035,
      nid: null,
      name: "nowa nowa",
      z: -1,
    },
    {
      id: "1090",
      x: 389.78912,
      y: 259.1334,
      nid: null,
      name: "nowa nowa",
      z: -1,
    },
    {
      id: "1093",
      x: 417.7431,
      y: 264.6137,
      nid: "s-002",
      name: "Z\u0141OTE SZATNIE",
      z: -1,
    },
    {
      id: "1094",
      x: 495.63916,
      y: 320.38675,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "1096",
      x: 524.2023,
      y: 313.7146,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "1097",
      x: 524.961,
      y: 242.69823,
      nid: null,
      name: "nowa nowa",
      z: -1,
    },
    {
      id: "1098",
      x: 537.237,
      y: 245.0491,
      nid: null,
      name: "nowa nowa",
      z: -1,
    },
    {
      id: "1372",
      x: 345.1702,
      y: 348.77686,
      nid: "-001",
      name: "CARREFOUR MARKET",
      z: -1,
    },
    {
      id: "32355",
      x: 362.96494,
      y: 348.77948,
      nid: "WF-01001",
      name: "Wayfinder 001 pietro -01",
      z: -1,
    },
    {
      id: "75495",
      x: 389.57663,
      y: 296.2327,
      nid: "s-003",
      name: "BLIKLE",
      z: "-1",
    },
    {
      id: "75496",
      x: 380.38007,
      y: 280.64896,
      nid: "s-001",
      name: "EURO FLORA",
      z: "-1",
    },
    {
      id: "106640",
      x: 449.98157,
      y: 301.39813,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106641",
      x: 469.26114,
      y: 296.8944,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106642",
      x: 483.88715,
      y: 293.47775,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106643",
      x: 495.85397,
      y: 290.68228,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106644",
      x: 515.134,
      y: 286.17844,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106645",
      x: 538.1744,
      y: 280.27545,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106646",
      x: 540.95447,
      y: 272.3344,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106647",
      x: 457.9085,
      y: 296.42194,
      nid: "-012",
      name: "ITAKA",
      z: "-1",
    },
    {
      id: "106648",
      x: 483.14957,
      y: 288.96313,
      nid: "-013",
      name: "JEAN_LOUIS_DAVID",
      z: "-1",
    },
    {
      id: "106649",
      x: 517.7253,
      y: 280.88617,
      nid: "-015",
      name: "autorska",
      z: "-1",
    },
    {
      id: "106650",
      x: 429.98572,
      y: 341.95938,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106652",
      x: 448.7593,
      y: 334.97577,
      nid: "-008",
      name: "nowa nowa",
      z: "-1",
    },
    {
      id: "106653",
      x: 475.5312,
      y: 327.68265,
      nid: "-009a",
      name: "Hop-Chop Barber Shop",
      z: "-1",
    },
    {
      id: "106655",
      x: 502.7868,
      y: 322.87503,
      nid: "-009b",
      name: "SO COFFEE",
      z: "-1",
    },
    {
      id: "106656",
      x: 538.15186,
      y: 310.45605,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106657",
      x: 528.94073,
      y: 318.84427,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106658",
      x: 534.7988,
      y: 324.74902,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106659",
      x: 538.9131,
      y: 333.13467,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106660",
      x: 541.9477,
      y: 343.84265,
      nid: null,
      name: "_waypoint",
      z: "-1",
    },
    {
      id: "106662",
      x: 744.08575,
      y: 262.3512,
      nid: "-021",
      name: "HARD ROCK CAFE",
      z: "-1",
    },
    {
      id: "113305",
      x: 675.7937,
      y: 269.46445,
      nid: "-023",
      name: "H&M",
      z: "-1",
    },
    {
      id: "113306",
      x: 563.58307,
      y: 285.27167,
      nid: "-020",
      name: "RTV",
      z: "-1",
    },
    {
      id: "119972",
      x: 470.224,
      y: 315.40433,
      nid: "s-007",
      name: "SUSHI ZUSHI",
      z: "-1",
    },
    {
      id: "2",
      x: 301.9225,
      y: 264.17725,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "10",
      x: 296.8419,
      y: 272.6234,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "11",
      x: 294.1262,
      y: 277.7884,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "12",
      x: 291.05536,
      y: 284.84213,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "13",
      x: 290.82956,
      y: 292.1428,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "14",
      x: 289.7549,
      y: 297.81842,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "15",
      x: 289.9544,
      y: 305.91144,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "16",
      x: 289.72958,
      y: 313.1878,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "17",
      x: 293.36652,
      y: 321.39343,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "18",
      x: 295.1601,
      y: 328.6545,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "19",
      x: 298.40228,
      y: 337.3884,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "20",
      x: 302.6368,
      y: 345.44717,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "21",
      x: 307.04996,
      y: 352.56488,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "22",
      x: 312.21567,
      y: 357.27048,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "23",
      x: 319.0457,
      y: 363.85974,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "24",
      x: 327.48245,
      y: 369.65378,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "25",
      x: 336.32104,
      y: 374.91833,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "26",
      x: 342.1054,
      y: 379.5029,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "27",
      x: 348.44504,
      y: 381.2772,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "28",
      x: 355.42645,
      y: 384.26395,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "42",
      x: 364.87814,
      y: 388.07266,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "43",
      x: 372.2994,
      y: 391.87408,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "44",
      x: 384.43164,
      y: 395.57523,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "45",
      x: 397.2004,
      y: 399.15326,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "46",
      x: 407.7488,
      y: 403.66382,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "47",
      x: 293.73355,
      y: 369.3897,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "48",
      x: 289.36398,
      y: 363.62872,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "49",
      x: 282.94925,
      y: 356.52557,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "50",
      x: 280.80252,
      y: 351.1712,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "51",
      x: 275.72992,
      y: 341.52722,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "52",
      x: 272.24515,
      y: 323.429,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "53",
      x: 270.19998,
      y: 314.43393,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "54",
      x: 270.62515,
      y: 307.57648,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "55",
      x: 270.90884,
      y: 303.00055,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "56",
      x: 271.51184,
      y: 295.1863,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "57",
      x: 273.74368,
      y: 287.89438,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "58",
      x: 273.99426,
      y: 281.95245,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "59",
      x: 267.9768,
      y: 275.63516,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "60",
      x: 262.7746,
      y: 269.5848,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "62",
      x: 259.97723,
      y: 262.9776,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "65",
      x: 257.77982,
      y: 256.22757,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "66",
      x: 259.65152,
      y: 250.79482,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "68",
      x: 260.49783,
      y: 244.68642,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "69",
      x: 264.9971,
      y: 239.07994,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "70",
      x: 271.67188,
      y: 231.12898,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "71",
      x: 284.06335,
      y: 223.64026,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "72",
      x: 296.17117,
      y: 219.40857,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "74",
      x: 309.99536,
      y: 217.06061,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "75",
      x: 320.83527,
      y: 216.79716,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "76",
      x: 329.1,
      y: 210.25124,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "77",
      x: 337.23016,
      y: 206.02771,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "78",
      x: 345.56506,
      y: 200.8283,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "79",
      x: 360.09274,
      y: 194.15082,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "80",
      x: 369.34225,
      y: 190.56694,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "81",
      x: 379.63983,
      y: 186.26721,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "82",
      x: 391.8562,
      y: 182.8983,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "83",
      x: 422.64194,
      y: 173.24701,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "84",
      x: 438.97763,
      y: 169.35735,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "85",
      x: 450.10712,
      y: 166.23987,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "86",
      x: 464.86487,
      y: 163.19337,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "87",
      x: 483.28482,
      y: 160.21504,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "88",
      x: 475.02533,
      y: 162.18034,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "89",
      x: 499.66064,
      y: 158.66832,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "90",
      x: 509.04022,
      y: 157.37976,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "91",
      x: 521.4385,
      y: 156.31816,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "92",
      x: 534.73944,
      y: 156.933,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "94",
      x: 547.21136,
      y: 157.28162,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "95",
      x: 561.8714,
      y: 157.59381,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "96",
      x: 570.519,
      y: 158.86101,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "97",
      x: 583.7308,
      y: 160.47816,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "98",
      x: 597.4102,
      y: 162.94344,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "99",
      x: 609.16644,
      y: 168.71259,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "100",
      x: 619.41327,
      y: 174.3719,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "101",
      x: 626.6159,
      y: 181.21935,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "102",
      x: 636.44336,
      y: 188.88423,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "103",
      x: 641.6854,
      y: 196.1909,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "104",
      x: 648.0204,
      y: 204.19254,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "105",
      x: 653.26013,
      y: 212.91615,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "106",
      x: 658.93494,
      y: 222.48245,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "107",
      x: 663.7394,
      y: 228.94258,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "108",
      x: 676.2518,
      y: 226.12418,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "109",
      x: 690.7843,
      y: 222.85074,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "110",
      x: 706.6928,
      y: 219.26738,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "111",
      x: 721.3261,
      y: 215.97125,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "112",
      x: 665.03375,
      y: 237.13228,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "113",
      x: 666.7737,
      y: 241.92097,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "114",
      x: 667.8443,
      y: 251.08902,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "115",
      x: 668.2645,
      y: 257.5735,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "116",
      x: 669.11566,
      y: 266.30292,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "117",
      x: 668.6535,
      y: 275.31137,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "118",
      x: 664.6944,
      y: 286.008,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "119",
      x: 659.43665,
      y: 296.9645,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "120",
      x: 650.4976,
      y: 307.76138,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "121",
      x: 644.8424,
      y: 316.4268,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "122",
      x: 648.0874,
      y: 324.09058,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "123",
      x: 659.5832,
      y: 330.91473,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "124",
      x: 670.0073,
      y: 337.05,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "125",
      x: 683.7174,
      y: 342.4988,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "126",
      x: 693.9614,
      y: 346.83633,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "127",
      x: 708.37494,
      y: 352.1652,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "128",
      x: 723.7042,
      y: 356.38943,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "129",
      x: 737.5298,
      y: 360.4796,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "130",
      x: 750.51654,
      y: 362.8962,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "131",
      x: 762.2019,
      y: 365.59387,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "132",
      x: 436.8409,
      y: 420.03836,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "133",
      x: 429.76083,
      y: 415.6721,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "134",
      x: 414.9166,
      y: 414.7406,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "135",
      x: 398.6771,
      y: 413.6657,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "136",
      x: 379.38675,
      y: 410.5601,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "137",
      x: 367.09323,
      y: 407.78607,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "138",
      x: 357.25552,
      y: 404.50095,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "139",
      x: 347.2213,
      y: 400.8182,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "140",
      x: 336.77942,
      y: 396.3364,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "141",
      x: 325.53732,
      y: 391.58896,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "142",
      x: 313.64862,
      y: 384.30814,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "143",
      x: 302.55673,
      y: 375.9721,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "144",
      x: 307.67886,
      y: 379.33557,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "145",
      x: 458.35287,
      y: 419.1509,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "146",
      x: 463.08987,
      y: 399.19647,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "147",
      x: 454.8013,
      y: 384.65878,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "148",
      x: 465.15427,
      y: 380.64383,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "149",
      x: 480.80997,
      y: 381.40036,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "150",
      x: 492.13678,
      y: 384.44952,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "151",
      x: 506.41275,
      y: 386.4314,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "152",
      x: 271.67776,
      y: 332.54947,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "153",
      x: 256.32828,
      y: 336.4275,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "154",
      x: 242.99521,
      y: 338.95795,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "155",
      x: 230.50807,
      y: 341.74792,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "156",
      x: 204.46494,
      y: 347.5667,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "157",
      x: 193.18489,
      y: 347.41553,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "158",
      x: 195.83566,
      y: 337.4608,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "159",
      x: 188.09302,
      y: 330.282,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "162",
      x: 181.11552,
      y: 328.2902,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "163",
      x: 163.98273,
      y: 334.39447,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "164",
      x: 149.02718,
      y: 333.3316,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "165",
      x: 178.46045,
      y: 355.59833,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "166",
      x: 161.77768,
      y: 356.21725,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "167",
      x: 399.93326,
      y: 179.5835,
      nid: "s130",
      name: "Samsung",
      z: 0,
    },
    {
      id: "168",
      x: 410.74368,
      y: 176.0801,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "169",
      x: 404.746,
      y: 167.27116,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "170",
      x: 400.02002,
      y: 159.5536,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "171",
      x: 394.6453,
      y: 150.59184,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "172",
      x: 390.97348,
      y: 143.54842,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "173",
      x: 374.61124,
      y: 149.84483,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "174",
      x: 360.87616,
      y: 148.98564,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "175",
      x: 288.00446,
      y: 235.04913,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "176",
      x: 327.83447,
      y: 227.54071,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "177",
      x: 304.93634,
      y: 245.245,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "178",
      x: 267.79984,
      y: 261.1625,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "179",
      x: 449.22958,
      y: 228.3358,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "180",
      x: 429.4651,
      y: 224.11394,
      nid: "s017",
      name: "TELETORIUM",
      z: "0",
    },
    {
      id: "181",
      x: 446.62595,
      y: 221.98744,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "182",
      x: 453.9679,
      y: 237.43024,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "183",
      x: 516.0437,
      y: 382.12146,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "184",
      x: 420.42184,
      y: 206.76927,
      nid: "s018",
      name: "TATAMI",
      z: "0",
    },
    {
      id: "185",
      x: 501.89975,
      y: 375.10126,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "186",
      x: 497.61038,
      y: 365.53735,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "187",
      x: 466.23138,
      y: 295.97345,
      nid: "s015",
      name: "MARYLIN",
      z: "0",
    },
    {
      id: "188",
      x: 486.8838,
      y: 342.28845,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "189",
      x: 490.3089,
      y: 348.85654,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "190",
      x: 481.1166,
      y: 332.56973,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "191",
      x: 494.80286,
      y: 357.47433,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "192",
      x: 475.9906,
      y: 324.0808,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "193",
      x: 434.3361,
      y: 242.8643,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "195",
      x: 466.3293,
      y: 304.21393,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "196",
      x: 431.9313,
      y: 235.56604,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "197",
      x: 469.97424,
      y: 311.20334,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "198",
      x: 458.8217,
      y: 289.81738,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "199",
      x: 445.31464,
      y: 265.2589,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "200",
      x: 413.4631,
      y: 202.8387,
      nid: "Dotyknr2",
      name: "Wayfinder 002 pietro 1",
      z: "0",
    },
    {
      id: "201",
      x: 438.4204,
      y: 250.69635,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "202",
      x: 461.19855,
      y: 295.7167,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "203",
      x: 427.21872,
      y: 227.87263,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "204",
      x: 422.915,
      y: 219.61966,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "205",
      x: 418.39447,
      y: 210.95091,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "206",
      x: 420.08066,
      y: 198.03589,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "207",
      x: 438.66946,
      y: 208.06729,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "208",
      x: 486.8555,
      y: 299.20413,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "209",
      x: 482.98892,
      y: 291.7826,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "210",
      x: 504.0633,
      y: 336.27557,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "212",
      x: 476.11194,
      y: 278.58292,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "213",
      x: 466.8812,
      y: 262.21533,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "214",
      x: 522.0388,
      y: 368.08194,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "215",
      x: 448.08908,
      y: 173.26044,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "216",
      x: 488.6496,
      y: 165.03859,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "217",
      x: 405.08328,
      y: 188.11423,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "218",
      x: 777.2424,
      y: 367.60736,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "219",
      x: 791.65393,
      y: 369.7641,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "220",
      x: 809.8914,
      y: 372.08167,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "221",
      x: 825.296,
      y: 373.12772,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "222",
      x: 840.7445,
      y: 374.17673,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "223",
      x: 443.16956,
      y: 429.5112,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "224",
      x: 491.25574,
      y: 421.33356,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "225",
      x: 487.6794,
      y: 398.5492,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "226",
      x: 456.00848,
      y: 430.44504,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "227",
      x: 467.2435,
      y: 433.52002,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "228",
      x: 481.61273,
      y: 435.9592,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "229",
      x: 491.59213,
      y: 433.90366,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "230",
      x: 500.51743,
      y: 428.45255,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "231",
      x: 513.85974,
      y: 422.07852,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "232",
      x: 526.8545,
      y: 418.93768,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "233",
      x: 539.1074,
      y: 425.02356,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "234",
      x: 550.54126,
      y: 432.19748,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "235",
      x: 565.15906,
      y: 438.73462,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "236",
      x: 595.1045,
      y: 446.27405,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "237",
      x: 607.68164,
      y: 451.45963,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "238",
      x: 619.855,
      y: 458.55704,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "239",
      x: 628.6221,
      y: 465.89154,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "240",
      x: 638.4756,
      y: 456.19598,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "241",
      x: 651.79376,
      y: 453.07666,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "242",
      x: 534.3914,
      y: 413.29352,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "243",
      x: 548.4893,
      y: 406.73138,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "244",
      x: 562.63403,
      y: 398.77762,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "245",
      x: 575.7685,
      y: 390.1006,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "246",
      x: 590.8392,
      y: 368.6074,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "247",
      x: 595.95276,
      y: 360.63242,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "248",
      x: 618.7153,
      y: 375.60864,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "249",
      x: 635.7201,
      y: 371.1222,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "250",
      x: 653.8771,
      y: 365.9285,
      nid: "Dotyknr1",
      name: "Wayfinder 001 pietro 1",
      z: 0,
    },
    {
      id: "251",
      x: 672.1047,
      y: 363.49048,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "252",
      x: 693.4582,
      y: 361.3301,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "253",
      x: 711.625,
      y: 358.4535,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "254",
      x: 735.1203,
      y: 212.38889,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "255",
      x: 754.0805,
      y: 210.02194,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "256",
      x: 768.6216,
      y: 209.13312,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "257",
      x: 757.0747,
      y: 224.57323,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "258",
      x: 767.48914,
      y: 233.18254,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "259",
      x: 774.8268,
      y: 237.25603,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "837",
      x: 348.84406,
      y: 148.65295,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "840",
      x: 440.1493,
      y: 405.38293,
      nid: "Dotyknr5",
      name: "Wayfinder 004 pietro 0",
      z: 0,
    },
    {
      id: "841",
      x: 419.27,
      y: 403.47827,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "875",
      x: 452.3673,
      y: 259.9712,
      nid: "ATM002",
      name: "Bankomat",
      z: 0,
    },
    {
      id: "876",
      x: 719.74744,
      y: 220.12102,
      nid: "TEL001",
      name: "Telefon",
      z: 0,
    },
    {
      id: "877",
      x: 758.0828,
      y: 211.97864,
      nid: "WC001",
      name: "Toaleta",
      z: 0,
    },
    {
      id: "878",
      x: 770.1201,
      y: 238.29858,
      nid: "WC002",
      name: "Toaleta",
      z: 0,
    },
    {
      id: "879",
      x: 233.55463,
      y: 343.75058,
      nid: "TEL002",
      name: "Telefon",
      z: 0,
    },
    {
      id: "880",
      x: 196.80275,
      y: 343.04086,
      nid: "C001",
      name: "Pok\u00f3j dla matki z dzieckiem",
      z: 0,
    },
    {
      id: "881",
      x: 156.94365,
      y: 358.18433,
      nid: "M001",
      name: "Pierwsza pomoc",
      z: 0,
    },
    {
      id: "882",
      x: 182.96046,
      y: 324.30148,
      nid: "WC003",
      name: "Toaleta",
      z: 0,
    },
    {
      id: "883",
      x: 149.30838,
      y: 330.15488,
      nid: "WC004",
      name: "Toaleta",
      z: 0,
    },
    {
      id: "1106",
      x: 506.98172,
      y: 426.2142,
      nid: "030",
      name: "EMPIK",
      z: 0,
    },
    {
      id: "1107",
      x: 438.7697,
      y: 425.0323,
      nid: "031",
      name: "Empik",
      z: 0,
    },
    {
      id: "1110",
      x: 634.1849,
      y: 469.78104,
      nid: null,
      name: "nowa nowa",
      z: 0,
    },
    {
      id: "1111",
      x: 657.8234,
      y: 450.958,
      nid: null,
      name: "nowa nowa",
      z: 0,
    },
    {
      id: "1114",
      x: 596.15063,
      y: 354.14835,
      nid: "I001",
      name: "Informacja",
      z: 0,
    },
    {
      id: "1115",
      x: 611.5782,
      y: 338.4045,
      nid: "S38",
      name: "IN MEDIO",
      z: 0,
    },
    {
      id: "1118",
      x: 414.30173,
      y: 401.82748,
      nid: "065",
      name: "YVES ROCHER",
      z: 0,
    },
    {
      id: "1120",
      x: 374.45627,
      y: 408.91415,
      nid: "034",
      name: "Reserved",
      z: 0,
    },
    {
      id: "1121",
      x: 360.7465,
      y: 385.3659,
      nid: "066",
      name: "SUNGLASS CENTER WATCH CORNER",
      z: 0,
    },
    {
      id: "1123",
      x: 297.82745,
      y: 372.0778,
      nid: "035",
      name: "VICTORIA'S SECRET BEAUTY&ACCESSORIES",
      z: 0,
    },
    {
      id: "1124",
      x: 333.20377,
      y: 371.55334,
      nid: "067",
      name: "VISION EXPRESS TRENDY",
      z: 0,
    },
    {
      id: "1126",
      x: 276.88995,
      y: 347.5519,
      nid: "037",
      name: "DOUGLAS",
      z: 0,
    },
    {
      id: "1127",
      x: 299.51157,
      y: 333.08975,
      nid: "070",
      name: "MARC O'POLO",
      z: 0,
    },
    {
      id: "1128",
      x: 269.70514,
      y: 318.5988,
      nid: "039",
      name: "KAZAR",
      z: 0,
    },
    {
      id: "1129",
      x: 269.99075,
      y: 298.6947,
      nid: "041",
      name: "GUESS",
      z: 0,
    },
    {
      id: "1130",
      x: 291.8775,
      y: 301.86,
      nid: "072",
      name: "KIEHL'S",
      z: 0,
    },
    {
      id: "1131",
      x: 295.64743,
      y: 281.97797,
      nid: "073",
      name: "LEVI'S STORE",
      z: 0,
    },
    {
      id: "1132",
      x: 255.66139,
      y: 259.8973,
      nid: "001",
      name: "Van Graaf",
      z: 0,
    },
    {
      id: "1133",
      x: 311.0267,
      y: 252.95778,
      nid: "043",
      name: "JOOP",
      z: 0,
    },
    {
      id: "1134",
      x: 334.68668,
      y: 231.44049,
      nid: "044",
      name: "LAMBERT",
      z: 0,
    },
    {
      id: "1135",
      x: 368.89752,
      y: 211.001,
      nid: "045",
      name: "CALZEDONIA",
      z: 0,
    },
    {
      id: "1136",
      x: 400.9001,
      y: 200.23273,
      nid: "047",
      name: "JUBIREX",
      z: 0,
    },
    {
      id: "1137",
      x: 411.35004,
      y: 188.02356,
      nid: "s001",
      name: "LUKULLUS",
      z: 0,
    },
    {
      id: "1138",
      x: 471.58957,
      y: 378.36655,
      nid: "064",
      name: "COSTA",
      z: 0,
    },
    {
      id: "1139",
      x: 518.19495,
      y: 362.05103,
      nid: "062",
      name: "APART",
      z: 0,
    },
    {
      id: "1140",
      x: 507.6955,
      y: 341.8995,
      nid: "083",
      name: "L'OCCITANE",
      z: 0,
    },
    {
      id: "1141",
      x: 483.07336,
      y: 339.00217,
      nid: "084",
      name: "STEFANEL",
      z: 0,
    },
    {
      id: "1142",
      x: 500.39188,
      y: 326.53394,
      nid: "082",
      name: "ORANGE",
      z: 0,
    },
    {
      id: "1143",
      x: 492.23752,
      y: 310.88287,
      nid: "081",
      name: "VAGABOND",
      z: 0,
    },
    {
      id: "1144",
      x: 483.6627,
      y: 295.77356,
      nid: "080",
      name: "PLAY ZONE",
      z: 0,
    },
    {
      id: "1145",
      x: 476.98706,
      y: 281.612,
      nid: "079",
      name: "INGLOT",
      z: 0,
    },
    {
      id: "1146",
      x: 457.74878,
      y: 287.76,
      nid: "088",
      name: "BANK MILLENNIUM",
      z: 0,
    },
    {
      id: "1147",
      x: 463.5745,
      y: 301.6137,
      nid: "087",
      name: "BADURA",
      z: 0,
    },
    {
      id: "1148",
      x: 473.0064,
      y: 319.6991,
      nid: "086",
      name: "VISION EXPRESS",
      z: 0,
    },
    {
      id: "1149",
      x: 489.07013,
      y: 330.37808,
      nid: "s013",
      name: "E-PAPIEROSY",
      z: 0,
    },
    {
      id: "1151",
      x: 452.7087,
      y: 253.89528,
      nid: "S24",
      name: "WARSAW POINT GALLERY",
      z: 0,
    },
    {
      id: "1152",
      x: 442.12988,
      y: 261.83627,
      nid: "089",
      name: "WOJAS",
      z: 0,
    },
    {
      id: "1153",
      x: 464.5313,
      y: 259.0549,
      nid: "077",
      name: "5aSEC",
      z: 0,
    },
    {
      id: "1154",
      x: 459.78058,
      y: 248.58678,
      nid: "076",
      name: "GATTA",
      z: 0,
    },
    {
      id: "1155",
      x: 451.38358,
      y: 232.47006,
      nid: "075",
      name: "T-MOBILE",
      z: 0,
    },
    {
      id: "1156",
      x: 443.84293,
      y: 217.9969,
      nid: "048a",
      name: "NIKON",
      z: 0,
    },
    {
      id: "1157",
      x: 436.08194,
      y: 203.10089,
      nid: "049",
      name: "APART EXCLUSIVE",
      z: 0,
    },
    {
      id: "1159",
      x: 427.70956,
      y: 232.84378,
      nid: "074a",
      name: "TUI CENTRUM PODR\u00d3\u017bY",
      z: 0,
    },
    {
      id: "1160",
      x: 435.6648,
      y: 248.09767,
      nid: "074",
      name: "THE BODY SHOP",
      z: 0,
    },
    {
      id: "1161",
      x: 276.61716,
      y: 226.31142,
      nid: "003",
      name: "HUGO BOSS",
      z: 0,
    },
    {
      id: "1162",
      x: 323.61877,
      y: 212.92532,
      nid: "006",
      name: "TIME TREND/KALIGRAF",
      z: 0,
    },
    {
      id: "1163",
      x: 353.08893,
      y: 195.35178,
      nid: "007",
      name: "CLINIQUE",
      z: 0,
    },
    {
      id: "1164",
      x: 383.53174,
      y: 183.02328,
      nid: "008",
      name: "SWISS",
      z: 0,
    },
    {
      id: "1166",
      x: 387.5409,
      y: 138.31259,
      nid: "ATM001",
      name: "Bankomat",
      z: 0,
    },
    {
      id: "1167",
      x: 430.3698,
      y: 170.47415,
      nid: "009",
      name: "IN MEDIO",
      z: 0,
    },
    {
      id: "1168",
      x: 453.87802,
      y: 163.9386,
      nid: "010",
      name: "TCHIBO",
      z: 0,
    },
    {
      id: "1169",
      x: 490.92303,
      y: 158.39755,
      nid: "012",
      name: "TALLY WEIJL",
      z: 0,
    },
    {
      id: "1170",
      x: 527.00745,
      y: 155.93867,
      nid: "013",
      name: "ESPRIT",
      z: 0,
    },
    {
      id: "1171",
      x: 575.27545,
      y: 158.21266,
      nid: "015",
      name: "ZARA",
      z: 0,
    },
    {
      id: "1172",
      x: 593.9952,
      y: 185.94678,
      nid: null,
      name: "nowa nowa",
      z: 0,
    },
    {
      id: "1173",
      x: 612.2286,
      y: 178.88072,
      nid: null,
      name: "nowa nowa",
      z: 0,
    },
    {
      id: "1174",
      x: 560.8735,
      y: 173.86844,
      nid: "051",
      name: "BIJOU BRIGITTE",
      z: 0,
    },
    {
      id: "1175",
      x: 505.3557,
      y: 175.159,
      nid: "050",
      name: "PANDORA",
      z: 0,
    },
    {
      id: "1176",
      x: 629.8909,
      y: 184.71985,
      nid: "017",
      name: "\u015aWIAT SPINEK",
      z: 0,
    },
    {
      id: "1177",
      x: 642.994,
      y: 200.14479,
      nid: "018",
      name: "PARFOIS",
      z: 0,
    },
    {
      id: "1178",
      x: 655.44196,
      y: 217.14052,
      nid: "019",
      name: "SUPER-PHARM",
      z: 0,
    },
    {
      id: "1179",
      x: 613.9963,
      y: 200.66153,
      nid: "052",
      name: "LEE WRANGLER",
      z: 0,
    },
    {
      id: "1180",
      x: 634.2502,
      y: 220.06369,
      nid: "054",
      name: "ZARA HOME",
      z: 0,
    },
    {
      id: "1181",
      x: 644.04706,
      y: 241.83928,
      nid: "055",
      name: "TATUUM",
      z: 0,
    },
    {
      id: "1182",
      x: 665.88763,
      y: 245.88287,
      nid: "022",
      name: "ORSAY",
      z: 0,
    },
    {
      id: "1183",
      x: 646.20575,
      y: 261.53088,
      nid: "057",
      name: "ALDO",
      z: 0,
    },
    {
      id: "1184",
      x: 668.4476,
      y: 270.6706,
      nid: "024",
      name: "BATH&BODY WORKS",
      z: 0,
    },
    {
      id: "1185",
      x: 645.7511,
      y: 278.94467,
      nid: "058",
      name: "INTIMISSIMI",
      z: 0,
    },
    {
      id: "1186",
      x: 632.7117,
      y: 305.57123,
      nid: "059",
      name: "SWAROVSKI",
      z: 0,
    },
    {
      id: "1187",
      x: 585.85754,
      y: 348.0553,
      nid: "060",
      name: "PROMOD",
      z: 0,
    },
    {
      id: "1188",
      x: 654.41895,
      y: 302.71866,
      nid: "025",
      name: "H&M",
      z: 0,
    },
    {
      id: "1189",
      x: 767.29266,
      y: 365.47055,
      nid: "025a",
      name: "LUBI\u0118 TO",
      z: 0,
    },
    {
      id: "1190",
      x: 844.6029,
      y: 371.51236,
      nid: "026",
      name: "HARD ROCK CAFE",
      z: 0,
    },
    {
      id: "1201",
      x: 305.43686,
      y: 257.90018,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1374",
      x: 268.89865,
      y: 256.81943,
      nid: "s004a",
      name: "GRYCAN",
      z: 0,
    },
    {
      id: "1454",
      x: 332.07126,
      y: 384.80835,
      nid: "s009",
      name: "la Vanille",
      z: 0,
    },
    {
      id: "1937",
      x: 541.3145,
      y: 375.40933,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1938",
      x: 551.453,
      y: 370.51987,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1939",
      x: 560.7676,
      y: 365.33948,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1940",
      x: 572.6557,
      y: 358.22617,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1941",
      x: 584.37384,
      y: 352.05362,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1943",
      x: 592.91345,
      y: 345.16577,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1944",
      x: 601.04785,
      y: 338.81268,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1945",
      x: 610.0639,
      y: 332.7209,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1946",
      x: 617.8094,
      y: 325.5022,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1947",
      x: 625.5743,
      y: 316.87314,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1948",
      x: 633.1439,
      y: 310.59106,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1949",
      x: 637.91315,
      y: 303.04483,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1950",
      x: 642.04175,
      y: 294.22684,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1951",
      x: 645.962,
      y: 284.9711,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1952",
      x: 648.5855,
      y: 274.5802,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1953",
      x: 649.4695,
      y: 265.0234,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1954",
      x: 650.5703,
      y: 257.27997,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1955",
      x: 648.4026,
      y: 247.43933,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1956",
      x: 645.36176,
      y: 235.90402,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1957",
      x: 639.2625,
      y: 225.51859,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1958",
      x: 633.1614,
      y: 215.13004,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1959",
      x: 625.5349,
      y: 208.85855,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1960",
      x: 619.65393,
      y: 203.13426,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1961",
      x: 611.1651,
      y: 196.5979,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1962",
      x: 603.554,
      y: 191.75134,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1963",
      x: 596.8206,
      y: 188.5931,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1964",
      x: 568.4394,
      y: 177.2818,
      nid: "Dotyknr3",
      name: "Wayfinder 001 pietro 1",
      z: 0,
    },
    {
      id: "1965",
      x: 580.5544,
      y: 181.0546,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1966",
      x: 553.11316,
      y: 172.8611,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1967",
      x: 540.42346,
      y: 172.07458,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1968",
      x: 525.85034,
      y: 173.15031,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1969",
      x: 512.81665,
      y: 174.33986,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1970",
      x: 497.7031,
      y: 176.962,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1971",
      x: 482.0053,
      y: 179.72502,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1972",
      x: 463.6331,
      y: 184.05565,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1973",
      x: 448.91974,
      y: 188.45569,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1974",
      x: 438.2236,
      y: 190.97577,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1975",
      x: 426.92734,
      y: 193.63724,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1976",
      x: 409.8452,
      y: 198.58943,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1977",
      x: 393.02496,
      y: 202.55057,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1978",
      x: 376.91534,
      y: 207.73003,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1979",
      x: 362.13037,
      y: 213.97516,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1980",
      x: 350.50543,
      y: 220.84789,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1981",
      x: 339.3187,
      y: 227.14511,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1982",
      x: 329.4502,
      y: 235.87189,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1983",
      x: 320.163,
      y: 241.69878,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "1984",
      x: 312.97217,
      y: 248.85497,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "10785",
      x: 569.63104,
      y: 444.62662,
      nid: null,
      name: "_waypoint",
      z: 0,
    },
    {
      id: "32356",
      x: 507.2673,
      y: 388.06992,
      nid: "WF+00002",
      name: "Wayfinder 002 parter",
      z: 0,
    },
    {
      id: "32357",
      x: 271.11313,
      y: 334.02344,
      nid: "WF+00003",
      name: "Wayfinder 003 parter",
      z: 0,
    },
    {
      id: "32358",
      x: 400.6257,
      y: 183.59955,
      nid: "WF+00004",
      name: "Wayfinder 004 parter",
      z: 0,
    },
    {
      id: "32359",
      x: 496.09045,
      y: 164.21214,
      nid: "WF+00005",
      name: "Wayfinder 005 parter",
      z: 0,
    },
    {
      id: "32360",
      x: 633.5702,
      y: 342.02884,
      nid: "WF+00001",
      name: "Wayfinder 001 parter",
      z: 0,
    },
    {
      id: "75497",
      x: 457.8261,
      y: 243.48505,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75498",
      x: 507.81085,
      y: 378.39984,
      nid: "s012",
      name: "Yankee Candle",
      z: "0",
    },
    {
      id: "75499",
      x: 453.87192,
      y: 262.8575,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75500",
      x: 461.9327,
      y: 252.71748,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75501",
      x: 464.06787,
      y: 255.46553,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75502",
      x: 514.77905,
      y: 356.84167,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75503",
      x: 431.8078,
      y: 197.6003,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75504",
      x: 479.5509,
      y: 285.1837,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75505",
      x: 468.43027,
      y: 286.7496,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75506",
      x: 480.02072,
      y: 308.98547,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75507",
      x: 497.90652,
      y: 352.69885,
      nid: "-002",
      name: "Vincent",
      z: "0",
    },
    {
      id: "75508",
      x: 524.6187,
      y: 374.3801,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75509",
      x: 506.59424,
      y: 359.96573,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75510",
      x: 495.88608,
      y: 339.42255,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75511",
      x: 503.3821,
      y: 330.9253,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75512",
      x: 511.9585,
      y: 347.38684,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75513",
      x: 441.27536,
      y: 214.41994,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75514",
      x: 441.42908,
      y: 256.46567,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75515",
      x: 529.1033,
      y: 381.64102,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75516",
      x: 490.7209,
      y: 306.62332,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75517",
      x: 495.43008,
      y: 314.3136,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75518",
      x: 496.31537,
      y: 318.70972,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75519",
      x: 441.55814,
      y: 235.19644,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75520",
      x: 429.7156,
      y: 212.47704,
      nid: null,
      name: "_waypoint",
      z: "0",
    },
    {
      id: "75521",
      x: 351.59818,
      y: 213.68805,
      nid: "s003",
      name: "SEACRET",
      z: "0",
    },
    {
      id: "75522",
      x: 290.4773,
      y: 269.54462,
      nid: "s005",
      name: "TWOJE_SOCZEWKI",
      z: "0",
    },
    {
      id: "75523",
      x: 281.83163,
      y: 312.26068,
      nid: "s007",
      name: "PARIS_OPTIQUE",
      z: "0",
    },
    {
      id: "75524",
      x: 298.8869,
      y: 357.50345,
      nid: "s006",
      name: "SWIAT_BURSZTYNU",
      z: "0",
    },
    {
      id: "75526",
      x: 644.63446,
      y: 307.6355,
      nid: "s021",
      name: "MOLEX_ORZESZKI",
      z: "0",
    },
    {
      id: "75527",
      x: 657.5685,
      y: 249.61958,
      nid: "s020",
      name: "SHOPDENT",
      z: "0",
    },
    {
      id: "75528",
      x: 624.6583,
      y: 195.85147,
      nid: "302",
      name: "REDBERRY",
      z: "0",
    },
    {
      id: "75529",
      x: 387.3122,
      y: 196.95479,
      nid: "s002",
      name: "LINDT",
      z: "0",
    },
    {
      id: "75530",
      x: 458.2421,
      y: 426.83157,
      nid: "s010",
      name: "PRECLE_KRAKOWSKIE",
      z: "0",
    },
    {
      id: "97065",
      x: 318.10788,
      y: 358.22495,
      nid: "069",
      name: "AVON",
      z: "0",
    },
    {
      id: "97066",
      x: 296.77744,
      y: 253.07185,
      nid: "s004",
      name: "iScratch",
      z: "0",
    },
    {
      id: "97068",
      x: 319.34888,
      y: 377.25214,
      nid: "s020",
      name: "PAESE",
      z: "0",
    },
    {
      id: "97069",
      x: 285.69263,
      y: 337.515,
      nid: "s007",
      name: "WYJATKOWY_PREZENT",
      z: "0",
    },
    {
      id: "97070",
      x: 527.55493,
      y: 400.17798,
      nid: "s011",
      name: "ING BANK",
      z: "0",
    },
    {
      id: "8",
      x: 348.73492,
      y: 390.39877,
      nid: "WF-101",
      name: "Wayfinder Origin",
      z: 1,
    },
    {
      id: "29",
      x: 260.34,
      y: 244.77945,
      nid: "101",
      name: "Van Graaf",
      z: 1,
    },
    {
      id: "260",
      x: 348.83768,
      y: 376.89352,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "261",
      x: 367.6566,
      y: 385.48587,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "271",
      x: 293.40417,
      y: 231.09703,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "272",
      x: 308.03644,
      y: 242.6257,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "273",
      x: 334.73065,
      y: 216.9182,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "735",
      x: 371.9016,
      y: 410.03793,
      nid: "WF-test",
      name: "Wayfinder test",
      z: 1,
    },
    {
      id: "832",
      x: 464.901,
      y: 408.96283,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "833",
      x: 479.61417,
      y: 422.15845,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "834",
      x: 506.82254,
      y: 412.93973,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "835",
      x: 493.15085,
      y: 394.8791,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "836",
      x: 346.1831,
      y: 156.58713,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1070",
      x: 291.66824,
      y: 253.85846,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1092",
      x: 379.41394,
      y: 391.54684,
      nid: "160",
      name: "odCzasu doCzasu",
      z: "1",
    },
    {
      id: "1191",
      x: 494.07193,
      y: 377.53082,
      nid: "158",
      name: "I AM",
      z: 1,
    },
    {
      id: "1192",
      x: 469.1316,
      y: 381.55502,
      nid: "159",
      name: "SIMONE PERELE",
      z: 1,
    },
    {
      id: "1193",
      x: 531.8277,
      y: 396.84662,
      nid: "ATM102",
      name: "Bankomat",
      z: 1,
    },
    {
      id: "1195",
      x: 439.93378,
      y: 414.39948,
      nid: "125",
      name: "STRADIVARIUS",
      z: 1,
    },
    {
      id: "1197",
      x: 385.31674,
      y: 411.05432,
      nid: "126",
      name: "Reserved \u2013 wkr\u00f3tce otwarcie",
      z: 1,
    },
    {
      id: "1199",
      x: 353.3159,
      y: 403.2654,
      nid: "128b",
      name: "TOPSHOP",
      z: 1,
    },
    {
      id: "1200",
      x: 325.264,
      y: 389.40445,
      nid: "128",
      name: "DOROTHY PERKINS",
      z: 1,
    },
    {
      id: "1203",
      x: 282.40277,
      y: 348.70776,
      nid: "130",
      name: "SEPHORA",
      z: 1,
    },
    {
      id: "1204",
      x: 238.88014,
      y: 343.95007,
      nid: "TEL101",
      name: "Telefon",
      z: 1,
    },
    {
      id: "1205",
      x: 194.08551,
      y: 353.46072,
      nid: "WC102",
      name: "Toaleta",
      z: 1,
    },
    {
      id: "1206",
      x: 158.63986,
      y: 360.32242,
      nid: "WC101",
      name: "Toaleta",
      z: 1,
    },
    {
      id: "1207",
      x: 276.36908,
      y: 317.9706,
      nid: "132",
      name: "MOTIVI",
      z: 1,
    },
    {
      id: "1208",
      x: 277.053,
      y: 297.31058,
      nid: "133",
      name: "MOHITO",
      z: 1,
    },
    {
      id: "1209",
      x: 272.5629,
      y: 272.2554,
      nid: "134",
      name: "SOLAR",
      z: 1,
    },
    {
      id: "1210",
      x: 292.22498,
      y: 217.85812,
      nid: "105",
      name: "VISTULA",
      z: 1,
    },
    {
      id: "1211",
      x: 346.12582,
      y: 201.7321,
      nid: "106",
      name: "MASIMO DUTTI",
      z: 1,
    },
    {
      id: "1212",
      x: 401.92026,
      y: 175.89023,
      nid: "107",
      name: "JB DESIGN BYCZEWSKI",
      z: 1,
    },
    {
      id: "1213",
      x: 449.80496,
      y: 162.57216,
      nid: "108",
      name: "WITTCHEN SHOES",
      z: 1,
    },
    {
      id: "1214",
      x: 481.5424,
      y: 156.67537,
      nid: "109",
      name: "WITTCHEN",
      z: 1,
    },
    {
      id: "1215",
      x: 514.71686,
      y: 152.29877,
      nid: "112",
      name: "MANGO",
      z: 1,
    },
    {
      id: "1216",
      x: 552.91705,
      y: 150.92532,
      nid: "114",
      name: "BERSHKA",
      z: 1,
    },
    {
      id: "1217",
      x: 600.1255,
      y: 154.8563,
      nid: "115",
      name: "ZARA",
      z: 1,
    },
    {
      id: "1218",
      x: 646.12103,
      y: 181.17755,
      nid: "117",
      name: "PULL&BEAR",
      z: 1,
    },
    {
      id: "1220",
      x: 675.16974,
      y: 225.16833,
      nid: "s127",
      name: "Lotto",
      z: 1,
    },
    {
      id: "1221",
      x: 679.21063,
      y: 261.55896,
      nid: "TEL102",
      name: "Telefon",
      z: 1,
    },
    {
      id: "1222",
      x: 774.5553,
      y: 248.00923,
      nid: "WC103",
      name: "Toaleta",
      z: 1,
    },
    {
      id: "1223",
      x: 788.6747,
      y: 271.3536,
      nid: "WC106",
      name: "Toaleta",
      z: 1,
    },
    {
      id: "1224",
      x: 802.7818,
      y: 294.67764,
      nid: "C101",
      name: "Pok\u00f3j dla matki z dzieckiem",
      z: 1,
    },
    {
      id: "1225",
      x: 677.0586,
      y: 281.87848,
      nid: "122",
      name: "H&M",
      z: 1,
    },
    {
      id: "1226",
      x: 605.5882,
      y: 181.77763,
      nid: null,
      name: "nowa nowa",
      z: 1,
    },
    {
      id: "1228",
      x: 302.88855,
      y: 317.85202,
      nid: "164",
      name: "LACOSTE",
      z: 1,
    },
    {
      id: "1229",
      x: 303.04596,
      y: 285.22293,
      nid: "166",
      name: "W. KRUK",
      z: 1,
    },
    {
      id: "1230",
      x: 324.9393,
      y: 246.16997,
      nid: "135",
      name: "PIJALNIA CZEKOLADY E. WEDEL",
      z: 1,
    },
    {
      id: "1231",
      x: 365.64865,
      y: 217.23517,
      nid: "137",
      name: "W\u00d3LCZANKA",
      z: 1,
    },
    {
      id: "1232",
      x: 401.7125,
      y: 202.88145,
      nid: "138",
      name: "SISLEY",
      z: 1,
    },
    {
      id: "1233",
      x: 450.64987,
      y: 188.82268,
      nid: "139",
      name: "RAGE AGE",
      z: 1,
    },
    {
      id: "1234",
      x: 433.82797,
      y: 181.09386,
      nid: "ATM101",
      name: "Bankomat",
      z: 1,
    },
    {
      id: "1235",
      x: 487.2291,
      y: 179.93399,
      nid: "140",
      name: "EMANUEL BERG",
      z: 1,
    },
    {
      id: "1236",
      x: 516.5848,
      y: 175.0253,
      nid: "141",
      name: "DENI CLER",
      z: 1,
    },
    {
      id: "1237",
      x: 549.90906,
      y: 171.04993,
      nid: "142",
      name: "TIFFI",
      z: 1,
    },
    {
      id: "1238",
      x: 584.72205,
      y: 176.89351,
      nid: "143",
      name: "OLD HAVANA CIGARS AND ACCESORIES",
      z: 1,
    },
    {
      id: "1239",
      x: 618.71985,
      y: 194.9044,
      nid: "144",
      name: "Simone perele ",
      z: 1,
    },
    {
      id: "1240",
      x: 636.4658,
      y: 208.7943,
      nid: "144a",
      name: "PHENOME",
      z: 1,
    },
    {
      id: "1241",
      x: 647.9125,
      y: 224.1303,
      nid: "145",
      name: "FULL OF STYLE",
      z: 1,
    },
    {
      id: "1242",
      x: 653.913,
      y: 241.18399,
      nid: "146",
      name: "MAC",
      z: 1,
    },
    {
      id: "1243",
      x: 655.10126,
      y: 259.81522,
      nid: "148",
      name: "LI PARIE",
      z: 1,
    },
    {
      id: "1244",
      x: 653.1497,
      y: 279.17853,
      nid: "150",
      name: "YES",
      z: 1,
    },
    {
      id: "1245",
      x: 595.3107,
      y: 268.23413,
      nid: "151",
      name: "CAFE BAGUETTE",
      z: 1,
    },
    {
      id: "1246",
      x: 551.51495,
      y: 269.66766,
      nid: "153",
      name: "GREEN COFEE",
      z: 1,
    },
    {
      id: "1247",
      x: 516.7854,
      y: 281.9301,
      nid: "155",
      name: "LA FRAGOLA",
      z: 1,
    },
    {
      id: "1248",
      x: 492.85336,
      y: 330.12192,
      nid: "156",
      name: "Sphinx",
      z: 1,
    },
    {
      id: "1252",
      x: 619.4261,
      y: 177.1944,
      nid: null,
      name: "nowa nowa",
      z: 1,
    },
    {
      id: "1375",
      x: 679.6527,
      y: 236.56926,
      nid: "120",
      name: "OYSHO \u2013 wkr\u00f3tce otwarcie",
      z: 1,
    },
    {
      id: "1376",
      x: 313.16068,
      y: 345.7125,
      nid: "161",
      name: "Simple",
      z: 1,
    },
    {
      id: "1452",
      x: 301.20172,
      y: 372.2486,
      nid: "129",
      name: "United Colors of Benetton",
      z: 1,
    },
    {
      id: "1455",
      x: 344.75687,
      y: 373.20206,
      nid: "161",
      name: "Simple",
      z: 1,
    },
    {
      id: "1456",
      x: 665.18835,
      y: 207.20248,
      nid: "119",
      name: "Oysho",
      z: 1,
    },
    {
      id: "1457",
      x: 645.8303,
      y: 198.31671,
      nid: "s128",
      name: "Swatch",
      z: 1,
    },
    {
      id: "1458",
      x: 488.64783,
      y: 440.8538,
      nid: "123",
      name: "empik",
      z: 1,
    },
    {
      id: "1985",
      x: 390.05768,
      y: 181.86781,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1986",
      x: 402.15863,
      y: 179.0874,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1987",
      x: 414.88803,
      y: 176.16258,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1988",
      x: 436.4859,
      y: 168.41528,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1989",
      x: 449.82706,
      y: 165.3509,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1990",
      x: 463.79327,
      y: 162.14296,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1991",
      x: 479.02814,
      y: 160.03441,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1992",
      x: 494.0357,
      y: 157.51353,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1993",
      x: 509.87875,
      y: 155.26314,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1994",
      x: 527.6164,
      y: 153.9651,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1995",
      x: 544.70514,
      y: 152.8136,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1996",
      x: 567.4796,
      y: 153.12656,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1997",
      x: 578.43933,
      y: 153.84119,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1998",
      x: 592.13763,
      y: 155.77191,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "1999",
      x: 609.2083,
      y: 160.1551,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2000",
      x: 620.80347,
      y: 164.87039,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2001",
      x: 631.7831,
      y: 172.49272,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2002",
      x: 641.71185,
      y: 180.81314,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2003",
      x: 650.1657,
      y: 189.00764,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2004",
      x: 656.9341,
      y: 196.6654,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2005",
      x: 662.24194,
      y: 205.58072,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2006",
      x: 668.61115,
      y: 216.55142,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2007",
      x: 670.7577,
      y: 223.42892,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2008",
      x: 673.3187,
      y: 229.74788,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2009",
      x: 673.5725,
      y: 235.68138,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2010",
      x: 675.0227,
      y: 261.61594,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2011",
      x: 675.7117,
      y: 269.75076,
      nid: "",
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2012",
      x: 673.4667,
      y: 277.65018,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2013",
      x: 671.4444,
      y: 287.34164,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2014",
      x: 667.51904,
      y: 294.7147,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2015",
      x: 658.79474,
      y: 305.0594,
      nid: "Dotyknr7",
      name: "Wayfinder 001 pietro 1",
      z: 1,
    },
    {
      id: "2016",
      x: 650.2587,
      y: 313.0579,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2017",
      x: 647.1703,
      y: 320.70068,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2018",
      x: 643.87897,
      y: 329.31454,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2019",
      x: 639.128,
      y: 339.19574,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2020",
      x: 628.5054,
      y: 350.00198,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2021",
      x: 614.7,
      y: 357.41043,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2022",
      x: 602.3362,
      y: 362.17346,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2023",
      x: 584.9128,
      y: 366.74612,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2024",
      x: 573.3736,
      y: 371.77878,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2025",
      x: 558.2764,
      y: 380.88806,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2026",
      x: 548.8614,
      y: 390.04584,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2027",
      x: 539.4204,
      y: 396.43872,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2028",
      x: 528.0894,
      y: 404.66837,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2029",
      x: 526.2578,
      y: 413.4274,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2030",
      x: 523.5795,
      y: 421.92514,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2031",
      x: 518.3608,
      y: 429.6387,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2032",
      x: 508.69067,
      y: 435.63437,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2033",
      x: 495.19528,
      y: 439.7628,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2034",
      x: 481.66833,
      y: 441.12106,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2035",
      x: 471.49463,
      y: 439.36652,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2036",
      x: 458.32544,
      y: 434.61288,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2037",
      x: 448.2986,
      y: 426.32843,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2038",
      x: 445.4555,
      y: 415.41248,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2039",
      x: 426.77426,
      y: 413.79874,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2040",
      x: 416.16025,
      y: 413.98615,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2041",
      x: 392.1286,
      y: 411.76886,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2042",
      x: 375.29343,
      y: 408.3093,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2043",
      x: 359.71832,
      y: 404.54602,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2044",
      x: 340.48138,
      y: 397.91855,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2045",
      x: 330.6155,
      y: 391.4029,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2046",
      x: 314.54468,
      y: 384.01248,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2047",
      x: 305.94382,
      y: 377.18814,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2050",
      x: 295.60098,
      y: 367.04282,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2051",
      x: 286.3155,
      y: 356.177,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2052",
      x: 280.66345,
      y: 345.3875,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2053",
      x: 277.39014,
      y: 337.7662,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2054",
      x: 266.90555,
      y: 338.3476,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2055",
      x: 254.07826,
      y: 341.33878,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2056",
      x: 243.38608,
      y: 344.29834,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2057",
      x: 199.46426,
      y: 354.0741,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2058",
      x: 185.30295,
      y: 357.37637,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2059",
      x: 165.97484,
      y: 361.88345,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2060",
      x: 151.78824,
      y: 365.1916,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2061",
      x: 130.48825,
      y: 370.1585,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2062",
      x: 119.07937,
      y: 373.28677,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2063",
      x: 276.0155,
      y: 326.90527,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2064",
      x: 273.98813,
      y: 314.79706,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2065",
      x: 273.05984,
      y: 306.15945,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2066",
      x: 274.67206,
      y: 294.13547,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2067",
      x: 278.23004,
      y: 284.4568,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2068",
      x: 277.7486,
      y: 278.04483,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2069",
      x: 268.89896,
      y: 269.37466,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2070",
      x: 263.68726,
      y: 260.79068,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2071",
      x: 263.62888,
      y: 253.81126,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2072",
      x: 265.25354,
      y: 243.17993,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2073",
      x: 273.09555,
      y: 233.4474,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2075",
      x: 296.91312,
      y: 215.84756,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2076",
      x: 309.0742,
      y: 213.04924,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2077",
      x: 324.86957,
      y: 211.74213,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2078",
      x: 332.96185,
      y: 209.41415,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2079",
      x: 350.1622,
      y: 199.87325,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2080",
      x: 361.8332,
      y: 193.4685,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2081",
      x: 359.63434,
      y: 185.13744,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2084",
      x: 354.8707,
      y: 175.99796,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2085",
      x: 350.10562,
      y: 166.85577,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2086",
      x: 373.89737,
      y: 157.68492,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2087",
      x: 378.6559,
      y: 166.82271,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2088",
      x: 382.9919,
      y: 176.51929,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2089",
      x: 351.98688,
      y: 162.23769,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2090",
      x: 361.56094,
      y: 160.04514,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2091",
      x: 686.95685,
      y: 261.59155,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2092",
      x: 700.74194,
      y: 258.36917,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2093",
      x: 713.67883,
      y: 254.88461,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2094",
      x: 727.6559,
      y: 252.07774,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2095",
      x: 740.78577,
      y: 249.00851,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2096",
      x: 754.5308,
      y: 245.79546,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2097",
      x: 758.5393,
      y: 252.21597,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2098",
      x: 763.4124,
      y: 263.0298,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2099",
      x: 768.9081,
      y: 273.69385,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2100",
      x: 775.02563,
      y: 284.2077,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2101",
      x: 779.2601,
      y: 293.78152,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2102",
      x: 783.4934,
      y: 303.35275,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2103",
      x: 788.78094,
      y: 314.96924,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2104",
      x: 795.2931,
      y: 323.5367,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2105",
      x: 805.8821,
      y: 321.03644,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2106",
      x: 818.1212,
      y: 317.68756,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2107",
      x: 832.21783,
      y: 313.90042,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2108",
      x: 840.92126,
      y: 311.84558,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2109",
      x: 409.97623,
      y: 199.58423,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2110",
      x: 426.51508,
      y: 195.77425,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2111",
      x: 443.0282,
      y: 190.57816,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2112",
      x: 464.6148,
      y: 185.60616,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2113",
      x: 479.83737,
      y: 182.09998,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2114",
      x: 495.4662,
      y: 178.03685,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2115",
      x: 524.5944,
      y: 172.71715,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2116",
      x: 537.465,
      y: 171.6037,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2117",
      x: 556.6523,
      y: 170.421,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2118",
      x: 570.3665,
      y: 172.34653,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2119",
      x: 590.4162,
      y: 178.35191,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2120",
      x: 604.13635,
      y: 184.4229,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2121",
      x: 612.80054,
      y: 190.27126,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2122",
      x: 623.35815,
      y: 197.06224,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2123",
      x: 633.91986,
      y: 205.2313,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2124",
      x: 644.7192,
      y: 217.95236,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2125",
      x: 649.81775,
      y: 226.45503,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2126",
      x: 654.08435,
      y: 236.0708,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2127",
      x: 656.69965,
      y: 249.29678,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2128",
      x: 657.6438,
      y: 263.37158,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2129",
      x: 655.00244,
      y: 274.59485,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2130",
      x: 650.0927,
      y: 290.9621,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2131",
      x: 644.4706,
      y: 296.4305,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2132",
      x: 630.96594,
      y: 286.67712,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2133",
      x: 619.9988,
      y: 280.4747,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2134",
      x: 610.71027,
      y: 274.79803,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2135",
      x: 599.5381,
      y: 270.94247,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2136",
      x: 578.25183,
      y: 266.66953,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2137",
      x: 564.7813,
      y: 267.96185,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2138",
      x: 546.8909,
      y: 271.6707,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2139",
      x: 526.4759,
      y: 278.7444,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2140",
      x: 512.1745,
      y: 286.70914,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2141",
      x: 503.56076,
      y: 293.3495,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2142",
      x: 496.87585,
      y: 303.7094,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2143",
      x: 489.56616,
      y: 315.60785,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2144",
      x: 490.4908,
      y: 325.58105,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2145",
      x: 497.15887,
      y: 339.7629,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2146",
      x: 504.8863,
      y: 354.61496,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2147",
      x: 511.5375,
      y: 367.39847,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2148",
      x: 516.45966,
      y: 375.494,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2149",
      x: 509.95142,
      y: 380.7307,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2150",
      x: 488.1458,
      y: 377.07504,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2151",
      x: 472.721,
      y: 380.24603,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2152",
      x: 467.49908,
      y: 389.35306,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2153",
      x: 464.41586,
      y: 401.20068,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2154",
      x: 425.81192,
      y: 399.6472,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2155",
      x: 451.31778,
      y: 406.1473,
      nid: "Dotyknr4",
      name: "Wayfinder 001 pietro 1",
      z: 1,
    },
    {
      id: "2156",
      x: 407.3146,
      y: 397.04807,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2157",
      x: 386.8427,
      y: 389.33438,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2158",
      x: 333.8206,
      y: 366.00104,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2159",
      x: 316.82654,
      y: 349.97467,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2160",
      x: 308.14554,
      y: 333.38577,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2161",
      x: 302.70602,
      y: 321.61914,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2162",
      x: 300.44193,
      y: 306.31564,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2163",
      x: 301.37778,
      y: 290.26648,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2164",
      x: 303.60516,
      y: 275.31482,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2165",
      x: 310.32266,
      y: 260.72202,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2166",
      x: 319.00256,
      y: 251.26584,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2168",
      x: 358.0037,
      y: 220.39297,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2169",
      x: 378.58456,
      y: 210.53423,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2170",
      x: 394.28693,
      y: 205.05695,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2171",
      x: 97.05764,
      y: 324.58344,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2172",
      x: 113.873055,
      y: 320.68613,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2173",
      x: 133.0375,
      y: 315.77658,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2174",
      x: 151.31825,
      y: 310.6048,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2175",
      x: 170.019,
      y: 306.2712,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2176",
      x: 182.73431,
      y: 308.46323,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2177",
      x: 187.55646,
      y: 319.02188,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2178",
      x: 193.2275,
      y: 328.44568,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2179",
      x: 198.47154,
      y: 338.43207,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2180",
      x: 201.96663,
      y: 345.08786,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2181",
      x: 238.3972,
      y: 337.53357,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2182",
      x: 253.16037,
      y: 334.0939,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2183",
      x: 268.7706,
      y: 330.92288,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2184",
      x: 705.8929,
      y: 247.95476,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2185",
      x: 721.54126,
      y: 244.30069,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2186",
      x: 739.05176,
      y: 240.21179,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2187",
      x: 756.7572,
      y: 236.53726,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2188",
      x: 772.3626,
      y: 232.89302,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "2189",
      x: 797.716,
      y: 226.51302,
      nid: null,
      name: "_waypoint",
      z: 1,
    },
    {
      id: "32361",
      x: 512.50604,
      y: 410.20636,
      nid: "WF+01001",
      name: "Wayfinder 001 Pi\u0119tro 01",
      z: 1,
    },
    {
      id: "32362",
      x: 332.12946,
      y: 237.9936,
      nid: "WF+01002",
      name: "Wayfinder 002 Pi\u0119tro 01",
      z: 1,
    },
    {
      id: "32363",
      x: 650.15497,
      y: 299.24826,
      nid: "WF+01004",
      name: "Wayfinder 004 Pi\u0119tro 01",
      z: 1,
    },
    {
      id: "32364",
      x: 625.90967,
      y: 173.38786,
      nid: "WF+01003",
      name: "Wayfinder 003 Pi\u0119tro 01",
      z: 1,
    },
    {
      id: "75531",
      x: 521.6288,
      y: 415.44885,
      nid: "",
      name: "CITIBANK",
      z: "1",
    },
    {
      id: "75532",
      x: 411.81824,
      y: 190.80063,
      nid: "s123",
      name: "GADZECIAK",
      z: "1",
    },
    {
      id: "75533",
      x: 372.5737,
      y: 204.48137,
      nid: "s124",
      name: "BYDZIUBEKA",
      z: "1",
    },
    {
      id: "86282",
      x: 626.825,
      y: 183.33311,
      nid: "s127",
      name: "LOTTO",
      z: "1",
    },
    {
      id: "97071",
      x: 307.67682,
      y: 328.3747,
      nid: "162a",
      name: "SUPERDRY",
      z: "1",
    },
    {
      id: "99977",
      x: 207.98634,
      y: 345.55246,
      nid: null,
      name: "nowa nowa",
      z: "1",
    },
    {
      id: "119973",
      x: 664.8645,
      y: 275.97604,
      nid: "s126",
      name: "Selfie Jewellery",
      z: "1",
    },
    {
      id: "733",
      x: 503.7486,
      y: 408.70685,
      nid: "WF+02001",
      name: "Wayfinder 001 pietro 02",
      z: 2,
    },
    {
      id: "831",
      x: 338.71973,
      y: 156.04575,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1122",
      x: 258.5493,
      y: 264.01944,
      nid: "201",
      name: "SATURN",
      z: "2",
    },
    {
      id: "1194",
      x: 155.13353,
      y: 358.23386,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1219",
      x: 262.01828,
      y: 334.63907,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1253",
      x: 492.19257,
      y: 447.2348,
      nid: "229",
      name: "EMPIK",
      z: 2,
    },
    {
      id: "1254",
      x: 426.38693,
      y: 421.30106,
      nid: "230a",
      name: "ADIDAS",
      z: 2,
    },
    {
      id: "1255",
      x: 382.48642,
      y: 416.73077,
      nid: "230",
      name: "CARRY",
      z: 2,
    },
    {
      id: "1256",
      x: 358.8876,
      y: 410.5126,
      nid: "233",
      name: "Terranova",
      z: 2,
    },
    {
      id: "1257",
      x: 318.71506,
      y: 394.65884,
      nid: "234",
      name: "CROPP/HOUSE",
      z: 2,
    },
    {
      id: "1258",
      x: 285.38968,
      y: 366.8762,
      nid: "235",
      name: "MARCO ALDANY",
      z: 2,
    },
    {
      id: "1259",
      x: 272.1702,
      y: 349.8943,
      nid: "237",
      name: "DEICHMANN",
      z: 2,
    },
    {
      id: "1260",
      x: 263.19717,
      y: 317.7665,
      nid: "240",
      name: "ISPOT",
      z: 2,
    },
    {
      id: "1261",
      x: 265.57062,
      y: 296.387,
      nid: "241",
      name: "DUKA",
      z: 2,
    },
    {
      id: "1263",
      x: 332.65842,
      y: 199.69606,
      nid: "206",
      name: "NIKE",
      z: 2,
    },
    {
      id: "1266",
      x: 337.45325,
      y: 212.69388,
      nid: "208",
      name: "SOI COFEE",
      z: 2,
    },
    {
      id: "1267",
      x: 380.25974,
      y: 198.472,
      nid: "ATM201",
      name: "Bankomat",
      z: 2,
    },
    {
      id: "1268",
      x: 408.12796,
      y: 177.82672,
      nid: "212",
      name: "CROCS",
      z: 2,
    },
    {
      id: "1269",
      x: 451.39157,
      y: 166.77156,
      nid: "215",
      name: "GAMES WORKSHOP",
      z: 2,
    },
    {
      id: "1270",
      x: 440.2517,
      y: 162.30359,
      nid: "210",
      name: "GAMES WORLD",
      z: 2,
    },
    {
      id: "1271",
      x: 465.54144,
      y: 155.44281,
      nid: "214",
      name: "NATURHOUSE",
      z: 2,
    },
    {
      id: "1272",
      x: 670.6645,
      y: 248.99802,
      nid: "s233",
      name: "Ergo Hestia",
      z: "2",
    },
    {
      id: "1273",
      x: 526.2622,
      y: 149.27055,
      nid: "218",
      name: "COFFEEHEAVEN",
      z: 2,
    },
    {
      id: "1274",
      x: 556.0655,
      y: 148.93457,
      nid: "219",
      name: "CLARKS",
      z: 2,
    },
    {
      id: "1275",
      x: 580.1487,
      y: 151.37418,
      nid: "220",
      name: "TERRANOVA&CALLIOPE",
      z: 2,
    },
    {
      id: "1276",
      x: 611.6121,
      y: 156.39328,
      nid: "221",
      name: "SMYK",
      z: 2,
    },
    {
      id: "1277",
      x: 632.5836,
      y: 171.51973,
      nid: "222",
      name: "VIP COLLECTION",
      z: 2,
    },
    {
      id: "1278",
      x: 648.4597,
      y: 184.06972,
      nid: "223",
      name: "LEGO",
      z: 2,
    },
    {
      id: "1279",
      x: 662.9995,
      y: 200.80414,
      nid: "224",
      name: "CCC",
      z: 2,
    },
    {
      id: "1282",
      x: 670.1289,
      y: 213.54443,
      nid: "227a",
      name: "PUCCINI",
      z: 2,
    },
    {
      id: "1283",
      x: 682.0292,
      y: 245.4282,
      nid: "227",
      name: "RY\u0141KO",
      z: 2,
    },
    {
      id: "1284",
      x: 681.15234,
      y: 278.44125,
      nid: "228a",
      name: "Ry\u0142ko",
      z: 2,
    },
    {
      id: "1285",
      x: 739.72406,
      y: 250.07014,
      nid: "TEL202",
      name: "Telefon",
      z: 2,
    },
    {
      id: "1286",
      x: 783.3157,
      y: 242.5191,
      nid: "WC204",
      name: "Toaleta",
      z: 2,
    },
    {
      id: "1287",
      x: 791.1673,
      y: 257.6942,
      nid: "WC205",
      name: "Toaleta",
      z: 2,
    },
    {
      id: "1288",
      x: 620.3336,
      y: 278.34644,
      nid: "263",
      name: "Grycan",
      z: 2,
    },
    {
      id: "1289",
      x: 624.86316,
      y: 293.22052,
      nid: "ATM202",
      name: "Bankomat",
      z: 2,
    },
    {
      id: "1290",
      x: 579.6113,
      y: 266.03854,
      nid: "265",
      name: "HANA SUSHI",
      z: 2,
    },
    {
      id: "1291",
      x: 517.6053,
      y: 278.82938,
      nid: "266",
      name: "CAVA",
      z: 2,
    },
    {
      id: "1292",
      x: 483.62616,
      y: 324.0117,
      nid: "268",
      name: "WIKING",
      z: 2,
    },
    {
      id: "1293",
      x: 506.0695,
      y: 335.7217,
      nid: "s230",
      name: "ME&B",
      z: 2,
    },
    {
      id: "1294",
      x: 488.42978,
      y: 338.29517,
      nid: "269",
      name: "PARIPARI",
      z: 2,
    },
    {
      id: "1295",
      x: 497.15475,
      y: 351.71613,
      nid: "271",
      name: "7CAMPICIE",
      z: 2,
    },
    {
      id: "1296",
      x: 484.1606,
      y: 377.81528,
      nid: "272",
      name: "SAMSONITE",
      z: 2,
    },
    {
      id: "1301",
      x: 186.72409,
      y: 351.92664,
      nid: "TEL201",
      name: "Telefon",
      z: 2,
    },
    {
      id: "1302",
      x: 172.95853,
      y: 326.73,
      nid: "WC202",
      name: "Toaleta",
      z: 2,
    },
    {
      id: "1303",
      x: 137.22556,
      y: 334.49707,
      nid: "WC201",
      name: "Toaleta",
      z: 2,
    },
    {
      id: "1304",
      x: 148.74696,
      y: 356.86362,
      nid: "C201",
      name: "Pok\u00f3j dla matki z dzieckiem",
      z: 2,
    },
    {
      id: "1305",
      x: 374.0369,
      y: 391.21667,
      nid: "273",
      name: "TIMBERLAND",
      z: 2,
    },
    {
      id: "1306",
      x: 342.96292,
      y: 377.64923,
      nid: "275",
      name: "BIG STAR",
      z: 2,
    },
    {
      id: "1307",
      x: 313.73218,
      y: 353.7239,
      nid: "276",
      name: "VERO MODA",
      z: 2,
    },
    {
      id: "1308",
      x: 298.7792,
      y: 328.10016,
      nid: "278",
      name: "ECCO",
      z: 2,
    },
    {
      id: "1309",
      x: 294.09543,
      y: 298.80127,
      nid: "245",
      name: "Smoke - wkr\u00f3tce otwarcie",
      z: 2,
    },
    {
      id: "1310",
      x: 300.3828,
      y: 271.31763,
      nid: "246",
      name: "MUSCLE CLINIC",
      z: 2,
    },
    {
      id: "1311",
      x: 318.44833,
      y: 244.45818,
      nid: "248",
      name: "SIZEER",
      z: 2,
    },
    {
      id: "1312",
      x: 371.52765,
      y: 210.4102,
      nid: "250",
      name: "DISTANCE",
      z: 2,
    },
    {
      id: "1313",
      x: 398.59897,
      y: 200.32831,
      nid: "252",
      name: "KOMPUTRONIK",
      z: 2,
    },
    {
      id: "1314",
      x: 448.28156,
      y: 186.38669,
      nid: "253",
      name: "GINO ROSSI",
      z: 2,
    },
    {
      id: "1315",
      x: 478.59467,
      y: 179.32011,
      nid: "255",
      name: "PAWO",
      z: 2,
    },
    {
      id: "1316",
      x: 507.72015,
      y: 173.95126,
      nid: "255a",
      name: "PR\u00d3CHNIK",
      z: 2,
    },
    {
      id: "1317",
      x: 560.78546,
      y: 172.02756,
      nid: "256",
      name: "OCHNIK",
      z: 2,
    },
    {
      id: "1318",
      x: 604.4185,
      y: 187.11603,
      nid: "258",
      name: "BYTOM",
      z: 2,
    },
    {
      id: "1319",
      x: 625.5854,
      y: 201.31468,
      nid: "259",
      name: "4F",
      z: 2,
    },
    {
      id: "1320",
      x: 643.41974,
      y: 218.74217,
      nid: "",
      name: "",
      z: 2,
    },
    {
      id: "1321",
      x: 654.84644,
      y: 245.88864,
      nid: "262",
      name: "HEGO'S",
      z: 2,
    },
    {
      id: "1377",
      x: 665.53784,
      y: 303.76706,
      nid: "s232",
      name: "PETERSON",
      z: 2,
    },
    {
      id: "1459",
      x: 673.6397,
      y: 295.6303,
      nid: "228",
      name: "Go Sport",
      z: 2,
    },
    {
      id: "1680",
      x: 519.8388,
      y: 152.19078,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1682",
      x: 548.2849,
      y: 150.75156,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1685",
      x: 642.22504,
      y: 182.16975,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1686",
      x: 648.8627,
      y: 188.75679,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1689",
      x: 600.2466,
      y: 180.93373,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1690",
      x: 613.0056,
      y: 187.98732,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1691",
      x: 623.40985,
      y: 196.08228,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1692",
      x: 634.5409,
      y: 205.46317,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1693",
      x: 635.83167,
      y: 176.0134,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1694",
      x: 625.8922,
      y: 168.78319,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1698",
      x: 680.22906,
      y: 250.17758,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1699",
      x: 643.0176,
      y: 214.04166,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1700",
      x: 572.11456,
      y: 171.29187,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1701",
      x: 548.4423,
      y: 168.25519,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1702",
      x: 515.6252,
      y: 169.74017,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1703",
      x: 500.0568,
      y: 171.9498,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1704",
      x: 484.96594,
      y: 174.99585,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1705",
      x: 467.0818,
      y: 178.22112,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1706",
      x: 491.1204,
      y: 163.62843,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1707",
      x: 454.64432,
      y: 182.06729,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1708",
      x: 445.53088,
      y: 173.33168,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1709",
      x: 410.27798,
      y: 193.83002,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1712",
      x: 270.39468,
      y: 324.64667,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1716",
      x: 261.8427,
      y: 268.91263,
      nid: "",
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1717",
      x: 256.28503,
      y: 257.00815,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1718",
      x: 257.7636,
      y: 244.89973,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1719",
      x: 263.52768,
      y: 234.15498,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1720",
      x: 275.90387,
      y: 223.75212,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1721",
      x: 293.24344,
      y: 215.94841,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1722",
      x: 311.74088,
      y: 211.6322,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1724",
      x: 279.52707,
      y: 352.96902,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1725",
      x: 283.7401,
      y: 359.62595,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1726",
      x: 294.07773,
      y: 371.0957,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1727",
      x: 302.41724,
      y: 378.74698,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1728",
      x: 312.92593,
      y: 386.3979,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1729",
      x: 329.57758,
      y: 397.46506,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1730",
      x: 342.6833,
      y: 403.1298,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1731",
      x: 352.73752,
      y: 407.09695,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1732",
      x: 368.6661,
      y: 411.1867,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1733",
      x: 377.61346,
      y: 413.01465,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1734",
      x: 390.49817,
      y: 415.4027,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1735",
      x: 403.5923,
      y: 416.7859,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1736",
      x: 416.05338,
      y: 418.3176,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1737",
      x: 434.6196,
      y: 418.9616,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1738",
      x: 289.88788,
      y: 292.19342,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1739",
      x: 289.28424,
      y: 304.64957,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1740",
      x: 290.34943,
      y: 314.8384,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1743",
      x: 188.41951,
      y: 337.34122,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1745",
      x: 179.11166,
      y: 326.2555,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1746",
      x: 305.28632,
      y: 351.36325,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1747",
      x: 314.52875,
      y: 360.69998,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1750",
      x: 321.3432,
      y: 366.78683,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1752",
      x: 335.3516,
      y: 375.55115,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1753",
      x: 348.25833,
      y: 382.19452,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1754",
      x: 358.11542,
      y: 387.14832,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1755",
      x: 366.2034,
      y: 390.11218,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1756",
      x: 383.92767,
      y: 396.1847,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1757",
      x: 194.1442,
      y: 349.74936,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1759",
      x: 395.09238,
      y: 399.42996,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1760",
      x: 409.78522,
      y: 403.81262,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1764",
      x: 145.28157,
      y: 334.99725,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1766",
      x: 504.94476,
      y: 383.74246,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1767",
      x: 444.21252,
      y: 425.9908,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1768",
      x: 449.02188,
      y: 433.6254,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1769",
      x: 456.17563,
      y: 438.80383,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1770",
      x: 465.99377,
      y: 444.8486,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1771",
      x: 479.85535,
      y: 446.1111,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1772",
      x: 498.88788,
      y: 443.30255,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1774",
      x: 514.3918,
      y: 432.53107,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1775",
      x: 523.6682,
      y: 426.55905,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1781",
      x: 527.83673,
      y: 317.76807,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1782",
      x: 508.1744,
      y: 326.1004,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1785",
      x: 503.6967,
      y: 294.95493,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1786",
      x: 513.65076,
      y: 285.00586,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1787",
      x: 531.3724,
      y: 278.54507,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1788",
      x: 548.0398,
      y: 272.8034,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1789",
      x: 569.86084,
      y: 269.71545,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1790",
      x: 587.71124,
      y: 271.38428,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1791",
      x: 601.8987,
      y: 274.38315,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1793",
      x: 623.88324,
      y: 284.76303,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1794",
      x: 651.35144,
      y: 303.60864,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1795",
      x: 660.6174,
      y: 308.2755,
      nid: "Dotyknr9",
      name: "Wayfinder 2 pietro 2",
      z: 2,
    },
    {
      id: "1796",
      x: 669.50287,
      y: 297.5403,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1797",
      x: 676.0535,
      y: 283.95523,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1798",
      x: 679.00793,
      y: 269.2698,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1799",
      x: 681.0552,
      y: 259.63184,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1800",
      x: 692.9132,
      y: 256.91754,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1801",
      x: 706.09973,
      y: 253.89917,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1802",
      x: 721.2785,
      y: 250.42477,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1803",
      x: 732.2665,
      y: 247.42644,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1804",
      x: 777.8146,
      y: 239.41977,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1805",
      x: 788.00244,
      y: 252.11015,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1806",
      x: 659.61005,
      y: 277.08215,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1807",
      x: 661.31244,
      y: 264.1509,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1809",
      x: 657.37366,
      y: 237.13335,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1810",
      x: 657.37366,
      y: 237.13335,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1811",
      x: 650.9079,
      y: 224.21184,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1812",
      x: 673.22253,
      y: 219.07277,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1813",
      x: 676.7878,
      y: 230.7477,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1816",
      x: 529.91626,
      y: 167.82999,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1817",
      x: 292.0503,
      y: 282.7024,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1818",
      x: 294.6567,
      y: 274.06082,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1819",
      x: 301.7072,
      y: 262.9777,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1820",
      x: 307.47562,
      y: 252.1966,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1821",
      x: 320.883,
      y: 238.23088,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1822",
      x: 333.90384,
      y: 226.24298,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1823",
      x: 344.91086,
      y: 219.9094,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1824",
      x: 358.48416,
      y: 212.97672,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1825",
      x: 370.55722,
      y: 205.92015,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1826",
      x: 384.4405,
      y: 202.21265,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1827",
      x: 410.65744,
      y: 183.83464,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1829",
      x: 421.08545,
      y: 171.02805,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1833",
      x: 349.54053,
      y: 161.46967,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1834",
      x: 387.30063,
      y: 179.39911,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1835",
      x: 287.79303,
      y: 231.32457,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1836",
      x: 320.38724,
      y: 215.73076,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1837",
      x: 305.5714,
      y: 243.20341,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1838",
      x: 372.65262,
      y: 195.06358,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1839",
      x: 588.8639,
      y: 175.00626,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1840",
      x: 527.55743,
      y: 417.4229,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1841",
      x: 525.2166,
      y: 406.2964,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1842",
      x: 523.0662,
      y: 394.17288,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1843",
      x: 519.33057,
      y: 380.48972,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1844",
      x: 517.56213,
      y: 366.38535,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1845",
      x: 519.9949,
      y: 353.27966,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1846",
      x: 525.78375,
      y: 342.31708,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1847",
      x: 535.02216,
      y: 329.61105,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1848",
      x: 548.41144,
      y: 316.44467,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1849",
      x: 564.1434,
      y: 307.55896,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1851",
      x: 596.1778,
      y: 298.80276,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1852",
      x: 612.02325,
      y: 298.0801,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1853",
      x: 624.896,
      y: 299.9704,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1854",
      x: 638.4986,
      y: 303.1508,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1855",
      x: 466.8123,
      y: 405.41415,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1856",
      x: 499.1338,
      y: 401.5097,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1857",
      x: 472.6293,
      y: 424.4385,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1858",
      x: 507.75916,
      y: 417.50507,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1859",
      x: 269.09158,
      y: 312.6183,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1860",
      x: 269.31747,
      y: 302.14685,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1861",
      x: 273.12607,
      y: 288.96713,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1862",
      x: 272.7387,
      y: 280.07697,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1863",
      x: 449.98254,
      y: 162.85579,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1864",
      x: 477.26852,
      y: 156.94585,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1865",
      x: 502.9421,
      y: 153.30388,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1866",
      x: 567.9409,
      y: 151.85037,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1868",
      x: 331.0922,
      y: 206.17583,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1869",
      x: 348.4484,
      y: 196.94879,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1870",
      x: 677.669,
      y: 241.61253,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1871",
      x: 507.97284,
      y: 438.34756,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1872",
      x: 275.87567,
      y: 341.91156,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1873",
      x: 354.0202,
      y: 169.8004,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1874",
      x: 595.8673,
      y: 290.20145,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1877",
      x: 173.89323,
      y: 332.65186,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1878",
      x: 158.85474,
      y: 335.17197,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1879",
      x: 171.89394,
      y: 356.27826,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1882",
      x: 405.03317,
      y: 174.78178,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1883",
      x: 365.59088,
      y: 187.29793,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1884",
      x: 436.28433,
      y: 167.00246,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1885",
      x: 463.92053,
      y: 159.12402,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1886",
      x: 768.4419,
      y: 249.30994,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1887",
      x: 750.35864,
      y: 243.76839,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1888",
      x: 518.2065,
      y: 386.55157,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1889",
      x: 508.94336,
      y: 350.48364,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1890",
      x: 499.13037,
      y: 337.783,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1891",
      x: 493.0125,
      y: 323.79068,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1892",
      x: 579.0443,
      y: 299.82343,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1893",
      x: 656.69403,
      y: 197.47162,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1894",
      x: 662.0386,
      y: 204.38034,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1895",
      x: 614.42993,
      y: 161.44669,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1896",
      x: 597.129,
      y: 155.48814,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1897",
      x: 360.00903,
      y: 178.25946,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1898",
      x: 660.5652,
      y: 250.83266,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1900",
      x: 428.8869,
      y: 188.0743,
      nid: "Dotyknr8",
      name: "Wayfinder 001 pietro 1",
      z: 2,
    },
    {
      id: "1901",
      x: 291.6101,
      y: 324.044,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1902",
      x: 296.33142,
      y: 334.36792,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1903",
      x: 299.07465,
      y: 342.30457,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1904",
      x: 607.2187,
      y: 281.34634,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1908",
      x: 201.15039,
      y: 348.14062,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1909",
      x: 211.3471,
      y: 345.7993,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1910",
      x: 227.9317,
      y: 341.9912,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1911",
      x: 244.5348,
      y: 338.17886,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1912",
      x: 253.78798,
      y: 237.36363,
      nid: "201b",
      name: "Home & You",
      z: 2,
    },
    {
      id: "1913",
      x: 420.71588,
      y: 405.19888,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1914",
      x: 428.48474,
      y: 410.20023,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1915",
      x: 439.8994,
      y: 412.4541,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1916",
      x: 451.43597,
      y: 410.81787,
      nid: "Dotyknr10",
      name: "Wayfinder 3 pietro 2",
      z: 2,
    },
    {
      id: "1917",
      x: 466.93747,
      y: 394.75153,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "1918",
      x: 478.08545,
      y: 386.91687,
      nid: null,
      name: "_waypoint",
      z: 2,
    },
    {
      id: "32366",
      x: 273.43375,
      y: 271.88846,
      nid: "WF+02002",
      name: "Wayfinder 002 pietro 02",
      z: 2,
    },
    {
      id: "32367",
      x: 649.096,
      y: 211.68082,
      nid: "WF+02003",
      name: "Wayfinder 003 pietro 02",
      z: 2,
    },
    {
      id: "75525",
      x: 670.7519,
      y: 267.77887,
      nid: "s233",
      name: "Millennium Bank",
      z: "2",
    },
    {
      id: "75534",
      x: 514.9266,
      y: 400.85535,
      nid: "",
      name: "",
      z: "2",
    },
    {
      id: "75535",
      x: 517.6737,
      y: 424.98895,
      nid: "s229",
      name: "GETIN_POINT",
      z: "2",
    },
    {
      id: "86280",
      x: 321.77524,
      y: 396.35986,
      nid: "234a",
      name: "CROPP/HOUSE",
      z: "2",
    },
    {
      id: "126635",
      x: 287.06372,
      y: 216.45084,
      nid: "201c",
      name: "HOMLA",
      z: "2",
    },
    {
      id: "126636",
      x: 16.470728,
      y: 189.7094,
      nid: null,
      name: "nowa nowa",
      z: "2",
    },
    {
      id: "126637",
      x: 108.71439,
      y: 167.43896,
      nid: null,
      name: "nowa nowa",
      z: "2",
    },
    {
      id: "126638",
      x: 39.54148,
      y: 185.63997,
      nid: null,
      name: "nowa nowa",
      z: "2",
    },
    {
      id: "126639",
      x: 39.54148,
      y: 185.63997,
      nid: null,
      name: "nowa nowa",
      z: "2",
    },
    {
      id: "839",
      x: 502.4068,
      y: 446.0802,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "884",
      x: 439.96832,
      y: 413.2539,
      nid: "ATM301",
      name: "Bankomat",
      z: 3,
    },
    {
      id: "981",
      x: 734.61597,
      y: 223.88931,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "982",
      x: 724.3667,
      y: 207.37688,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "983",
      x: 707.9194,
      y: 188.01349,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "984",
      x: 690.3636,
      y: 179.08076,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "985",
      x: 661.7423,
      y: 174.9701,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "986",
      x: 666.1524,
      y: 161.73863,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "987",
      x: 644.5754,
      y: 155.07729,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "988",
      x: 632.2784,
      y: 176.70343,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "989",
      x: 606.8353,
      y: 181.6032,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "990",
      x: 587.6785,
      y: 189.1579,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "991",
      x: 564.017,
      y: 201.75502,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "992",
      x: 532.4673,
      y: 212.85843,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "993",
      x: 493.9437,
      y: 219.88597,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "994",
      x: 464.48184,
      y: 225.68765,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "995",
      x: 436.18326,
      y: 227.20493,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "996",
      x: 408.9138,
      y: 228.48325,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "997",
      x: 373.03494,
      y: 235.73163,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "998",
      x: 373.34216,
      y: 246.12387,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "999",
      x: 370.25424,
      y: 258.79138,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1000",
      x: 354.16824,
      y: 267.94373,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1001",
      x: 337.36526,
      y: 279.59125,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1002",
      x: 324.10565,
      y: 292.74008,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1003",
      x: 330.71942,
      y: 312.19162,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1004",
      x: 344.4322,
      y: 325.9714,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1005",
      x: 358.36258,
      y: 336.50598,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1006",
      x: 372.97348,
      y: 344.52814,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1007",
      x: 387.66425,
      y: 347.2489,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1008",
      x: 408.0628,
      y: 351.65335,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1009",
      x: 420.79263,
      y: 353.27997,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1010",
      x: 445.36313,
      y: 355.22787,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1011",
      x: 468.02997,
      y: 356.10297,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1012",
      x: 483.31757,
      y: 356.39462,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1013",
      x: 485.97244,
      y: 343.33276,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1014",
      x: 490.72345,
      y: 329.69147,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1015",
      x: 501.88977,
      y: 316.7633,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1016",
      x: 514.194,
      y: 303.4963,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1017",
      x: 534.24854,
      y: 295.2662,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1018",
      x: 565.8702,
      y: 282.17014,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1019",
      x: 591.45844,
      y: 274.9673,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1020",
      x: 614.6387,
      y: 267.52896,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1021",
      x: 639.67645,
      y: 263.50497,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1022",
      x: 662.60175,
      y: 262.24457,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1023",
      x: 686.87274,
      y: 263.7281,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1024",
      x: 716.0055,
      y: 263.35403,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1025",
      x: 716.54114,
      y: 254.8685,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1026",
      x: 774.8113,
      y: 269.2021,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1027",
      x: 796.16956,
      y: 272.71225,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1028",
      x: 811.7484,
      y: 278.94,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1029",
      x: 870.1316,
      y: 293.99634,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1030",
      x: 893.15735,
      y: 299.81705,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1031",
      x: 926.8336,
      y: 298.92886,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1032",
      x: 912.2097,
      y: 288.40088,
      nid: "-010",
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1033",
      x: 902.0089,
      y: 280.43634,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1034",
      x: 880.48615,
      y: 275.62778,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1035",
      x: 828.30133,
      y: 256.64175,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1036",
      x: 829.9057,
      y: 241.94168,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1037",
      x: 831.05566,
      y: 223.353,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1038",
      x: 846.2012,
      y: 212.2799,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1039",
      x: 875.08966,
      y: 202.76567,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1040",
      x: 900.44806,
      y: 198.68501,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1041",
      x: 961.9983,
      y: 188.12094,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1042",
      x: 575.19275,
      y: 262.44415,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1043",
      x: 565.3001,
      y: 242.06541,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1044",
      x: 555.4733,
      y: 217.32831,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1045",
      x: 521.7497,
      y: 277.40854,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1046",
      x: 510.9234,
      y: 260.4473,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1047",
      x: 497.15607,
      y: 238.34207,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1048",
      x: 474.51974,
      y: 364.8885,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1049",
      x: 482.6423,
      y: 378.2859,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1050",
      x: 507.72833,
      y: 397.0278,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1051",
      x: 509.05176,
      y: 408.7001,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1052",
      x: 459.54153,
      y: 415.3045,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1053",
      x: 459.54153,
      y: 415.3045,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1055",
      x: 526.9598,
      y: 421.50717,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1056",
      x: 537.3367,
      y: 435.0775,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1057",
      x: 528.8512,
      y: 467.34735,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1058",
      x: 500.85898,
      y: 470.7297,
      nid: "Dotyknr6",
      name: "Wayfinder 001 pietro 3",
      z: 3,
    },
    {
      id: "1059",
      x: 515.8358,
      y: 484.11835,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1060",
      x: 550.63116,
      y: 490.42688,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1061",
      x: 577.988,
      y: 486.4957,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1062",
      x: 585.3677,
      y: 469.1047,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1063",
      x: 587.9918,
      y: 443.1196,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1064",
      x: 561.312,
      y: 431.92038,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1065",
      x: 513.49927,
      y: 453.1835,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1066",
      x: 346.4495,
      y: 253.75533,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1067",
      x: 312.6002,
      y: 248.49606,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1068",
      x: 288.01486,
      y: 242.68901,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1069",
      x: 255.68336,
      y: 232.81679,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "1322",
      x: 567.1189,
      y: 494.05896,
      nid: "303",
      name: "MAX BURGER",
      z: 3,
    },
    {
      id: "1323",
      x: 499.25116,
      y: 477.08594,
      nid: "301",
      name: "MULTIKINO",
      z: 3,
    },
    {
      id: "1324",
      x: 541.8118,
      y: 468.5489,
      nid: null,
      name: "nowa nowa",
      z: 3,
    },
    {
      id: "1325",
      x: 578.49567,
      y: 459.28137,
      nid: null,
      name: "nowa nowa",
      z: 3,
    },
    {
      id: "1327",
      x: 498.13904,
      y: 396.29547,
      nid: "",
      name: "",
      z: 3,
    },
    {
      id: "1329",
      x: 482.64148,
      y: 318.01843,
      nid: "304",
      name: "BURGER KING",
      z: 3,
    },
    {
      id: "1330",
      x: 400.24966,
      y: 342.9767,
      nid: "305",
      name: "TOKIO EXPRESS",
      z: 3,
    },
    {
      id: "1331",
      x: 353.1102,
      y: 281.572,
      nid: "307",
      name: "NORTH FISH",
      z: 3,
    },
    {
      id: "1332",
      x: 381.5375,
      y: 255.47002,
      nid: "308",
      name: "Tacamole",
      z: 3,
    },
    {
      id: "1333",
      x: 448.36108,
      y: 229.30241,
      nid: "309",
      name: "THAI WOK",
      z: 3,
    },
    {
      id: "1334",
      x: 621.5936,
      y: 258.3035,
      nid: "314",
      name: "SUBWAY",
      z: 3,
    },
    {
      id: "1335",
      x: 719.8609,
      y: 232.60504,
      nid: "313",
      name: "KFC",
      z: 3,
    },
    {
      id: "1338",
      x: 878.3985,
      y: 297.26483,
      nid: "318",
      name: "KINDER PLANETA",
      z: 3,
    },
    {
      id: "1339",
      x: 818.61127,
      y: 273.71912,
      nid: "TEL301",
      name: "Telefon",
      z: 3,
    },
    {
      id: "1340",
      x: 912.30414,
      y: 196.05203,
      nid: "WC301",
      name: "Toaleta",
      z: 3,
    },
    {
      id: "1341",
      x: 970.08356,
      y: 181.6651,
      nid: "WC302",
      name: "Toaleta",
      z: 3,
    },
    {
      id: "1342",
      x: 893.0739,
      y: 272.86008,
      nid: "317",
      name: "MCDONALD'S",
      z: 3,
    },
    {
      id: "1343",
      x: 946.61945,
      y: 297.4744,
      nid: "",
      name: "",
      z: 3,
    },
    {
      id: "1344",
      x: 674.39777,
      y: 183.46555,
      nid: "312",
      name: "SALAD STORY",
      z: 3,
    },
    {
      id: "1345",
      x: 578.09515,
      y: 198.59758,
      nid: "311",
      name: "ITALIAN CORNER & CAFE",
      z: 3,
    },
    {
      id: "1346",
      x: 659.9634,
      y: 151.61261,
      nid: "320",
      name: "FREEZE ME",
      z: 3,
    },
    {
      id: "10786",
      x: 773.50433,
      y: 258.95816,
      nid: null,
      name: "_waypoint",
      z: 3,
    },
    {
      id: "32365",
      x: 463.1197,
      y: 365.2134,
      nid: "WF+03001",
      name: "Wayfinder 01 Pi\u0119tro 3",
      z: 3,
    },
    {
      id: "65008",
      x: 729.95483,
      y: 265.56696,
      nid: null,
      name: "_waypoint",
      z: "3",
    },
    {
      id: "65009",
      x: 750.5155,
      y: 267.05627,
      nid: null,
      name: "_waypoint",
      z: "3",
    },
    {
      id: "97067",
      x: 502.29245,
      y: 483.735,
      nid: "301a",
      name: "Klub 35mm",
      z: "3",
    },
  ],
  graph: [
    ["1734", "1735", 19.235384061671343],
    ["1760", "1735", 30.0],
    ["1770", "1771", 20.12461179749811],
    ["1772", "1771", 28.160255680657446],
    ["2087", "2088", 23.021728866442675],
    ["1201", "2", 15.620499351813308],
    ["10", "2", 21.2602916254693],
    ["11", "2", 34.058772731852805],
    ["11", "10", 12.806248474865697],
    ["2", "10", 21.2602916254693],
    ["12", "10", 30.0],
    ["10", "11", 12.806248474865697],
    ["12", "11", 17.204650534085253],
    ["58", "11", 33.015148038438355],
    ["13", "12", 17.08800749063506],
    ["11", "12", 17.204650534085253],
    ["58", "12", 27.459060435491963],
    ["14", "13", 13.416407864998739],
    ["12", "13", 17.08800749063506],
    ["57", "13", 28.42534080710379],
    ["13", "14", 13.416407864998739],
    ["15", "14", 18.973665961010276],
    ["56", "14", 29.154759474226502],
    ["16", "15", 17.08800749063506],
    ["14", "15", 18.973665961010276],
    ["55", "15", 30.528675044947494],
    ["15", "16", 17.08800749063506],
    ["17", "16", 20.024984394500787],
    ["53", "16", 30.805843601498726],
    ["18", "17", 17.26267650163207],
    ["16", "17", 20.024984394500787],
    ["52", "17", 33.54101966249684],
    ["17", "18", 17.26267650163207],
    ["19", "18", 21.095023109728988],
    ["16", "18", 37.21558813185679],
    ["20", "19", 20.0],
    ["18", "19", 21.095023109728988],
    ["51", "19", 37.05401462729781],
    ["21", "20", 18.027756377319946],
    ["19", "20", 20.0],
    ["22", "20", 31.400636936215164],
    ["22", "21", 13.601470508735444],
    ["20", "21", 18.027756377319946],
    ["23", "21", 32.28002478313795],
    ["21", "22", 13.601470508735444],
    ["23", "22", 18.681541692269406],
    ["20", "22", 31.400636936215164],
    ["22", "23", 18.681541692269406],
    ["24", "23", 18.788294228055936],
    ["21", "23", 32.28002478313795],
    ["25", "24", 18.35755975068582],
    ["23", "24", 18.788294228055936],
    ["26", "24", 32.202484376209235],
    ["26", "25", 13.92838827718412],
    ["24", "25", 18.35755975068582],
    ["27", "25", 23.853720883753127],
    ["27", "26", 10.63014581273465],
    ["25", "26", 13.92838827718412],
    ["28", "26", 23.345235059857504],
    ["26", "27", 10.63014581273465],
    ["28", "27", 12.806248474865697],
    ["25", "27", 23.853720883753127],
    ["27", "28", 12.806248474865697],
    ["42", "28", 17.029386365926403],
    ["26", "28", 23.345235059857504],
    ["43", "42", 14.422205101855956],
    ["28", "42", 17.029386365926403],
    ["27", "42", 29.832867780352597],
    ["42", "43", 14.422205101855956],
    ["44", "43", 20.518284528683193],
    ["28", "43", 31.400636936215164],
    ["43", "44", 20.518284528683193],
    ["45", "44", 21.2602916254693],
    ["42", "44", 34.713109915419565],
    ["46", "45", 19.209372712298546],
    ["44", "45", 21.2602916254693],
    ["135", "45", 34.20526275297414],
    ["712", "147", 100],
    ["45", "46", 19.209372712298546],
    ["135", "46", 27.65863337187866],
    ["48", "47", 15.132745950421556],
    ["143", "47", 20.615528128088304],
    ["144", "47", 31.78049716414141],
    ["47", "48", 15.132745950421556],
    ["49", "48", 19.4164878389476],
    ["50", "48", 32.14031735997639],
    ["50", "49", 13.038404810405298],
    ["48", "49", 19.4164878389476],
    ["47", "49", 34.52535300326414],
    ["49", "50", 13.038404810405298],
    ["51", "50", 24.0],
    ["48", "50", 32.14031735997639],
    ["152", "51", 22.02271554554524],
    ["50", "51", 24.0],
    ["153", "51", 32.64965543462902],
    ["53", "52", 21.37755832643195],
    ["152", "52", 21.540659228538015],
    ["17", "52", 33.54101966249684],
    ["54", "53", 16.15549442140351],
    ["52", "53", 21.37755832643195],
    ["55", "53", 26.92582403567252],
    ["55", "54", 10.770329614269007],
    ["53", "54", 16.15549442140351],
    ["56", "54", 29.154759474226502],
    ["54", "55", 10.770329614269007],
    ["56", "55", 18.384776310850235],
    ["53", "55", 26.92582403567252],
    ["57", "56", 17.4928556845359],
    ["55", "56", 18.384776310850235],
    ["14", "56", 29.154759474226502],
    ["58", "57", 13.92838827718412],
    ["56", "57", 17.4928556845359],
    ["12", "57", 28.071337695236398],
    ["57", "58", 13.92838827718412],
    ["59", "58", 17.46424919657298],
    ["12", "58", 27.459060435491963],
    ["60", "59", 16.278820596099706],
    ["58", "59", 17.46424919657298],
    ["57", "59", 30.01666203960727],
    ["62", "60", 16.0312195418814],
    ["59", "60", 16.278820596099706],
    ["178", "60", 21.2602916254693],
    ["178", "62", 13.0],
    ["60", "62", 16.0312195418814],
    ["65", "62", 16.1245154965971],
    ["66", "65", 13.038404810405298],
    ["62", "65", 16.1245154965971],
    ["178", "65", 19.4164878389476],
    ["65", "66", 13.038404810405298],
    ["68", "66", 14.317821063276353],
    ["178", "66", 27.294688127912362],
    ["66", "68", 14.317821063276353],
    ["69", "68", 14.866068747318506],
    ["65", "68", 27.294688127912362],
    ["68", "69", 14.866068747318506],
    ["70", "69", 21.2602916254693],
    ["66", "69", 28.600699292150182],
    ["69", "70", 21.2602916254693],
    ["71", "70", 26.0],
    ["175", "70", 27.018512172212592],
    ["72", "71", 21.213203435596427],
    ["70", "71", 26.0],
    ["175", "71", 27.16615541441225],
    ["71", "72", 21.213203435596427],
    ["74", "72", 22.090722034374522],
    ["175", "72", 38.41874542459709],
    ["75", "74", 16.76305461424021],
    ["72", "74", 22.090722034374522],
    ["76", "74", 33.37663853655727],
    ["74", "75", 16.76305461424021],
    ["76", "75", 19.72308292331602],
    ["176", "75", 27.073972741361768],
    ["77", "76", 15.811388300841896],
    ["75", "76", 19.72308292331602],
    ["78", "76", 33.24154027718932],
    ["76", "77", 15.811388300841896],
    ["78", "77", 17.46424919657298],
    ["75", "77", 35.34119409414458],
    ["77", "78", 17.46424919657298],
    ["79", "78", 26.92582403567252],
    ["76", "78", 33.24154027718932],
    ["80", "79", 16.278820596099706],
    ["78", "79", 26.92582403567252],
    ["81", "79", 34.713109915419565],
    ["79", "80", 16.278820596099706],
    ["81", "80", 18.439088914585774],
    ["82", "80", 38.3275357934736],
    ["80", "81", 18.439088914585774],
    ["82", "81", 20.024984394500787],
    ["167", "81", 34.23448553724738],
    ["167", "82", 14.317821063276353],
    ["81", "82", 20.024984394500787],
    ["217", "82", 23.430749027719962],
    ["168", "83", 19.0],
    ["84", "83", 26.0],
    ["169", "83", 30.4138126514911],
    ["215", "84", 16.401219466856727],
    ["85", "84", 18.027756377319946],
    ["83", "84", 26.0],
    ["215", "85", 16.1245154965971],
    ["84", "85", 18.027756377319946],
    ["86", "85", 23.021728866442675],
    ["88", "86", 15.297058540778355],
    ["85", "86", 23.021728866442675],
    ["87", "86", 28.160255680657446],
    ["88", "87", 13.0],
    ["216", "87", 13.601470508735444],
    ["89", "87", 24.515301344262525],
    ["87", "88", 13.0],
    ["86", "88", 15.297058540778355],
    ["216", "88", 21.400934559032695],
    ["90", "89", 14.142135623730951],
    ["216", "89", 21.540659228538015],
    ["87", "89", 24.515301344262525],
    ["89", "90", 14.142135623730951],
    ["91", "90", 18.439088914585774],
    ["216", "90", 34.52535300326414],
    ["90", "91", 18.439088914585774],
    ["92", "91", 19.697715603592208],
    ["89", "91", 32.55764119219941],
    ["94", "92", 18.384776310850235],
    ["91", "92", 19.697715603592208],
    ["1967", "92", 35.12833614050059],
    ["92", "94", 18.384776310850235],
    ["95", "94", 21.540659228538015],
    ["1967", "94", 34.40930106817051],
    ["96", "95", 13.038404810405298],
    ["94", "95", 21.540659228538015],
    ["97", "95", 32.7566787083184],
    ["95", "96", 13.038404810405298],
    ["97", "96", 19.72308292331602],
    ["94", "96", 34.438350715445125],
    ["96", "97", 19.72308292331602],
    ["98", "97", 20.808652046684813],
    ["95", "97", 32.7566787083184],
    ["97", "98", 20.808652046684813],
    ["99", "98", 21.633307652783937],
    ["96", "98", 40.496913462633174],
    ["100", "99", 19.72308292331602],
    ["98", "99", 21.633307652783937],
    ["101", "99", 38.07886552931954],
    ["101", "100", 18.681541692269406],
    ["99", "100", 19.72308292331602],
    ["102", "100", 41.10960958218893],
    ["100", "101", 18.681541692269406],
    ["102", "101", 22.47220505424423],
    ["99", "101", 38.07886552931954],
    ["103", "102", 18.110770276274835],
    ["101", "102", 22.47220505424423],
    ["104", "102", 38.3275357934736],
    ["102", "103", 18.110770276274835],
    ["104", "103", 20.223748416156685],
    ["1960", "103", 35.22782990761707],
    ["103", "104", 20.223748416156685],
    ["105", "104", 21.02379604162864],
    ["1958", "104", 32.2490309931942],
    ["104", "105", 21.02379604162864],
    ["106", "105", 23.021728866442675],
    ["1958", "105", 29.427877939124322],
    ["107", "106", 16.1245154965971],
    ["105", "106", 23.021728866442675],
    ["108", "106", 26.40075756488817],
    ["106", "107", 16.1245154965971],
    ["112", "107", 18.439088914585774],
    ["108", "107", 19.0],
    ["107", "108", 19.0],
    ["109", "108", 22.0],
    ["106", "108", 26.40075756488817],
    ["108", "109", 22.0],
    ["110", "109", 24.0],
    ["107", "109", 41.0],
    ["111", "110", 22.0],
    ["109", "110", 24.0],
    ["254", "110", 43.01162633521314],
    ["254", "111", 21.02379604162864],
    ["110", "111", 22.0],
    ["109", "111", 46.0],
    ["113", "112", 11.045361017187261],
    ["107", "112", 18.439088914585774],
    ["1956", "112", 28.635642126552707],
    ["112", "113", 11.045361017187261],
    ["114", "113", 20.615528128088304],
    ["1955", "113", 29.154759474226502],
    ["115", "114", 14.560219778561036],
    ["113", "114", 20.615528128088304],
    ["1954", "114", 28.442925306655784],
    ["114", "115", 14.560219778561036],
    ["116", "115", 19.6468827043885],
    ["1954", "115", 25.632011235952593],
    ["115", "116", 19.6468827043885],
    ["117", "116", 20.248456731316587],
    ["1953", "116", 28.635642126552707],
    ["116", "117", 20.248456731316587],
    ["118", "117", 24.698178070456937],
    ["1952", "117", 29.154759474226502],
    ["117", "118", 24.698178070456937],
    ["119", "118", 25.80697580112788],
    ["1951", "118", 27.313000567495326],
    ["118", "119", 25.80697580112788],
    ["1950", "119", 26.076809620810597],
    ["120", "119", 27.586228448267445],
    ["121", "120", 21.2602916254693],
    ["1949", "120", 21.2602916254693],
    ["1948", "120", 26.076809620810597],
    ["122", "121", 18.027756377319946],
    ["120", "121", 21.2602916254693],
    ["1948", "121", 21.633307652783937],
    ["121", "122", 18.027756377319946],
    ["123", "122", 22.825424421026653],
    ["1947", "122", 36.76955262170047],
    ["124", "123", 20.591260281974],
    ["122", "123", 22.825424421026653],
    ["121", "123", 39.293765408777],
    ["123", "124", 20.591260281974],
    ["125", "124", 23.430749027719962],
    ["126", "124", 41.23105625617661],
    ["126", "125", 17.804493814764857],
    ["124", "125", 23.430749027719962],
    ["127", "125", 41.86884283091664],
    ["125", "126", 17.804493814764857],
    ["127", "126", 24.08318915758459],
    ["252", "126", 32.89376840679705],
    ["253", "127", 15.0],
    ["126", "127", 24.08318915758459],
    ["128", "127", 24.08318915758459],
    ["253", "128", 18.027756377319946],
    ["129", "128", 21.93171219946131],
    ["127", "128", 24.08318915758459],
    ["130", "129", 19.4164878389476],
    ["128", "129", 21.93171219946131],
    ["131", "129", 37.20215047547655],
    ["131", "130", 17.804493814764857],
    ["129", "130", 19.4164878389476],
    ["218", "130", 39.66106403010388],
    ["130", "131", 17.804493814764857],
    ["218", "131", 21.95449840010015],
    ["129", "131", 37.20215047547655],
    ["133", "132", 14.7648230602334],
    ["223", "132", 24.08318915758459],
    ["145", "132", 32.984845004941285],
    ["132", "133", 14.7648230602334],
    ["134", "133", 22.847319317591726],
    ["1914", "712", 100],
    ["133", "134", 22.847319317591726],
    ["135", "134", 25.079872407968907],
    ["147", "712", 100],
    ["134", "135", 25.079872407968907],
    ["46", "135", 27.65863337187866],
    ["136", "135", 30.528675044947494],
    ["137", "136", 20.0],
    ["135", "136", 30.528675044947494],
    ["44", "136", 36.359317925395686],
    ["138", "137", 16.97056274847714],
    ["136", "137", 20.0],
    ["139", "137", 34.655446902326915],
    ["137", "138", 16.97056274847714],
    ["139", "138", 17.69180601295413],
    ["136", "138", 36.87817782917155],
    ["138", "139", 17.69180601295413],
    ["140", "139", 19.209372712298546],
    ["137", "139", 34.655446902326915],
    ["139", "140", 19.209372712298546],
    ["141", "140", 20.615528128088304],
    ["138", "140", 36.87817782917155],
    ["140", "141", 20.615528128088304],
    ["142", "141", 25.059928172283335],
    ["26", "141", 38.948684188300895],
    ["144", "142", 14.866068747318506],
    ["141", "142", 25.059928172283335],
    ["143", "142", 26.0],
    ["144", "143", 11.180339887498949],
    ["47", "143", 20.615528128088304],
    ["142", "143", 26.0],
    ["143", "144", 11.180339887498949],
    ["142", "144", 14.866068747318506],
    ["47", "144", 31.78049716414141],
    ["226", "145", 26.832815729997478],
    ["132", "145", 32.984845004941285],
    ["223", "145", 34.0],
    ["2154", "840", 100],
    ["225", "146", 37.36308338453881],
    ["712", "1915", 100],
    ["840", "2155", 100],
    ["2155", "840", 100],
    ["840", "2153", 100],
    ["2153", "840", 100],
    ["149", "148", 23.769728648009426],
    ["150", "148", 41.677331968349414],
    ["150", "149", 18.439088914585774],
    ["148", "149", 23.769728648009426],
    ["151", "149", 40.22437072223753],
    ["149", "150", 18.439088914585774],
    ["151", "150", 21.95449840010015],
    ["225", "150", 33.61547262794322],
    ["150", "151", 21.95449840010015],
    ["214", "75509", 29.68164415931166],
    ["204", "205", 21.0],
    ["52", "152", 21.540659228538015],
    ["51", "152", 22.02271554554524],
    ["153", "152", 26.019223662515376],
    ["154", "153", 22.02271554554524],
    ["152", "153", 26.019223662515376],
    ["51", "153", 32.64965543462902],
    ["155", "154", 21.0],
    ["153", "154", 22.02271554554524],
    ["152", "154", 48.0],
    ["154", "155", 21.0],
    ["153", "155", 43.01162633521314],
    ["156", "155", 44.0],
    ["157", "156", 18.027756377319946],
    ["158", "156", 27.459060435491963],
    ["155", "156", 44.0],
    ["156", "157", 18.027756377319946],
    ["158", "157", 24.186773244895647],
    ["165", "157", 31.016124838541646],
    ["159", "158", 20.8806130178211],
    ["157", "158", 24.186773244895647],
    ["156", "158", 27.459060435491963],
    ["162", "159", 12.041594578792296],
    ["158", "159", 20.8806130178211],
    ["163", "159", 40.11234224026316],
    ["159", "162", 12.041594578792296],
    ["163", "162", 31.400636936215164],
    ["158", "162", 31.76476034853718],
    ["164", "163", 24.166091947189145],
    ["162", "163", 31.400636936215164],
    ["159", "163", 40.11234224026316],
    ["163", "164", 24.166091947189145],
    ["162", "164", 53.23532661682466],
    ["166", "164", 58.008620049092706],
    ["166", "165", 26.92582403567252],
    ["157", "165", 31.016124838541646],
    ["156", "165", 46.2709412050371],
    ["165", "166", 26.92582403567252],
    ["163", "166", 52.392747589718944],
    ["157", "166", 55.14526271584895],
    ["82", "167", 14.317821063276353],
    ["168", "167", 18.110770276274835],
    ["217", "167", 21.02379604162864],
    ["167", "168", 18.110770276274835],
    ["83", "168", 19.0],
    ["169", "168", 22.090722034374522],
    ["170", "169", 19.026297590440446],
    ["168", "169", 22.090722034374522],
    ["167", "169", 28.844410203711913],
    ["169", "170", 19.026297590440446],
    ["171", "170", 22.02271554554524],
    ["172", "170", 39.01281840626232],
    ["172", "171", 17.0],
    ["170", "171", 22.02271554554524],
    ["173", "171", 30.463092423455635],
    ["171", "172", 17.0],
    ["173", "172", 28.442925306655784],
    ["170", "172", 39.01281840626232],
    ["174", "173", 21.02379604162864],
    ["172", "173", 28.442925306655784],
    ["171", "173", 30.463092423455635],
    ["837", "174", 18.384776310850235],
    ["173", "174", 21.02379604162864],
    ["172", "174", 47.16990566028302],
    ["70", "175", 27.018512172212592],
    ["71", "175", 27.16615541441225],
    ["177", "175", 35.35533905932738],
    ["1981", "176", 17.72004514666935],
    ["1982", "176", 19.4164878389476],
    ["75", "176", 27.073972741361768],
    ["1984", "177", 15.0],
    ["1983", "177", 25.0],
    ["1201", "177", 29.410882339705484],
    ["62", "178", 13.0],
    ["65", "178", 19.4164878389476],
    ["60", "178", 21.2602916254693],
    ["189", "192", 61.032778078668514],
    ["190", "197", 52.0],
    ["192", "197", 31.016124838541646],
    ["200", "206", 14.7648230602334],
    ["191", "189", 21.0],
    ["75509", "186", 18.788294228055936],
    ["183", "186", 47.12748667179272],
    ["186", "189", 40.049968789001575],
    ["185", "189", 63.071388124885914],
    ["75510", "188", 15.132745950421556],
    ["207", "75520", 16.76305461424021],
    ["75515", "75508", 18.027756377319946],
    ["214", "75508", 15.033296378372908],
    ["190", "188", 24.020824298928627],
    ["75515", "186", 59.90826320300064],
    ["205", "75520", 17.4928556845359],
    ["75515", "183", 19.6468827043885],
    ["183", "185", 26.627053911388696],
    ["75515", "185", 43.41658669218482],
    ["189", "190", 40.01249804748511],
    ["191", "190", 61.00819617067857],
    ["188", "197", 76.00657866263946],
    ["189", "197", 92.00543462209176],
    ["197", "195", 17.0],
    ["186", "191", 19.1049731745428],
    ["185", "75508", 34.20526275297414],
    ["185", "186", 23.021728866442675],
    ["198", "193", 114.0],
    ["186", "190", 80.00624975587844],
    ["185", "191", 42.1070065428546],
    ["193", "196", 17.11724276862369],
    ["198", "75514", 81.0],
    ["192", "195", 48.010415536631214],
    ["190", "195", 69.0],
    ["202", "75514", 95.00526301210897],
    ["201", "196", 36.05551275463989],
    ["195", "75514", 116.0],
    ["75514", "201", 14.0],
    ["188", "195", 93.00537618869137],
    ["205", "75513", 35.510561809129406],
    ["203", "75513", 37.20215047547655],
    ["75513", "181", 19.1049731745428],
    ["203", "181", 32.14031735997639],
    ["199", "75501", 36.05551275463989],
    ["75500", "75497", 22.02271554554524],
    ["75501", "213", 16.0312195418814],
    ["75500", "213", 23.0],
    ["199", "213", 33.24154027718932],
    ["75514", "213", 40.607881008493905],
    ["196", "179", 30.805843601498726],
    ["198", "75504", 33.0],
    ["203", "179", 33.24154027718932],
    ["75513", "179", 34.0147027033899],
    ["179", "182", 22.0],
    ["193", "182", 32.0624390837628],
    ["75504", "208", 34.0],
    ["197", "208", 37.589892258425],
    ["195", "75516", 37.12142238654117],
    ["208", "75516", 18.0],
    ["75516", "75517", 19.026297590440446],
    ["192", "75517", 37.0],
    ["75503", "206", 17.72004514666935],
    ["210", "75511", 12.36931687685298],
    ["75503", "1975", 11.704699910719626],
    ["75503", "1974", 17.804493814764857],
    ["75503", "207", 26.076809620810597],
    ["202", "75504", 36.76955262170047],
    ["1954", "75527", 19.849433241279208],
    ["198", "212", 36.6742416417845],
    ["75504", "209", 16.0],
    ["75501", "213", 16],
    ["75504", "75505", 17],
    ["208", "209", 18],
    ["209", "75504", 16.0],
    ["75516", "208", 18.0],
    ["208", "75516", 18],
    ["75506", "75516", 17],
    ["207", "75520", 16.76305461424021],
    ["75520", "207", 16],
    ["75516", "75517", 19.026297590440446],
    ["75511", "210", 12],
    ["196", "204", 39.01281840626232],
    ["207", "75513", 15.033296378372908],
    ["210", "75510", 14.317821063276353],
    ["75514", "203", 69.00724599634447],
    ["205", "206", 29.546573405388315],
    ["201", "203", 55.00909015790027],
    ["75514", "196", 50.039984012787215],
    ["203", "205", 41.0],
    ["196", "205", 60.00833275470999],
    ["193", "205", 77.00649323271382],
    ["199", "75514", 21.02379604162864],
    ["201", "204", 75.0066663703967],
    ["197", "199", 112.0044641967453],
    ["191", "192", 82.02438661763951],
    ["195", "199", 95.00526301210897],
    ["75509", "191", 18.601075237738275],
    ["75515", "214", 33.0],
    ["183", "214", 33.83784863137726],
    ["75502", "214", 28.071337695236398],
    ["210", "75510", 14.317821063276353],
    ["188", "75510", 15.132745950421556],
    ["85", "215", 16.1245154965971],
    ["84", "215", 16.401219466856727],
    ["86", "215", 33.61547262794322],
    ["87", "216", 13.601470508735444],
    ["88", "216", 21.400934559032695],
    ["89", "216", 21.540659228538015],
    ["167", "217", 21.02379604162864],
    ["82", "217", 23.430749027719962],
    ["1976", "217", 25.019992006393608],
    ["219", "218", 21.095023109728988],
    ["131", "218", 21.95449840010015],
    ["130", "218", 39.66106403010388],
    ["218", "219", 21.095023109728988],
    ["220", "219", 26.419689627245813],
    ["131", "219", 43.04648650006177],
    ["221", "220", 21.93171219946131],
    ["219", "220", 26.419689627245813],
    ["222", "220", 43.86342439892262],
    ["220", "221", 21.93171219946131],
    ["222", "221", 21.93171219946131],
    ["219", "221", 48.30113870293329],
    ["221", "222", 21.93171219946131],
    ["220", "222", 43.86342439892262],
    ["219", "222", 70.21395872616783],
    ["226", "223", 19.697715603592208],
    ["132", "223", 24.08318915758459],
    ["145", "223", 34.0],
    ["230", "224", 21.540659228538015],
    ["229", "224", 29.410882339705484],
    ["231", "224", 34.17601498127012],
    ["150", "225", 33.61547262794322],
    ["146", "225", 37.36308338453881],
    ["151", "225", 40.24922359499622],
    ["227", "226", 18.439088914585774],
    ["223", "226", 19.697715603592208],
    ["145", "226", 26.832815729997478],
    ["226", "227", 18.439088914585774],
    ["228", "227", 22.47220505424423],
    ["145", "227", 36.05551275463989],
    ["229", "228", 16.0],
    ["227", "228", 22.47220505424423],
    ["230", "228", 33.95585369269929],
    ["228", "229", 16.0],
    ["230", "229", 18.788294228055936],
    ["224", "229", 29.410882339705484],
    ["229", "230", 18.788294228055936],
    ["224", "230", 21.540659228538015],
    ["231", "230", 25.298221281347036],
    ["232", "231", 21.02379604162864],
    ["230", "231", 25.298221281347036],
    ["224", "231", 34.17601498127012],
    ["242", "232", 17.4928556845359],
    ["231", "232", 21.02379604162864],
    ["233", "232", 23.021728866442675],
    ["232", "233", 23.021728866442675],
    ["234", "233", 23.706539182259394],
    ["242", "233", 28.071337695236398],
    ["233", "234", 23.706539182259394],
    ["235", "234", 26.40075756488817],
    ["10785", "234", 40.24922359499622],
    ["10785", "235", 15.132745950421556],
    ["234", "235", 26.40075756488817],
    ["236", "235", 47.634021455258214],
    ["237", "236", 22.02271554554524],
    ["10785", "236", 38.07886552931954],
    ["238", "236", 46.04345773288535],
    ["236", "237", 22.02271554554524],
    ["238", "237", 24.186773244895647],
    ["239", "237", 45.18849411078001],
    ["239", "238", 21.18962010041709],
    ["237", "238", 24.186773244895647],
    ["240", "238", 28.160255680657446],
    ["238", "239", 21.18962010041709],
    ["240", "239", 27.018512172212592],
    ["237", "239", 45.18849411078001],
    ["241", "240", 21.02379604162864],
    ["239", "240", 27.018512172212592],
    ["238", "240", 28.160255680657446],
    ["240", "241", 21.02379604162864],
    ["239", "241", 45.69463863518345],
    ["238", "241", 49.040799340956916],
    ["232", "242", 17.4928556845359],
    ["243", "242", 26.248809496813376],
    ["233", "242", 28.071337695236398],
    ["242", "243", 26.248809496813376],
    ["244", "243", 28.231188426986208],
    ["232", "243", 43.46262762420146],
    ["245", "244", 28.178005607210743],
    ["243", "244", 28.231188426986208],
    ["242", "244", 54.42425929675111],
    ["244", "245", 28.178005607210743],
    ["246", "245", 54.48853090330111],
    ["243", "245", 56.36488268416781],
    ["247", "246", 19.849433241279208],
    ["1940", "246", 35.84689665786984],
    ["1941", "246", 39.11521443121589],
    ["246", "247", 19.849433241279208],
    ["1941", "247", 26.0],
    ["1940", "247", 34.88552708502482],
    ["249", "248", 27.073972741361768],
    ["246", "248", 44.04543109109048],
    ["247", "248", 47.853944456021594],
    ["248", "249", 27.073972741361768],
    ["250", "249", 29.154759474226502],
    ["251", "249", 56.0],
    ["251", "250", 27.16615541441225],
    ["249", "250", 29.154759474226502],
    ["248", "250", 56.22277118748239],
    ["250", "251", 27.16615541441225],
    ["252", "251", 31.400636936215164],
    ["126", "251", 49.39635614091387],
    ["253", "252", 27.073972741361768],
    ["127", "252", 29.966648127543394],
    ["251", "252", 31.400636936215164],
    ["127", "253", 15.0],
    ["128", "253", 18.027756377319946],
    ["252", "253", 27.073972741361768],
    ["111", "254", 21.02379604162864],
    ["255", "254", 27.294688127912362],
    ["257", "254", 41.677331968349414],
    ["256", "255", 20.615528128088304],
    ["254", "255", 27.294688127912362],
    ["257", "255", 32.55764119219941],
    ["255", "256", 20.615528128088304],
    ["257", "256", 37.48332962798263],
    ["254", "256", 47.853944456021594],
    ["258", "257", 24.351591323771842],
    ["255", "257", 32.55764119219941],
    ["256", "257", 37.48332962798263],
    ["259", "258", 13.892443989449804],
    ["257", "258", 24.351591323771842],
    ["256", "258", 53.14132102234569],
    ["258", "259", 13.892443989449804],
    ["257", "259", 38.07886552931954],
    ["256", "259", 62.96824596572466],
    ["8", "260", 30.675723300355934],
    ["2158", "260", 33.61547262794322],
    ["261", "260", 34.40930106817051],
    ["2157", "261", 30.0],
    ["8", "261", 30.01666203960727],
    ["260", "261", 34.40930106817051],
    ["2073", "271", 30.4138126514911],
    ["272", "271", 34.17601498127012],
    ["2075", "271", 34.88552708502482],
    ["2166", "272", 25.632011235952593],
    ["271", "272", 34.17601498127012],
    ["1070", "272", 34.88552708502482],
    ["2078", "273", 17.26267650163207],
    ["2077", "273", 18.867962264113206],
    ["2168", "273", 35.510561809129406],
    ["1833", "831", 20.248456731316587],
    ["1873", "831", 38.600518131237564],
    ["1897", "831", 59.43904440685432],
    ["2155", "832", 21.400934559032695],
    ["2038", "832", 32.2490309931942],
    ["832", "833", 37.64306044943742],
    ["2035", "833", 40.80441152620633],
    ["2036", "833", 42.15447781671598],
    ["2029", "834", 29.154759474226502],
    ["2030", "834", 32.7566787083184],
    ["2028", "834", 36.6742416417845],
    ["2149", "835", 40.496913462633174],
    ["2150", "835", 41.43669871020132],
    ["2089", "836", 15.524174696260024],
    ["2085", "836", 24.08318915758459],
    ["2090", "836", 24.20743687382041],
    ["174", "837", 18.384776310850235],
    ["173", "837", 39.395431207184416],
    ["172", "837", 64.93843238021688],
    ["1915", "712", 100],
    ["712", "840", 100],
    ["712", "1916", 100],
    ["840", "712", 100],
    ["712", "841", 100],
    ["841", "712", 100],
    ["912", "911", 31.76476034853718],
    ["927", "911", 55.569775957799216],
    ["928", "911", 61.220911460055866],
    ["913", "912", 29.68164415931166],
    ["911", "912", 31.76476034853718],
    ["914", "912", 56.0357029044876],
    ["914", "913", 26.40075756488817],
    ["912", "913", 29.68164415931166],
    ["915", "913", 47.70744176750625],
    ["915", "914", 21.470910553583888],
    ["913", "914", 26.40075756488817],
    ["916", "914", 43.41658669218482],
    ["914", "915", 21.470910553583888],
    ["916", "915", 21.95449840010015],
    ["917", "915", 43.04648650006177],
    ["917", "916", 21.095023109728988],
    ["915", "916", 21.95449840010015],
    ["918", "916", 32.2490309931942],
    ["918", "917", 17.11724276862369],
    ["916", "917", 21.095023109728988],
    ["919", "917", 35.12833614050059],
    ["917", "918", 17.11724276862369],
    ["919", "918", 18.027756377319946],
    ["940", "918", 25.059928172283335],
    ["940", "919", 13.601470508735444],
    ["918", "919", 18.027756377319946],
    ["920", "919", 19.1049731745428],
    ["958", "1094", 18],
    ["940", "920", 18.601075237738275],
    ["919", "920", 19.1049731745428],
    ["1094", "954", 18.0],
    ["954", "1094", 18],
    ["1096", "954", 25.0],
    ["106657", "1096", 12],
    ["954", "1096", 25],
    ["925", "971", 18.0],
    ["928", "927", 22.360679774997898],
    ["929", "927", 41.14608122288197],
    ["930", "927", 55.47071299343465],
    ["929", "928", 18.788294228055936],
    ["927", "928", 22.360679774997898],
    ["930", "928", 33.12099032335839],
    ["930", "929", 14.422205101855956],
    ["928", "929", 18.788294228055936],
    ["931", "929", 33.61547262794322],
    ["929", "930", 14.422205101855956],
    ["931", "930", 19.235384061671343],
    ["928", "930", 33.12099032335839],
    ["932", "931", 16.1245154965971],
    ["930", "931", 19.235384061671343],
    ["929", "931", 33.61547262794322],
    ["931", "932", 16.1245154965971],
    ["933", "932", 18.439088914585774],
    ["934", "932", 33.83784863137726],
    ["934", "933", 15.652475842498529],
    ["932", "933", 18.439088914585774],
    ["935", "933", 29.832867780352597],
    ["935", "934", 15.0],
    ["933", "934", 15.652475842498529],
    ["932", "934", 33.83784863137726],
    ["934", "935", 15.0],
    ["936", "935", 21.93171219946131],
    ["933", "935", 29.832867780352597],
    ["937", "936", 20.518284528683193],
    ["935", "936", 21.93171219946131],
    ["934", "936", 36.87817782917155],
    ["936", "937", 20.518284528683193],
    ["938", "937", 23.40939982143925],
    ["939", "937", 34.36568055487916],
    ["939", "938", 17.69180601295413],
    ["937", "938", 23.40939982143925],
    ["941", "938", 41.773197148410844],
    ["938", "939", 17.69180601295413],
    ["937", "939", 34.36568055487916],
    ["940", "939", 39.6232255123179],
    ["919", "940", 13.601470508735444],
    ["920", "940", 18.601075237738275],
    ["918", "940", 25.059928172283335],
    ["949", "941", 33.24154027718932],
    ["938", "941", 41.773197148410844],
    ["943", "942", 24.041630560342615],
    ["949", "942", 41.72529209005013],
    ["952", "942", 44.384682042344295],
    ["942", "943", 24.041630560342615],
    ["952", "943", 26.90724809414742],
    ["944", "943", 27.892651361962706],
    ["952", "944", 25.96150997149434],
    ["945", "944", 27.073972741361768],
    ["943", "944", 27.892651361962706],
    ["946", "945", 21.02379604162864],
    ["944", "945", 27.073972741361768],
    ["947", "945", 41.30375285612676],
    ["947", "946", 20.8806130178211],
    ["945", "946", 21.02379604162864],
    ["948", "946", 40.0],
    ["946", "947", 20.8806130178211],
    ["948", "947", 26.30589287593181],
    ["945", "947", 41.30375285612676],
    ["947", "948", 26.30589287593181],
    ["946", "948", 40.0],
    ["951", "948", 47.38143096192854],
    ["950", "949", 26.076809620810597],
    ["941", "949", 33.24154027718932],
    ["942", "949", 41.72529209005013],
    ["951", "950", 25.079872407968907],
    ["949", "950", 26.076809620810597],
    ["941", "950", 55.47071299343465],
    ["950", "951", 25.079872407968907],
    ["948", "951", 47.38143096192854],
    ["949", "951", 51.15662224971465],
    ["944", "952", 25.96150997149434],
    ["943", "952", 26.90724809414742],
    ["106650", "953", 22.20360331117452],
    ["955", "953", 25.019992006393608],
    ["971", "925", 18],
    ["960", "959", 24.020824298928627],
    ["106644", "966", 16.15549442140351],
    ["961", "960", 15.033296378372908],
    ["959", "960", 24.020824298928627],
    ["960", "961", 15.033296378372908],
    ["962", "961", 30.59411708155671],
    ["959", "961", 39.0],
    ["963", "962", 29.410882339705484],
    ["961", "962", 30.59411708155671],
    ["960", "962", 45.27692569068709],
    ["962", "963", 29.410882339705484],
    ["961", "963", 58.077534382926416],
    ["960", "963", 73.10950690573696],
    ["106642", "966", 32.55764119219941],
    ["106645", "966", 50.24937810560445],
    ["106641", "966", 54.3323108288245],
    ["106643", "966", 15.231546211727817],
    ["32355", "964", 11.045361017187261],
    ["106644", "966", 16.15549442140351],
    ["979", "978", 24.698178070456937],
    ["980", "978", 57.14017850864661],
    ["978", "979", 24.698178070456937],
    ["980", "979", 50.60632371551998],
    ["979", "980", 50.60632371551998],
    ["978", "980", 57.14017850864661],
    ["982", "981", 24.08318915758459],
    ["1025", "981", 44.82186966202994],
    ["983", "981", 53.600373133029585],
    ["981", "982", 24.08318915758459],
    ["983", "982", 29.614185789921695],
    ["984", "982", 48.104053883222775],
    ["984", "983", 19.4164878389476],
    ["982", "983", 29.614185789921695],
    ["985", "983", 43.93176527297759],
    ["983", "984", 19.4164878389476],
    ["985", "984", 25.553864678361276],
    ["986", "984", 30.870698080866262],
    ["986", "985", 17.4928556845359],
    ["984", "985", 25.553864678361276],
    ["988", "985", 25.709920264364882],
    ["985", "986", 17.4928556845359],
    ["987", "986", 20.518284528683193],
    ["984", "986", 30.870698080866262],
    ["986", "987", 20.518284528683193],
    ["985", "987", 29.614185789921695],
    ["988", "987", 29.832867780352597],
    ["989", "988", 23.021728866442675],
    ["985", "988", 25.709920264364882],
    ["987", "988", 29.832867780352597],
    ["990", "989", 19.4164878389476],
    ["988", "989", 23.021728866442675],
    ["991", "989", 45.880278987817846],
    ["989", "990", 19.4164878389476],
    ["991", "990", 26.570660511172846],
    ["988", "990", 42.1070065428546],
    ["1044", "991", 22.02271554554524],
    ["990", "991", 26.570660511172846],
    ["992", "991", 31.400636936215164],
    ["1044", "992", 20.808652046684813],
    ["991", "992", 31.400636936215164],
    ["993", "992", 35.05709628591621],
    ["1047", "993", 24.73863375370596],
    ["994", "993", 27.018512172212592],
    ["992", "993", 35.05709628591621],
    ["995", "994", 24.73863375370596],
    ["993", "994", 27.018512172212592],
    ["1047", "994", 32.64965543462902],
    ["996", "995", 23.769728648009426],
    ["994", "995", 24.73863375370596],
    ["993", "995", 51.478150704935004],
    ["995", "996", 23.769728648009426],
    ["997", "996", 33.015148038438355],
    ["998", "996", 39.84971769034255],
    ["998", "997", 13.92838827718412],
    ["999", "997", 31.304951684997057],
    ["996", "997", 33.015148038438355],
    ["997", "998", 13.92838827718412],
    ["999", "998", 17.4928556845359],
    ["1066", "998", 26.076809620810597],
    ["998", "999", 17.4928556845359],
    ["1000", "999", 19.313207915827967],
    ["1066", "999", 21.400934559032695],
    ["999", "1000", 19.313207915827967],
    ["1066", "1000", 20.024984394500787],
    ["1001", "1000", 22.360679774997898],
    ["1002", "1001", 22.20360331117452],
    ["1000", "1001", 22.360679774997898],
    ["1066", "1001", 36.61966684720111],
    ["1001", "1002", 22.20360331117452],
    ["1003", "1002", 27.459060435491963],
    ["1000", "1002", 44.41846462902562],
    ["1004", "1003", 22.360679774997898],
    ["1002", "1003", 27.459060435491963],
    ["1005", "1003", 41.23105625617661],
    ["1005", "1004", 18.973665961010276],
    ["1003", "1004", 22.360679774997898],
    ["1006", "1004", 35.84689665786984],
    ["1006", "1005", 17.0],
    ["1004", "1005", 18.973665961010276],
    ["1007", "1005", 29.832867780352597],
    ["1007", "1006", 13.601470508735444],
    ["1005", "1006", 17.0],
    ["1008", "1006", 32.802438933713454],
    ["1006", "1007", 13.601470508735444],
    ["1008", "1007", 19.209372712298546],
    ["1005", "1007", 29.832867780352597],
    ["1009", "1008", 11.661903789690601],
    ["1007", "1008", 19.209372712298546],
    ["1006", "1008", 32.802438933713454],
    ["1008", "1009", 11.661903789690601],
    ["1010", "1009", 22.360679774997898],
    ["1007", "1009", 30.805843601498726],
    ["1011", "1010", 20.615528128088304],
    ["1009", "1010", 22.360679774997898],
    ["1048", "1010", 29.732137494637012],
    ["1012", "1011", 13.92838827718412],
    ["1048", "1011", 14.035668847618199],
    ["1010", "1011", 20.615528128088304],
    ["1011", "1012", 13.92838827718412],
    ["1048", "1012", 15.0],
    ["1013", "1012", 19.235384061671343],
    ["1012", "1013", 19.235384061671343],
    ["1014", "1013", 20.248456731316587],
    ["1011", "1013", 25.059928172283335],
    ["1013", "1014", 20.248456731316587],
    ["1015", "1014", 21.2602916254693],
    ["1012", "1014", 39.44616584663204],
    ["1014", "1015", 21.2602916254693],
    ["1016", "1015", 22.02271554554524],
    ["1013", "1015", 41.10960958218893],
    ["1017", "1016", 21.587033144922902],
    ["1015", "1016", 22.02271554554524],
    ["1045", "1016", 37.21558813185679],
    ["1016", "1017", 21.587033144922902],
    ["1045", "1017", 27.073972741361768],
    ["1018", "1017", 33.95585369269929],
    ["1019", "1018", 25.079872407968907],
    ["1042", "1018", 28.600699292150182],
    ["1017", "1018", 33.95585369269929],
    ["1042", "1019", 22.47220505424423],
    ["1020", "1019", 23.194827009486403],
    ["1018", "1019", 25.079872407968907],
    ["1021", "1020", 23.08679276123039],
    ["1019", "1020", 23.194827009486403],
    ["1042", "1020", 35.84689665786984],
    ["1022", "1021", 20.615528128088304],
    ["1020", "1021", 23.08679276123039],
    ["1023", "1021", 42.37924020083418],
    ["1021", "1022", 20.615528128088304],
    ["1023", "1022", 21.93171219946131],
    ["1020", "1022", 43.56604182158393],
    ["1022", "1023", 21.93171219946131],
    ["1024", "1023", 26.248809496813376],
    ["1025", "1023", 29.154759474226502],
    ["1025", "1024", 11.704699910719626],
    ["1023", "1024", 26.248809496813376],
    ["1022", "1024", 48.104053883222775],
    ["1024", "1025", 11.704699910719626],
    ["1023", "1025", 29.154759474226502],
    ["981", "1025", 44.82186966202994],
    ["10786", "1026", 14.317821063276353],
    ["1027", "1026", 20.248456731316587],
    ["1028", "1026", 36.87817782917155],
    ["1028", "1027", 17.029386365926403],
    ["1026", "1027", 20.248456731316587],
    ["10786", "1027", 28.653097563788805],
    ["1027", "1028", 17.029386365926403],
    ["1035", "1028", 33.97057550292606],
    ["1026", "1028", 36.87817782917155],
    ["1030", "1029", 23.430749027719962],
    ["1034", "1029", 27.202941017470888],
    ["1033", "1029", 34.20526275297414],
    ["1032", "1030", 23.259406699226016],
    ["1029", "1030", 23.430749027719962],
    ["1033", "1030", 28.30194339616981],
    ["1032", "1031", 21.02379604162864],
    ["1030", "1031", 31.32091952673165],
    ["1033", "1031", 36.24913792078372],
    ["1033", "1032", 15.231546211727817],
    ["1031", "1032", 21.02379604162864],
    ["1030", "1032", 23.259406699226016],
    ["1032", "1033", 15.231546211727817],
    ["1034", "1033", 21.400934559032695],
    ["1030", "1033", 28.30194339616981],
    ["1033", "1034", 21.400934559032695],
    ["1029", "1034", 27.202941017470888],
    ["1032", "1034", 35.4682957019364],
    ["1036", "1035", 20.248456731316587],
    ["1028", "1035", 33.97057550292606],
    ["1027", "1035", 36.05551275463989],
    ["1035", "1036", 20.248456731316587],
    ["1037", "1036", 25.298221281347036],
    ["1038", "1036", 42.20189569201838],
    ["1038", "1037", 19.72308292331602],
    ["1036", "1037", 25.298221281347036],
    ["1035", "1037", 45.5411901469428],
    ["1037", "1038", 19.72308292331602],
    ["1039", "1038", 28.284271247461902],
    ["1036", "1038", 42.20189569201838],
    ["1040", "1039", 23.08679276123039],
    ["1038", "1039", 28.284271247461902],
    ["1037", "1039", 47.12748667179272],
    ["1039", "1040", 23.08679276123039],
    ["1038", "1040", 51.03920062069938],
    ["1041", "1040", 56.142675390472796],
    ["1040", "1041", 56.142675390472796],
    ["1039", "1041", 79.22752047110903],
    ["1038", "1041", 107.01868995647442],
    ["1019", "1042", 22.47220505424423],
    ["1018", "1042", 28.600699292150182],
    ["1043", "1042", 29.017236257093817],
    ["1042", "1043", 29.017236257093817],
    ["1044", "1043", 34.132096331752024],
    ["992", "1043", 48.08326112068523],
    ["992", "1044", 20.808652046684813],
    ["991", "1044", 22.02271554554524],
    ["1043", "1044", 34.132096331752024],
    ["1046", "1045", 25.019992006393608],
    ["1017", "1045", 27.073972741361768],
    ["1016", "1045", 37.21558813185679],
    ["1045", "1046", 25.019992006393608],
    ["1047", "1046", 32.01562118716424],
    ["1017", "1046", 52.08646657242167],
    ["993", "1047", 24.73863375370596],
    ["1046", "1047", 32.01562118716424],
    ["994", "1047", 32.64965543462902],
    ["1011", "1048", 14.035668847618199],
    ["1012", "1048", 15.0],
    ["1049", "1048", 21.0],
    ["1048", "1049", 21.0],
    ["1012", "1049", 32.31098884280702],
    ["1011", "1049", 35.014282800023196],
    ["1051", "1050", 17.72004514666935],
    ["1049", "1050", 36.05551275463989],
    ["1050", "1051", 17.72004514666935],
    ["1052", "1051", 47.38143096192854],
    ["1053", "1052", 0.0],
    ["1051", "1052", 47.38143096192854],
    ["1050", "1052", 53.150729063673246],
    ["1053", "1053", 0.0],
    ["1051", "1053", 47.38143096192854],
    ["1050", "1053", 53.150729063673246],
    ["1064", "1056", 23.194827009486403],
    ["1065", "1056", 36.359317925395686],
    ["1065", "1057", 26.68332812825267],
    ["1058", "1057", 27.294688127912362],
    ["1059", "1057", 29.698484809834994],
    ["1059", "1058", 25.709920264364882],
    ["1057", "1058", 27.294688127912362],
    ["1065", "1058", 30.4138126514911],
    ["1058", "1059", 25.709920264364882],
    ["1057", "1059", 29.698484809834994],
    ["1060", "1059", 35.0],
    ["1061", "1060", 27.16615541441225],
    ["1059", "1060", 35.0],
    ["1057", "1060", 42.579337712087536],
    ["1060", "1061", 27.16615541441225],
    ["1062", "1061", 28.844410203711913],
    ["1057", "1061", 56.4003546088143],
    ["1061", "1062", 28.844410203711913],
    ["1063", "1062", 41.23105625617661],
    ["1060", "1062", 47.853944456021594],
    ["1064", "1063", 30.805843601498726],
    ["1062", "1063", 41.23105625617661],
    ["1056", "1063", 49.64876634922564],
    ["1056", "1064", 23.194827009486403],
    ["1063", "1064", 30.805843601498726],
    ["1057", "1065", 26.68332812825267],
    ["1058", "1065", 30.4138126514911],
    ["1000", "1066", 20.024984394500787],
    ["999", "1066", 21.400934559032695],
    ["998", "1066", 26.076809620810597],
    ["1068", "1067", 22.02271554554524],
    ["1066", "1067", 29.68164415931166],
    ["1000", "1067", 43.266615305567875],
    ["1067", "1068", 22.02271554554524],
    ["1069", "1068", 29.698484809834994],
    ["1066", "1068", 51.61395160225576],
    ["1068", "1069", 29.698484809834994],
    ["1067", "1069", 51.66236541235796],
    ["1066", "1069", 81.05553651663777],
    ["2165", "1070", 31.89043743820395],
    ["272", "1070", 34.88552708502482],
    ["2166", "1070", 40.792156108742276],
    ["1879", "1194", 25.317977802344327],
    ["1878", "1194", 51.894122981316485],
    ["1764", "1194", 54.08326913195984],
    ["2", "1201", 15.620499351813308],
    ["1984", "1201", 24.08318915758459],
    ["177", "1201", 29.410882339705484],
    ["1712", "1219", 25.495097567963924],
    ["1872", "1219", 26.076809620810597],
    ["1911", "1219", 27.018512172212592],
    ["1865", "1680", 24.73863375370596],
    ["1816", "1680", 38.05259518088089],
    ["1702", "1680", 39.824615503479755],
    ["1866", "1682", 28.635642126552707],
    ["1701", "1682", 39.21734310225516],
    ["1680", "1682", 41.48493702538308],
    ["1693", "1685", 16.492422502470642],
    ["1686", "1685", 17.46424919657298],
    ["1694", "1685", 37.94733192202055],
    ["1685", "1686", 17.46424919657298],
    ["1893", "1686", 22.360679774997898],
    ["1693", "1686", 33.95585369269929],
    ["1839", "1689", 21.095023109728988],
    ["1690", "1689", 24.186773244895647],
    ["1694", "1689", 45.880278987817846],
    ["1691", "1690", 23.40939982143925],
    ["1689", "1690", 24.186773244895647],
    ["1693", "1690", 42.37924020083418],
    ["1690", "1691", 23.40939982143925],
    ["1692", "1691", 26.248809496813376],
    ["1686", "1691", 40.11234224026316],
    ["1699", "1692", 22.561028345356956],
    ["1691", "1692", 26.248809496813376],
    ["1893", "1692", 36.49657518178932],
    ["1685", "1693", 16.492422502470642],
    ["1694", "1693", 21.540659228538015],
    ["1686", "1693", 33.95585369269929],
    ["1693", "1694", 21.540659228538015],
    ["1895", "1694", 23.259406699226016],
    ["1685", "1694", 37.94733192202055],
    ["1870", "1698", 19.235384061671343],
    ["1799", "1698", 20.8806130178211],
    ["1800", "1698", 23.323807579381203],
    ["1692", "1699", 22.561028345356956],
    ["1811", "1699", 25.179356624028344],
    ["1894", "1699", 34.785054261852174],
    ["1839", "1700", 25.612496949731394],
    ["1701", "1700", 34.9857113690718],
    ["1866", "1700", 43.93176527297759],
    ["1816", "1701", 26.92582403567252],
    ["1700", "1701", 34.9857113690718],
    ["1682", "1701", 39.21734310225516],
    ["1816", "1702", 21.213203435596427],
    ["1703", "1702", 23.194827009486403],
    ["1706", "1702", 38.28837943815329],
    ["1706", "1703", 22.80350850198276],
    ["1704", "1703", 23.021728866442675],
    ["1702", "1703", 23.194827009486403],
    ["1703", "1704", 23.021728866442675],
    ["1706", "1704", 27.018512172212592],
    ["1705", "1704", 27.073972741361768],
    ["1707", "1705", 20.09975124224178],
    ["1704", "1705", 27.073972741361768],
    ["1708", "1705", 33.421549934136806],
    ["1703", "1706", 22.80350850198276],
    ["1864", "1706", 25.238858928247925],
    ["1704", "1706", 27.018512172212592],
    ["1705", "1707", 20.09975124224178],
    ["1708", "1707", 23.769728648009426],
    ["1900", "1707", 40.0],
    ["1884", "1708", 19.697715603592208],
    ["1707", "1708", 23.769728648009426],
    ["1863", "1708", 24.413111231467404],
    ["1827", "1709", 22.47220505424423],
    ["1900", "1709", 30.14962686336267],
    ["1826", "1709", 42.2965719651132],
    ["1219", "1712", 25.495097567963924],
    ["1859", "1712", 26.92582403567252],
    ["1901", "1712", 31.32091952673165],
    ["1717", "1716", 28.0178514522438],
    ["1862", "1716", 29.832867780352597],
    ["1861", "1716", 48.010415536631214],
    ["1718", "1717", 27.313000567495326],
    ["1716", "1717", 28.0178514522438],
    ["1719", "1717", 52.478567053607705],
    ["1719", "1718", 25.612496949731394],
    ["1717", "1718", 27.313000567495326],
    ["1835", "1718", 53.85164807134504],
    ["1718", "1719", 25.612496949731394],
    ["1720", "1719", 29.68164415931166],
    ["1835", "1719", 36.49657518178932],
    ["1835", "1720", 24.596747752497688],
    ["1719", "1720", 29.68164415931166],
    ["1721", "1720", 31.04834939252005],
    ["1722", "1721", 29.0],
    ["1720", "1721", 31.04834939252005],
    ["1835", "1721", 35.510561809129406],
    ["1836", "1722", 15.811388300841896],
    ["1721", "1722", 29.0],
    ["1868", "1722", 31.064449134018133],
    ["1725", "1724", 16.0312195418814],
    ["1872", "1724", 25.179356624028344],
    ["1903", "1724", 37.33630940518894],
    ["1724", "1725", 16.0312195418814],
    ["1726", "1725", 29.614185789921695],
    ["1746", "1725", 36.6742416417845],
    ["1727", "1726", 20.8806130178211],
    ["1725", "1726", 29.614185789921695],
    ["1747", "1726", 37.94733192202055],
    ["1726", "1727", 20.8806130178211],
    ["1728", "1727", 22.847319317591726],
    ["1750", "1727", 38.48376280978771],
    ["1727", "1728", 22.847319317591726],
    ["1729", "1728", 34.438350715445125],
    ["1752", "1728", 40.80441152620633],
    ["1730", "1729", 22.80350850198276],
    ["1728", "1729", 34.438350715445125],
    ["1731", "1729", 39.824615503479755],
    ["1731", "1730", 17.029386365926403],
    ["1729", "1730", 22.80350850198276],
    ["1732", "1730", 41.72529209005013],
    ["1730", "1731", 17.029386365926403],
    ["1732", "1731", 24.839484696748443],
    ["1733", "1732", 13.601470508735444],
    ["1731", "1732", 24.839484696748443],
    ["1732", "1733", 13.601470508735444],
    ["1734", "1733", 19.4164878389476],
    ["1735", "1734", 19.235384061671343],
    ["1733", "1734", 19.4164878389476],
    ["1732", "1734", 33.015148038438355],
    ["1736", "1735", 18.35755975068582],
    ["1735", "1736", 18.35755975068582],
    ["1737", "1736", 26.92582403567252],
    ["1767", "1737", 20.615528128088304],
    ["1817", "1738", 21.470910553583888],
    ["1861", "1738", 25.80697580112788],
    ["1739", "1738", 27.85677655436824],
    ["1740", "1739", 22.80350850198276],
    ["1738", "1739", 27.85677655436824],
    ["1860", "1739", 30.01666203960727],
    ["1901", "1740", 20.615528128088304],
    ["1739", "1740", 22.80350850198276],
    ["1859", "1740", 31.76476034853718],
    ["1877", "1743", 24.041630560342615],
    ["1745", "1743", 28.442925306655784],
    ["1757", "1743", 29.017236257093817],
    ["1877", "1745", 16.278820596099706],
    ["1743", "1745", 28.442925306655784],
    ["1878", "1745", 36.138621999185304],
    ["1903", "1746", 22.090722034374522],
    ["1747", "1746", 24.73863375370596],
    ["1725", "1746", 36.6742416417845],
    ["1750", "1747", 16.76305461424021],
    ["1746", "1747", 24.73863375370596],
    ["1726", "1747", 37.94733192202055],
    ["1747", "1750", 16.76305461424021],
    ["1752", "1750", 28.178005607210743],
    ["1727", "1750", 38.48376280978771],
    ["1753", "1752", 23.853720883753127],
    ["1750", "1752", 28.178005607210743],
    ["1728", "1752", 40.80441152620633],
    ["1754", "1753", 18.027756377319946],
    ["1752", "1753", 23.853720883753127],
    ["1755", "1753", 31.400636936215164],
    ["1755", "1754", 13.45362404707371],
    ["1753", "1754", 18.027756377319946],
    ["1752", "1754", 41.88078318274385],
    ["1754", "1755", 13.45362404707371],
    ["1756", "1755", 29.0],
    ["1753", "1755", 31.400636936215164],
    ["1759", "1756", 17.69180601295413],
    ["1755", "1756", 29.0],
    ["1733", "1756", 38.27531841800928],
    ["1908", "1757", 11.0],
    ["1909", "1757", 27.0],
    ["1743", "1757", 29.017236257093817],
    ["1756", "1759", 17.69180601295413],
    ["1760", "1759", 23.345235059857504],
    ["1734", "1759", 35.84689665786984],
    ["1759", "1760", 23.345235059857504],
    ["1735", "1760", 30.0],
    ["1878", "1764", 20.248456731316587],
    ["1877", "1764", 42.95346318982906],
    ["1745", "1764", 54.037024344425184],
    ["1888", "1766", 20.0],
    ["1843", "1766", 22.0],
    ["1842", "1766", 34.48187929913333],
    ["1768", "1767", 18.027756377319946],
    ["1737", "1767", 20.615528128088304],
    ["1769", "1768", 15.231546211727817],
    ["1767", "1768", 18.027756377319946],
    ["1770", "1768", 34.438350715445125],
    ["1768", "1769", 15.231546211727817],
    ["1770", "1769", 19.235384061671343],
    ["1767", "1769", 32.7566787083184],
    ["1769", "1770", 19.235384061671343],
    ["1771", "1770", 20.12461179749811],
    ["1768", "1770", 34.438350715445125],
    ["1769", "1771", 37.48332962798263],
    ["1871", "1772", 17.08800749063506],
    ["1771", "1772", 28.160255680657446],
    ["1774", "1772", 32.64965543462902],
    ["1871", "1774", 15.811388300841896],
    ["1775", "1774", 18.788294228055936],
    ["1772", "1774", 32.64965543462902],
    ["1774", "1775", 18.788294228055936],
    ["1840", "1775", 20.808652046684813],
    ["1858", "1775", 30.01666203960727],
    ["1847", "1781", 28.0178514522438],
    ["1848", "1781", 29.832867780352597],
    ["1782", "1781", 33.95585369269929],
    ["1891", "1782", 22.47220505424423],
    ["1890", "1782", 29.0],
    ["1781", "1782", 33.95585369269929],
    ["1786", "1785", 26.40075756488817],
    ["1787", "1785", 54.230987451824994],
    ["1781", "1785", 61.1310068623117],
    ["1785", "1786", 26.40075756488817],
    ["1787", "1786", 29.427877939124322],
    ["1788", "1786", 56.71860364994893],
    ["1788", "1787", 27.294688127912362],
    ["1786", "1787", 29.427877939124322],
    ["1785", "1787", 54.230987451824994],
    ["1787", "1788", 27.294688127912362],
    ["1789", "1788", 32.2490309931942],
    ["1786", "1788", 56.71860364994893],
    ["1790", "1789", 25.942243542145693],
    ["1788", "1789", 32.2490309931942],
    ["1791", "1789", 47.16990566028302],
    ["1791", "1790", 21.400934559032695],
    ["1789", "1790", 25.942243542145693],
    ["1904", "1790", 35.510561809129406],
    ["1904", "1791", 17.11724276862369],
    ["1790", "1791", 21.400934559032695],
    ["1874", "1791", 36.05551275463989],
    ["1904", "1793", 25.0],
    ["1853", "1793", 33.52610922848042],
    ["1852", "1793", 34.058772731852805],
    ["1795", "1794", 16.64331697709324],
    ["1854", "1794", 18.384776310850235],
    ["1796", "1794", 29.274562336608895],
    ["1794", "1795", 16.64331697709324],
    ["1796", "1795", 26.90724809414742],
    ["1854", "1795", 33.421549934136806],
    ["1795", "1796", 26.90724809414742],
    ["1794", "1796", 29.274562336608895],
    ["1797", "1796", 31.400636936215164],
    ["1806", "1797", 27.80287754891569],
    ["1796", "1797", 31.400636936215164],
    ["1798", "1797", 32.64965543462902],
    ["1799", "1798", 21.470910553583888],
    ["1807", "1798", 27.586228448267445],
    ["1797", "1798", 32.64965543462902],
    ["1800", "1799", 18.0],
    ["1698", "1799", 20.8806130178211],
    ["1798", "1799", 21.470910553583888],
    ["1799", "1800", 18.0],
    ["1801", "1800", 20.0],
    ["1698", "1800", 23.323807579381203],
    ["1800", "1801", 20.0],
    ["1802", "1801", 23.0],
    ["1698", "1801", 37.73592452822641],
    ["1803", "1802", 17.029386365926403],
    ["1801", "1802", 23.0],
    ["1800", "1802", 43.0],
    ["1802", "1803", 17.029386365926403],
    ["1887", "1803", 27.018512172212592],
    ["1801", "1803", 40.01249804748511],
    ["1886", "1804", 25.612496949731394],
    ["1805", "1804", 31.25699921617557],
    ["1887", "1804", 40.19950248448356],
    ["1886", "1805", 28.30194339616981],
    ["1804", "1805", 31.25699921617557],
    ["1887", "1805", 56.22277118748239],
    ["1797", "1806", 27.80287754891569],
    ["1807", "1806", 28.635642126552707],
    ["1798", "1806", 32.7566787083184],
    ["1798", "1807", 27.586228448267445],
    ["1806", "1807", 28.635642126552707],
    ["1898", "1807", 29.410882339705484],
    ["1810", "1809", 0.0],
    ["1811", "1809", 30.01666203960727],
    ["1898", "1809", 30.59411708155671],
    ["1810", "1810", 0.0],
    ["1811", "1810", 30.01666203960727],
    ["1898", "1810", 30.59411708155671],
    ["1699", "1811", 25.179356624028344],
    ["1809", "1811", 30.01666203960727],
    ["1810", "1811", 30.01666203960727],
    ["1813", "1812", 26.30589287593181],
    ["1894", "1812", 36.22154055254967],
    ["1870", "1813", 24.041630560342615],
    ["1812", "1813", 26.30589287593181],
    ["1809", "1813", 31.25699921617557],
    ["1702", "1816", 21.213203435596427],
    ["1701", "1816", 26.92582403567252],
    ["1680", "1816", 38.05259518088089],
    ["1818", "1817", 19.72308292331602],
    ["1738", "1817", 21.470910553583888],
    ["1862", "1817", 29.154759474226502],
    ["1817", "1818", 19.72308292331602],
    ["1819", "1818", 26.90724809414742],
    ["1862", "1818", 35.05709628591621],
    ["1820", "1819", 25.612496949731394],
    ["1818", "1819", 26.90724809414742],
    ["1837", "1819", 44.721359549995796],
    ["1837", "1820", 20.396078054371138],
    ["1819", "1820", 25.612496949731394],
    ["1821", "1820", 37.013511046643494],
    ["1837", "1821", 25.179356624028344],
    ["1822", "1821", 33.015148038438355],
    ["1820", "1821", 37.013511046643494],
    ["1823", "1822", 21.540659228538015],
    ["1836", "1822", 31.016124838541646],
    ["1821", "1822", 33.015148038438355],
    ["1822", "1823", 21.540659228538015],
    ["1824", "1823", 25.298221281347036],
    ["1868", "1823", 37.107950630558946],
    ["1825", "1824", 23.769728648009426],
    ["1823", "1824", 25.298221281347036],
    ["1869", "1824", 39.05124837953327],
    ["1826", "1825", 22.02271554554524],
    ["1824", "1825", 23.769728648009426],
    ["1838", "1825", 24.596747752497688],
    ["1825", "1826", 22.02271554554524],
    ["1838", "1826", 23.706539182259394],
    ["1709", "1826", 42.2965719651132],
    ["1882", "1827", 22.02271554554524],
    ["1709", "1827", 22.47220505424423],
    ["1900", "1827", 28.42534080710379],
    ["1884", "1829", 24.020824298928627],
    ["1882", "1829", 25.0],
    ["1827", "1829", 32.55764119219941],
    ["1873", "1833", 20.0],
    ["831", "1833", 20.248456731316587],
    ["1897", "1833", 41.048751503547585],
    ["1882", "1834", 28.0178514522438],
    ["1827", "1834", 35.805027579936315],
    ["1883", "1834", 36.49657518178932],
    ["1720", "1835", 24.596747752497688],
    ["1721", "1835", 35.510561809129406],
    ["1719", "1835", 36.49657518178932],
    ["1722", "1836", 15.811388300841896],
    ["1868", "1836", 26.627053911388696],
    ["1822", "1836", 31.016124838541646],
    ["1820", "1837", 20.396078054371138],
    ["1821", "1837", 25.179356624028344],
    ["1835", "1837", 37.57658845611187],
    ["1883", "1838", 20.396078054371138],
    ["1826", "1838", 23.706539182259394],
    ["1825", "1838", 24.596747752497688],
    ["1689", "1839", 21.095023109728988],
    ["1700", "1839", 25.612496949731394],
    ["1896", "1839", 45.221676218380054],
    ["1775", "1840", 20.808652046684813],
    ["1841", "1840", 24.515301344262525],
    ["1858", "1840", 28.460498941515414],
    ["1840", "1841", 24.515301344262525],
    ["1842", "1841", 26.68332812825267],
    ["1858", "1841", 35.34119409414458],
    ["1888", "1842", 18.027756377319946],
    ["1841", "1842", 26.68332812825267],
    ["1843", "1842", 30.4138126514911],
    ["1888", "1843", 13.416407864998739],
    ["1766", "1843", 22.0],
    ["1842", "1843", 30.4138126514911],
    ["1845", "1844", 29.068883707497267],
    ["1843", "1844", 31.04834939252005],
    ["1889", "1844", 37.0],
    ["1889", "1845", 17.029386365926403],
    ["1846", "1845", 25.612496949731394],
    ["1844", "1845", 29.068883707497267],
    ["1845", "1846", 25.612496949731394],
    ["1889", "1846", 30.364452901377952],
    ["1847", "1846", 31.11269837220809],
    ["1781", "1847", 28.0178514522438],
    ["1846", "1847", 31.11269837220809],
    ["1848", "1847", 35.0],
    ["1781", "1848", 29.832867780352597],
    ["1849", "1848", 30.083217912982647],
    ["1847", "1848", 35.0],
    ["1892", "1849", 27.51363298439521],
    ["1848", "1849", 30.083217912982647],
    ["1851", "1849", 50.08991914547278],
    ["1874", "1851", 18.973665961010276],
    ["1852", "1851", 22.80350850198276],
    ["1892", "1851", 24.73863375370596],
    ["1853", "1852", 18.867962264113206],
    ["1851", "1852", 22.80350850198276],
    ["1874", "1852", 28.844410203711913],
    ["1852", "1853", 18.867962264113206],
    ["1854", "1853", 20.615528128088304],
    ["1793", "1853", 33.52610922848042],
    ["1794", "1854", 18.384776310850235],
    ["1853", "1854", 20.615528128088304],
    ["1795", "1854", 33.421549934136806],
    ["1858", "1856", 37.0],
    ["1842", "1856", 38.2099463490856],
    ["1841", "1856", 38.8329756778952],
    ["1769", "1857", 39.66106403010388],
    ["1768", "1857", 39.81205847478876],
    ["1767", "1857", 41.23105625617661],
    ["1840", "1858", 28.460498941515414],
    ["1775", "1858", 30.01666203960727],
    ["1774", "1858", 34.058772731852805],
    ["1860", "1859", 23.40939982143925],
    ["1712", "1859", 26.92582403567252],
    ["1740", "1859", 31.76476034853718],
    ["1859", "1860", 23.40939982143925],
    ["1739", "1860", 30.01666203960727],
    ["1861", "1860", 30.01666203960727],
    ["1862", "1861", 19.924858845171276],
    ["1738", "1861", 25.80697580112788],
    ["1860", "1861", 30.01666203960727],
    ["1861", "1862", 19.924858845171276],
    ["1817", "1862", 29.154759474226502],
    ["1716", "1862", 29.832867780352597],
    ["1885", "1863", 22.02271554554524],
    ["1884", "1863", 22.090722034374522],
    ["1708", "1863", 24.413111231467404],
    ["1885", "1864", 20.09975124224178],
    ["1706", "1864", 25.238858928247925],
    ["1865", "1864", 38.3275357934736],
    ["1680", "1865", 24.73863375370596],
    ["1706", "1865", 28.844410203711913],
    ["1864", "1865", 38.3275357934736],
    ["1682", "1866", 28.635642126552707],
    ["1896", "1866", 43.04648650006177],
    ["1700", "1866", 43.93176527297759],
    ["1836", "1868", 26.627053911388696],
    ["1722", "1868", 31.064449134018133],
    ["1869", "1868", 32.89376840679705],
    ["1868", "1869", 32.89376840679705],
    ["1883", "1869", 33.24154027718932],
    ["1838", "1869", 35.90264614203248],
    ["1698", "1870", 19.235384061671343],
    ["1813", "1870", 24.041630560342615],
    ["1809", "1870", 30.610455730027933],
    ["1774", "1871", 15.811388300841896],
    ["1772", "1871", 17.08800749063506],
    ["1775", "1871", 34.48187929913333],
    ["1724", "1872", 25.179356624028344],
    ["1219", "1872", 26.076809620810597],
    ["1903", "1872", 34.17601498127012],
    ["1833", "1873", 20.0],
    ["1897", "1873", 21.095023109728988],
    ["831", "1873", 38.600518131237564],
    ["1851", "1874", 18.973665961010276],
    ["1904", "1874", 25.553864678361276],
    ["1852", "1874", 28.844410203711913],
    ["1745", "1877", 16.278820596099706],
    ["1878", "1877", 23.08679276123039],
    ["1743", "1877", 24.041630560342615],
    ["1764", "1878", 20.248456731316587],
    ["1877", "1878", 23.08679276123039],
    ["1745", "1878", 36.138621999185304],
    ["1194", "1879", 25.317977802344327],
    ["1757", "1879", 36.124783736376884],
    ["1908", "1879", 47.095647357266465],
    ["1827", "1882", 22.02271554554524],
    ["1829", "1882", 25.0],
    ["1834", "1882", 28.0178514522438],
    ["1838", "1883", 20.396078054371138],
    ["1897", "1883", 22.02271554554524],
    ["1869", "1883", 33.24154027718932],
    ["1708", "1884", 19.697715603592208],
    ["1863", "1884", 22.090722034374522],
    ["1829", "1884", 24.020824298928627],
    ["1864", "1885", 20.09975124224178],
    ["1863", "1885", 22.02271554554524],
    ["1706", "1885", 41.048751503547585],
    ["1804", "1886", 25.612496949731394],
    ["1887", "1886", 28.284271247461902],
    ["1805", "1886", 28.30194339616981],
    ["1803", "1887", 27.018512172212592],
    ["1886", "1887", 28.284271247461902],
    ["1804", "1887", 40.19950248448356],
    ["1843", "1888", 13.416407864998739],
    ["1842", "1888", 18.027756377319946],
    ["1766", "1888", 20.0],
    ["1845", "1889", 17.029386365926403],
    ["1846", "1889", 30.364452901377952],
    ["1890", "1889", 31.25699921617557],
    ["1782", "1890", 29.0],
    ["1889", "1890", 31.25699921617557],
    ["1891", "1890", 32.0624390837628],
    ["1782", "1891", 22.47220505424423],
    ["1890", "1891", 32.0624390837628],
    ["1781", "1891", 52.15361924162119],
    ["1851", "1892", 24.73863375370596],
    ["1849", "1892", 27.51363298439521],
    ["1874", "1892", 32.31098884280702],
    ["1894", "1893", 17.11724276862369],
    ["1686", "1893", 22.360679774997898],
    ["1692", "1893", 36.49657518178932],
    ["1893", "1894", 17.11724276862369],
    ["1699", "1894", 34.785054261852174],
    ["1812", "1894", 36.22154055254967],
    ["1694", "1895", 23.259406699226016],
    ["1896", "1895", 28.319604517012593],
    ["1693", "1895", 44.77722635447622],
    ["1895", "1896", 28.319604517012593],
    ["1866", "1896", 43.04648650006177],
    ["1839", "1896", 45.221676218380054],
    ["1873", "1897", 21.095023109728988],
    ["1883", "1897", 22.02271554554524],
    ["1834", "1897", 40.311288741492746],
    ["1698", "1898", 28.160255680657446],
    ["1807", "1898", 29.410882339705484],
    ["1809", "1898", 30.59411708155671],
    ["1827", "1900", 28.42534080710379],
    ["1709", "1900", 30.14962686336267],
    ["1707", "1900", 40.0],
    ["1740", "1901", 20.615528128088304],
    ["1902", "1901", 24.020824298928627],
    ["1712", "1901", 31.32091952673165],
    ["1903", "1902", 18.110770276274835],
    ["1901", "1902", 24.020824298928627],
    ["1872", "1902", 34.52535300326414],
    ["1902", "1903", 18.110770276274835],
    ["1746", "1903", 22.090722034374522],
    ["1872", "1903", 34.17601498127012],
    ["1791", "1904", 17.11724276862369],
    ["1793", "1904", 25.0],
    ["1874", "1904", 25.553864678361276],
    ["1757", "1908", 11.0],
    ["1909", "1908", 16.0],
    ["1743", "1908", 30.675723300355934],
    ["1908", "1909", 16.0],
    ["1910", "1909", 26.0],
    ["1757", "1909", 27.0],
    ["1909", "1910", 26.0],
    ["1911", "1910", 26.0],
    ["1908", "1910", 42.0],
    ["1910", "1911", 26.0],
    ["1219", "1911", 27.018512172212592],
    ["1872", "1911", 47.01063709417264],
    ["1766", "1918", 39.45883931389772],
    ["1856", "1918", 43.86342439892262],
    ["1938", "1937", 18.973665961010276],
    ["1939", "1937", 37.33630940518894],
    ["1939", "1938", 18.384776310850235],
    ["1937", "1938", 18.973665961010276],
    ["1940", "1938", 42.5440947723653],
    ["1938", "1939", 18.384776310850235],
    ["1940", "1939", 24.166091947189145],
    ["1937", "1939", 37.33630940518894],
    ["1941", "1940", 22.47220505424423],
    ["1939", "1940", 24.166091947189145],
    ["247", "1940", 34.88552708502482],
    ["1943", "1941", 20.248456731316587],
    ["1940", "1941", 22.47220505424423],
    ["247", "1941", 26.0],
    ["1944", "1943", 18.867962264113206],
    ["1941", "1943", 20.248456731316587],
    ["247", "1943", 35.6931365951495],
    ["1943", "1944", 18.867962264113206],
    ["1945", "1944", 19.235384061671343],
    ["1941", "1944", 39.11521443121589],
    ["1944", "1945", 19.235384061671343],
    ["1946", "1945", 20.0],
    ["1943", "1945", 38.07886552931954],
    ["1945", "1946", 20.0],
    ["1947", "1946", 22.67156809750927],
    ["1944", "1946", 39.11521443121589],
    ["1948", "1947", 18.027756377319946],
    ["1946", "1947", 22.67156809750927],
    ["121", "1947", 28.160255680657446],
    ["1947", "1948", 18.027756377319946],
    ["1949", "1948", 18.439088914585774],
    ["121", "1948", 21.633307652783937],
    ["1948", "1949", 18.439088914585774],
    ["1950", "1949", 20.808652046684813],
    ["120", "1949", 21.2602916254693],
    ["1949", "1950", 20.808652046684813],
    ["1951", "1950", 21.633307652783937],
    ["119", "1950", 26.076809620810597],
    ["1950", "1951", 21.633307652783937],
    ["1952", "1951", 23.706539182259394],
    ["118", "1951", 27.313000567495326],
    ["1953", "1952", 21.540659228538015],
    ["1951", "1952", 23.706539182259394],
    ["117", "1952", 29.154759474226502],
    ["1954", "1953", 17.46424919657298],
    ["1952", "1953", 21.540659228538015],
    ["116", "1953", 28.635642126552707],
    ["1953", "1954", 17.46424919657298],
    ["1955", "1954", 22.360679774997898],
    ["115", "1954", 25.632011235952593],
    ["1954", "1955", 22.360679774997898],
    ["1956", "1955", 26.30589287593181],
    ["113", "1955", 29.154759474226502],
    ["1957", "1956", 25.019992006393608],
    ["1955", "1956", 26.30589287593181],
    ["112", "1956", 28.635642126552707],
    ["1956", "1957", 25.019992006393608],
    ["1958", "1957", 25.019992006393608],
    ["106", "1957", 29.154759474226502],
    ["1959", "1958", 18.027756377319946],
    ["1957", "1958", 25.019992006393608],
    ["105", "1958", 29.427877939124322],
    ["1960", "1959", 15.524174696260024],
    ["1958", "1959", 18.027756377319946],
    ["104", "1959", 34.0147027033899],
    ["1959", "1960", 15.524174696260024],
    ["1961", "1960", 19.313207915827967],
    ["1958", "1960", 33.52610922848042],
    ["1962", "1961", 15.652475842498529],
    ["1960", "1961", 19.313207915827967],
    ["1963", "1961", 27.784887978899608],
    ["1963", "1962", 12.206555615733702],
    ["1961", "1962", 15.652475842498529],
    ["1960", "1962", 34.92849839314596],
    ["1962", "1963", 12.206555615733702],
    ["1961", "1963", 27.784887978899608],
    ["1965", "1963", 29.410882339705484],
    ["1965", "1964", 19.79898987322333],
    ["1966", "1964", 24.758836806279895],
    ["96", "1964", 41.23105625617661],
    ["1964", "1965", 19.79898987322333],
    ["1963", "1965", 29.410882339705484],
    ["1962", "1965", 41.617304093369626],
    ["1967", "1966", 18.788294228055936],
    ["1964", "1966", 24.758836806279895],
    ["94", "1966", 36.124783736376884],
    ["1966", "1967", 18.788294228055936],
    ["1968", "1967", 21.587033144922902],
    ["94", "1967", 34.40930106817051],
    ["1969", "1968", 19.4164878389476],
    ["1967", "1968", 21.587033144922902],
    ["92", "1968", 38.41874542459709],
    ["1968", "1969", 19.4164878389476],
    ["1970", "1969", 23.08679276123039],
    ["90", "1969", 38.63935817272331],
    ["1969", "1970", 23.08679276123039],
    ["1971", "1970", 24.08318915758459],
    ["216", "1970", 30.265491900843113],
    ["1970", "1971", 24.08318915758459],
    ["1972", "1971", 29.0],
    ["216", "1971", 34.40930106817051],
    ["1973", "1972", 24.08318915758459],
    ["1971", "1972", 29.0],
    ["215", "1972", 34.0147027033899],
    ["1974", "1973", 17.0],
    ["1972", "1973", 24.08318915758459],
    ["215", "1973", 34.48187929913333],
    ["1973", "1974", 17.0],
    ["1975", "1974", 18.0],
    ["204", "206", 49.47726750741192],
    ["203", "206", 69.05070600652827],
    ["1974", "1975", 18.0],
    ["191", "75509", 18.601075237738275],
    ["199", "201", 35.014282800023196],
    ["217", "1976", 25.019992006393608],
    ["1977", "1976", 27.0],
    ["1976", "1977", 27.0],
    ["1978", "1977", 27.16615541441225],
    ["217", "1977", 37.53664875824692],
    ["1979", "1978", 26.68332812825267],
    ["1977", "1978", 27.16615541441225],
    ["79", "1978", 40.496913462633174],
    ["1980", "1979", 23.769728648009426],
    ["1978", "1979", 26.68332812825267],
    ["78", "1979", 39.56008088970496],
    ["1981", "1980", 22.47220505424423],
    ["1979", "1980", 23.769728648009426],
    ["176", "1980", 38.118237105091836],
    ["176", "1981", 17.72004514666935],
    ["1980", "1981", 22.47220505424423],
    ["1982", "1981", 25.238858928247925],
    ["176", "1982", 19.4164878389476],
    ["1983", "1982", 19.697715603592208],
    ["1981", "1982", 25.238858928247925],
    ["1982", "1983", 19.697715603592208],
    ["1984", "1983", 20.0],
    ["177", "1983", 25.0],
    ["177", "1984", 15.0],
    ["1983", "1984", 20.0],
    ["1201", "1984", 24.08318915758459],
    ["2088", "1985", 16.15549442140351],
    ["1986", "1985", 19.0],
    ["2087", "1985", 38.3275357934736],
    ["1985", "1986", 19.0],
    ["1987", "1986", 20.0],
    ["2088", "1986", 29.154759474226502],
    ["1986", "1987", 20.0],
    ["1988", "1987", 36.49657518178932],
    ["1985", "1987", 39.0],
    ["1989", "1988", 21.0],
    ["1987", "1988", 36.49657518178932],
    ["1990", "1988", 43.0],
    ["1988", "1989", 21.0],
    ["1990", "1989", 22.0],
    ["1991", "1989", 45.09988913511872],
    ["1989", "1990", 22.0],
    ["1991", "1990", 23.194827009486403],
    ["1988", "1990", 43.0],
    ["1992", "1991", 23.08679276123039],
    ["1990", "1991", 23.194827009486403],
    ["1989", "1991", 45.09988913511872],
    ["1991", "1992", 23.08679276123039],
    ["1993", "1992", 24.186773244895647],
    ["1990", "1992", 46.2709412050371],
    ["1992", "1993", 24.186773244895647],
    ["1994", "1993", 26.68332812825267],
    ["2115", "1993", 45.70557952810576],
    ["1995", "1994", 25.709920264364882],
    ["1993", "1994", 26.68332812825267],
    ["2115", "1994", 42.95346318982906],
    ["1994", "1995", 25.709920264364882],
    ["1996", "1995", 34.17601498127012],
    ["2116", "1995", 44.10215414239989],
    ["1997", "1996", 16.55294535724685],
    ["1995", "1996", 34.17601498127012],
    ["1998", "1996", 37.589892258425],
    ["1996", "1997", 16.55294535724685],
    ["1998", "1997", 21.095023109728988],
    ["2118", "1997", 43.829214001622255],
    ["1997", "1998", 21.095023109728988],
    ["1999", "1998", 27.65863337187866],
    ["1996", "1998", 37.589892258425],
    ["2000", "1999", 20.615528128088304],
    ["1998", "1999", 27.65863337187866],
    ["2001", "1999", 44.41846462902562],
    ["1999", "2000", 20.615528128088304],
    ["2001", "2000", 24.166091947189145],
    ["1998", "2000", 48.08326112068523],
    ["2000", "2001", 24.166091947189145],
    ["2002", "2001", 24.351591323771842],
    ["1999", "2001", 44.41846462902562],
    ["2003", "2002", 22.80350850198276],
    ["2001", "2002", 24.351591323771842],
    ["2004", "2002", 43.174066289845804],
    ["2004", "2003", 20.396078054371138],
    ["2002", "2003", 22.80350850198276],
    ["2005", "2003", 42.2965719651132],
    ["2003", "2004", 20.396078054371138],
    ["2005", "2004", 22.02271554554524],
    ["2123", "2004", 39.6232255123179],
    ["2004", "2005", 22.02271554554524],
    ["2006", "2005", 27.018512172212592],
    ["2124", "2005", 38.47076812334269],
    ["2007", "2006", 16.1245154965971],
    ["2005", "2006", 27.018512172212592],
    ["2008", "2006", 31.144823004794873],
    ["2008", "2007", 15.033296378372908],
    ["2006", "2007", 16.1245154965971],
    ["2009", "2007", 28.442925306655784],
    ["2009", "2008", 13.601470508735444],
    ["2007", "2008", 15.033296378372908],
    ["2006", "2008", 31.144823004794873],
    ["2008", "2009", 13.601470508735444],
    ["2007", "2009", 28.442925306655784],
    ["2126", "2009", 29.410882339705484],
    ["2091", "2010", 18.027756377319946],
    ["2011", "2010", 18.681541692269406],
    ["2128", "2010", 26.476404589747453],
    ["2012", "2011", 18.35755975068582],
    ["2010", "2011", 18.681541692269406],
    ["2091", "2011", 25.059928172283335],
    ["2011", "2012", 18.35755975068582],
    ["2013", "2012", 22.360679774997898],
    ["2129", "2012", 28.844410203711913],
    ["2014", "2013", 17.804493814764857],
    ["2012", "2013", 22.360679774997898],
    ["2130", "2013", 33.13608305156178],
    ["2013", "2014", 17.804493814764857],
    ["2015", "2014", 26.90724809414742],
    ["2130", "2014", 27.80287754891569],
    ["2016", "2015", 22.20360331117452],
    ["2014", "2015", 26.90724809414742],
    ["2131", "2015", 29.5296461204668],
    ["2017", "2016", 18.027756377319946],
    ["2015", "2016", 22.20360331117452],
    ["2018", "2016", 38.27531841800928],
    ["2016", "2017", 18.027756377319946],
    ["2018", "2017", 20.248456731316587],
    ["2015", "2017", 39.59797974644666],
    ["2017", "2018", 20.248456731316587],
    ["2019", "2018", 23.600847442411894],
    ["2016", "2018", 38.27531841800928],
    ["2018", "2019", 23.600847442411894],
    ["2020", "2019", 29.206163733020468],
    ["2017", "2019", 43.829214001622255],
    ["2021", "2020", 26.570660511172846],
    ["2019", "2020", 29.206163733020468],
    ["2022", "2020", 47.80167361086848],
    ["2022", "2021", 21.37755832643195],
    ["2020", "2021", 26.570660511172846],
    ["2023", "2021", 49.25444142409901],
    ["2021", "2022", 21.37755832643195],
    ["2023", "2022", 28.0178514522438],
    ["2020", "2022", 47.80167361086848],
    ["2024", "2023", 20.615528128088304],
    ["2022", "2023", 28.0178514522438],
    ["2021", "2023", 49.25444142409901],
    ["2023", "2024", 20.615528128088304],
    ["2025", "2024", 30.463092423455635],
    ["2022", "2024", 48.373546489791295],
    ["2026", "2025", 25.0],
    ["2024", "2025", 30.463092423455635],
    ["2027", "2025", 44.94441010848846],
    ["2027", "2026", 20.12461179749811],
    ["2025", "2026", 25.0],
    ["2028", "2026", 45.17742799230607],
    ["2026", "2027", 20.12461179749811],
    ["2028", "2027", 25.059928172283335],
    ["2029", "2027", 43.139309220245984],
    ["2029", "2028", 20.12461179749811],
    ["2027", "2028", 25.059928172283335],
    ["834", "2028", 36.6742416417845],
    ["2030", "2029", 19.72308292331602],
    ["2028", "2029", 20.12461179749811],
    ["834", "2029", 29.154759474226502],
    ["2031", "2030", 19.1049731745428],
    ["2029", "2030", 19.72308292331602],
    ["834", "2030", 32.7566787083184],
    ["2030", "2031", 19.1049731745428],
    ["2032", "2031", 19.697715603592208],
    ["2029", "2031", 38.600518131237564],
    ["2031", "2032", 19.697715603592208],
    ["2033", "2032", 22.090722034374522],
    ["2030", "2032", 38.01315561749642],
    ["2034", "2033", 20.396078054371138],
    ["2032", "2033", 22.090722034374522],
    ["2035", "2033", 35.4682957019364],
    ["2035", "2034", 15.811388300841896],
    ["2033", "2034", 20.396078054371138],
    ["2036", "2034", 38.2099463490856],
    ["2034", "2035", 15.811388300841896],
    ["2036", "2035", 22.67156809750927],
    ["2033", "2035", 35.4682957019364],
    ["2035", "2036", 22.67156809750927],
    ["2037", "2036", 24.351591323771842],
    ["2034", "2036", 38.2099463490856],
    ["2036", "2037", 24.351591323771842],
    ["2038", "2037", 25.317977802344327],
    ["2039", "2037", 43.41658669218482],
    ["2155", "2038", 22.67156809750927],
    ["2037", "2038", 25.317977802344327],
    ["2039", "2038", 28.178005607210743],
    ["2040", "2039", 15.811388300841896],
    ["2038", "2039", 28.178005607210743],
    ["2039", "2040", 15.811388300841896],
    ["2041", "2040", 36.235341863986875],
    ["2042", "2041", 26.40075756488817],
    ["735", "2041", 30.4138126514911],
    ["2040", "2041", 36.235341863986875],
    ["735", "2042", 6.324555320336759],
    ["2043", "2042", 24.839484696748443],
    ["2041", "2042", 26.40075756488817],
    ["735", "2043", 22.20360331117452],
    ["2042", "2043", 24.839484696748443],
    ["2044", "2043", 32.55764119219941],
    ["8", "2044", 20.808652046684813],
    ["2045", "2044", 21.02379604162864],
    ["2043", "2044", 32.55764119219941],
    ["2044", "2045", 21.02379604162864],
    ["8", "2045", 26.92582403567252],
    ["2046", "2045", 29.410882339705484],
    ["2047", "2046", 20.248456731316587],
    ["2045", "2046", 29.410882339705484],
    ["2050", "2046", 48.08326112068523],
    ["2046", "2047", 20.248456731316587],
    ["2050", "2047", 27.892651361962706],
    ["2158", "2047", 48.05205510693585],
    ["2047", "2050", 27.892651361962706],
    ["2051", "2050", 28.442925306655784],
    ["2046", "2050", 48.08326112068523],
    ["2052", "2051", 26.0],
    ["2050", "2051", 28.442925306655784],
    ["2053", "2051", 44.01136216933077],
    ["2053", "2052", 18.027756377319946],
    ["2051", "2052", 26.0],
    ["2054", "2052", 26.076809620810597],
    ["2054", "2053", 15.524174696260024],
    ["2052", "2053", 18.027756377319946],
    ["2183", "2053", 20.248456731316587],
    ["2053", "2054", 15.524174696260024],
    ["2183", "2054", 17.0],
    ["2055", "2054", 20.0],
    ["2182", "2055", 16.492422502470642],
    ["2056", "2055", 17.029386365926403],
    ["2054", "2055", 20.0],
    ["2055", "2056", 17.029386365926403],
    ["2181", "2056", 17.11724276862369],
    ["2182", "2056", 27.018512172212592],
    ["2180", "2057", 20.591260281974],
    ["2058", "2057", 22.0],
    ["2179", "2057", 35.4400902933387],
    ["2057", "2058", 22.0],
    ["2059", "2058", 30.0],
    ["2180", "2058", 36.71511950137164],
    ["2060", "2059", 22.0],
    ["2058", "2059", 30.0],
    ["2057", "2059", 52.0],
    ["2059", "2060", 22.0],
    ["2061", "2060", 33.0],
    ["2062", "2060", 51.0098029794274],
    ["2062", "2061", 18.027756377319946],
    ["2060", "2061", 33.0],
    ["2059", "2061", 55.0],
    ["2061", "2062", 18.027756377319946],
    ["2060", "2062", 51.0098029794274],
    ["2059", "2062", 73.00684899377592],
    ["2183", "2063", 13.92838827718412],
    ["2053", "2063", 24.73863375370596],
    ["2064", "2063", 27.65863337187866],
    ["2065", "2064", 19.6468827043885],
    ["2063", "2064", 27.65863337187866],
    ["2183", "2064", 37.21558813185679],
    ["2064", "2065", 19.6468827043885],
    ["2066", "2065", 27.313000567495326],
    ["2162", "2065", 40.496913462633174],
    ["2067", "2066", 22.47220505424423],
    ["2065", "2066", 27.313000567495326],
    ["2068", "2066", 36.6742416417845],
    ["2068", "2067", 14.560219778561036],
    ["2066", "2067", 22.47220505424423],
    ["2163", "2067", 36.87817782917155],
    ["2067", "2068", 14.560219778561036],
    ["2069", "2068", 23.769728648009426],
    ["2066", "2068", 36.6742416417845],
    ["2070", "2069", 21.02379604162864],
    ["2068", "2069", 23.769728648009426],
    ["2071", "2069", 36.22154055254967],
    ["2071", "2070", 15.811388300841896],
    ["2069", "2070", 21.02379604162864],
    ["2072", "2070", 39.92492955535426],
    ["2070", "2071", 15.811388300841896],
    ["2072", "2071", 24.166091947189145],
    ["2069", "2071", 36.22154055254967],
    ["2071", "2072", 24.166091947189145],
    ["2073", "2072", 24.758836806279895],
    ["2070", "2072", 39.92492955535426],
    ["2072", "2073", 24.758836806279895],
    ["271", "2073", 30.4138126514911],
    ["2071", "2073", 48.010415536631214],
    ["2076", "2075", 19.0],
    ["271", "2075", 34.88552708502482],
    ["2077", "2075", 42.2965719651132],
    ["2075", "2076", 19.0],
    ["2077", "2076", 23.53720459187964],
    ["2078", "2076", 36.22154055254967],
    ["2078", "2077", 13.038404810405298],
    ["273", "2077", 18.867962264113206],
    ["2076", "2077", 23.53720459187964],
    ["2077", "2078", 13.038404810405298],
    ["273", "2078", 17.26267650163207],
    ["2079", "2078", 33.24154027718932],
    ["2080", "2079", 22.47220505424423],
    ["2078", "2079", 33.24154027718932],
    ["2081", "2079", 36.124783736376884],
    ["2081", "2080", 19.235384061671343],
    ["2079", "2080", 22.47220505424423],
    ["2084", "2080", 41.10960958218893],
    ["2080", "2081", 19.235384061671343],
    ["2084", "2081", 22.0],
    ["2079", "2081", 36.124783736376884],
    ["2081", "2084", 22.0],
    ["2085", "2084", 22.0],
    ["2089", "2084", 31.575306807693888],
    ["2089", "2085", 10.816653826391969],
    ["2084", "2085", 22.0],
    ["2090", "2085", 22.847319317591726],
    ["2090", "2086", 19.026297590440446],
    ["2087", "2086", 22.0],
    ["2089", "2086", 34.0147027033899],
    ["2086", "2087", 22.0],
    ["2088", "2087", 23.021728866442675],
    ["2090", "2087", 29.832867780352597],
    ["1985", "2088", 16.15549442140351],
    ["1986", "2088", 29.154759474226502],
    ["2085", "2089", 10.816653826391969],
    ["2090", "2089", 15.0],
    ["836", "2089", 15.524174696260024],
    ["2089", "2090", 15.0],
    ["2086", "2090", 19.026297590440446],
    ["2085", "2090", 22.847319317591726],
    ["2010", "2091", 18.027756377319946],
    ["2092", "2091", 22.0],
    ["2011", "2091", 25.059928172283335],
    ["2093", "2092", 21.02379604162864],
    ["2091", "2092", 22.0],
    ["2184", "2092", 25.0],
    ["2184", "2093", 19.924858845171276],
    ["2092", "2093", 21.02379604162864],
    ["2094", "2093", 22.02271554554524],
    ["2185", "2094", 20.223748416156685],
    ["2095", "2094", 21.0],
    ["2093", "2094", 22.02271554554524],
    ["2186", "2095", 20.396078054371138],
    ["2094", "2095", 21.0],
    ["2096", "2095", 22.0],
    ["2097", "2096", 16.0312195418814],
    ["2187", "2096", 21.470910553583888],
    ["2095", "2096", 22.0],
    ["2096", "2097", 16.0312195418814],
    ["2098", "2097", 26.019223662515376],
    ["2095", "2097", 28.0178514522438],
    ["2099", "2098", 26.0],
    ["2097", "2098", 26.019223662515376],
    ["2096", "2098", 42.0],
    ["2098", "2099", 26.0],
    ["2100", "2099", 26.019223662515376],
    ["2101", "2099", 49.0],
    ["2101", "2100", 23.021728866442675],
    ["2099", "2100", 26.019223662515376],
    ["2102", "2100", 46.04345773288535],
    ["2100", "2101", 23.021728866442675],
    ["2102", "2101", 23.021728866442675],
    ["2099", "2101", 49.0],
    ["2101", "2102", 23.021728866442675],
    ["2103", "2102", 28.0178514522438],
    ["2100", "2102", 46.04345773288535],
    ["2104", "2103", 22.20360331117452],
    ["2102", "2103", 28.0178514522438],
    ["2105", "2103", 29.732137494637012],
    ["2105", "2104", 17.0],
    ["2103", "2104", 22.20360331117452],
    ["2106", "2104", 37.013511046643494],
    ["2104", "2105", 17.0],
    ["2106", "2105", 20.024984394500787],
    ["2103", "2105", 29.732137494637012],
    ["2105", "2106", 20.024984394500787],
    ["2107", "2106", 23.021728866442675],
    ["2104", "2106", 37.013511046643494],
    ["2108", "2107", 14.0],
    ["2106", "2107", 23.021728866442675],
    ["2105", "2107", 43.04648650006177],
    ["2107", "2108", 14.0],
    ["2106", "2108", 37.013511046643494],
    ["2105", "2108", 57.0350769263968],
    ["2110", "2109", 26.0],
    ["2170", "2109", 26.30589287593181],
    ["1986", "2109", 48.16637831516918],
    ["2109", "2110", 26.0],
    ["2111", "2110", 27.16615541441225],
    ["1987", "2110", 48.041648597857254],
    ["2110", "2111", 27.16615541441225],
    ["2112", "2111", 34.0],
    ["1988", "2111", 51.478150704935004],
    ["2113", "2112", 24.0],
    ["2111", "2112", 34.0],
    ["2114", "2112", 49.01020301937138],
    ["2112", "2113", 24.0],
    ["2114", "2113", 25.019992006393608],
    ["1991", "2113", 50.28916384272063],
    ["2113", "2114", 25.019992006393608],
    ["2115", "2114", 45.09988913511872],
    ["1992", "2114", 46.84015371452148],
    ["2116", "2115", 19.4164878389476],
    ["1994", "2115", 42.95346318982906],
    ["2114", "2115", 45.09988913511872],
    ["2115", "2116", 19.4164878389476],
    ["2117", "2116", 28.861739379323623],
    ["1994", "2116", 43.01162633521314],
    ["2118", "2117", 21.095023109728988],
    ["2116", "2117", 28.861739379323623],
    ["1996", "2117", 42.5205832509386],
    ["2117", "2118", 21.095023109728988],
    ["2119", "2118", 33.24154027718932],
    ["1997", "2118", 43.829214001622255],
    ["2120", "2119", 25.0],
    ["2118", "2119", 33.24154027718932],
    ["2121", "2119", 43.56604182158393],
    ["2121", "2120", 18.788294228055936],
    ["2119", "2120", 25.0],
    ["2122", "2120", 41.14608122288197],
    ["2120", "2121", 18.788294228055936],
    ["2122", "2121", 22.360679774997898],
    ["2119", "2121", 43.56604182158393],
    ["2121", "2122", 22.360679774997898],
    ["2123", "2122", 24.698178070456937],
    ["2120", "2122", 41.14608122288197],
    ["2122", "2123", 24.698178070456937],
    ["2124", "2123", 33.54101966249684],
    ["2004", "2123", 39.6232255123179],
    ["2125", "2124", 21.02379604162864],
    ["2123", "2124", 33.54101966249684],
    ["2006", "2124", 36.138621999185304],
    ["2124", "2125", 21.02379604162864],
    ["2126", "2125", 23.021728866442675],
    ["2007", "2125", 32.2490309931942],
    ["2125", "2126", 23.021728866442675],
    ["2009", "2126", 29.410882339705484],
    ["2127", "2126", 30.59411708155671],
    ["2126", "2127", 30.59411708155671],
    ["2128", "2127", 32.28002478313795],
    ["2010", "2127", 39.81205847478876],
    ["2129", "2128", 25.942243542145693],
    ["2010", "2128", 26.476404589747453],
    ["2011", "2128", 31.144823004794873],
    ["2128", "2129", 25.942243542145693],
    ["2012", "2129", 28.844410203711913],
    ["2011", "2129", 33.0],
    ["2131", "2130", 15.0],
    ["2014", "2130", 27.80287754891569],
    ["2132", "2130", 30.610455730027933],
    ["2130", "2131", 15.0],
    ["2015", "2131", 29.5296461204668],
    ["2132", "2131", 30.463092423455635],
    ["2133", "2132", 21.95449840010015],
    ["2131", "2132", 30.463092423455635],
    ["2130", "2132", 30.610455730027933],
    ["2134", "2133", 19.235384061671343],
    ["2132", "2133", 21.95449840010015],
    ["2135", "2133", 38.01315561749642],
    ["2135", "2134", 19.1049731745428],
    ["2133", "2134", 19.235384061671343],
    ["2132", "2134", 41.182520563948],
    ["2134", "2135", 19.1049731745428],
    ["2136", "2135", 33.60059523282288],
    ["2133", "2135", 38.01315561749642],
    ["2137", "2136", 20.396078054371138],
    ["2135", "2136", 33.60059523282288],
    ["2138", "2136", 48.25971404805462],
    ["2136", "2137", 20.396078054371138],
    ["2138", "2137", 28.0178514522438],
    ["2135", "2137", 52.773099207835045],
    ["2137", "2138", 28.0178514522438],
    ["2139", "2138", 34.36568055487916],
    ["2136", "2138", 48.25971404805462],
    ["2140", "2139", 27.85677655436824],
    ["2138", "2139", 34.36568055487916],
    ["2141", "2139", 47.4236228055175],
    ["2141", "2140", 19.72308292331602],
    ["2139", "2140", 27.85677655436824],
    ["2142", "2140", 44.68780594300866],
    ["2140", "2141", 19.72308292331602],
    ["2142", "2141", 25.495097567963924],
    ["2139", "2141", 47.4236228055175],
    ["2141", "2142", 25.495097567963924],
    ["2143", "2142", 29.068883707497267],
    ["2140", "2142", 44.68780594300866],
    ["2144", "2143", 22.80350850198276],
    ["2142", "2143", 29.068883707497267],
    ["2141", "2143", 54.56189146281496],
    ["2143", "2144", 22.80350850198276],
    ["2145", "2144", 34.0147027033899],
    ["2142", "2144", 50.60632371551998],
    ["2144", "2145", 34.0147027033899],
    ["2146", "2145", 36.0],
    ["2143", "2145", 56.43580423808985],
    ["2147", "2146", 31.0],
    ["2145", "2146", 36.0],
    ["2148", "2146", 51.0098029794274],
    ["2148", "2147", 20.024984394500787],
    ["2146", "2147", 31.0],
    ["2147", "2148", 20.024984394500787],
    ["2150", "2149", 33.83784863137726],
    ["2151", "2150", 24.020824298928627],
    ["2149", "2150", 33.83784863137726],
    ["2150", "2151", 24.020824298928627],
    ["835", "2151", 45.69463863518345],
    ["832", "2155", 21.400934559032695],
    ["2038", "2155", 22.67156809750927],
    ["2157", "2156", 35.4682957019364],
    ["2041", "2156", 40.0],
    ["261", "2157", 30.0],
    ["2156", "2157", 35.4682957019364],
    ["2042", "2157", 46.09772228646444],
    ["260", "2158", 33.61547262794322],
    ["2159", "2158", 44.64302857109943],
    ["2047", "2158", 48.05205510693585],
    ["2160", "2159", 40.0],
    ["2158", "2159", 44.64302857109943],
    ["2051", "2159", 47.042533945356304],
    ["2161", "2160", 28.0178514522438],
    ["2159", "2160", 40.0],
    ["2053", "2160", 46.389654018972806],
    ["2160", "2161", 28.0178514522438],
    ["2162", "2161", 34.92849839314596],
    ["2063", "2161", 41.048751503547585],
    ["2161", "2162", 34.92849839314596],
    ["2163", "2162", 36.40054944640259],
    ["2065", "2162", 40.496913462633174],
    ["2164", "2163", 34.0147027033899],
    ["2162", "2163", 36.40054944640259],
    ["2067", "2163", 36.87817782917155],
    ["2163", "2164", 34.0147027033899],
    ["2165", "2164", 34.40930106817051],
    ["2068", "2164", 38.63935817272331],
    ["2166", "2165", 24.839484696748443],
    ["1070", "2165", 31.89043743820395],
    ["2164", "2165", 34.40930106817051],
    ["2165", "2166", 24.839484696748443],
    ["272", "2166", 25.632011235952593],
    ["1070", "2166", 40.792156108742276],
    ["273", "2168", 35.510561809129406],
    ["2169", "2168", 37.64306044943742],
    ["2170", "2169", 26.30589287593181],
    ["2168", "2169", 37.64306044943742],
    ["2080", "2169", 46.32493928760188],
    ["2109", "2170", 26.30589287593181],
    ["2169", "2170", 26.30589287593181],
    ["2110", "2170", 52.15361924162119],
    ["2172", "2171", 26.0],
    ["2173", "2171", 56.00892785976178],
    ["2174", "2171", 85.05292469985967],
    ["2171", "2172", 26.0],
    ["2173", "2172", 30.01666203960727],
    ["2174", "2172", 59.07622195096772],
    ["2174", "2173", 29.068883707497267],
    ["2172", "2173", 30.01666203960727],
    ["2171", "2173", 56.00892785976178],
    ["2175", "2174", 29.0],
    ["2173", "2174", 29.068883707497267],
    ["2176", "2174", 46.32493928760188],
    ["2176", "2175", 19.4164878389476],
    ["2174", "2175", 29.0],
    ["2177", "2175", 39.0],
    ["2175", "2176", 19.4164878389476],
    ["2177", "2176", 25.019992006393608],
    ["2174", "2176", 46.32493928760188],
    ["2178", "2177", 23.021728866442675],
    ["2176", "2177", 25.019992006393608],
    ["2175", "2177", 39.0],
    ["2177", "2178", 23.021728866442675],
    ["2179", "2178", 24.0],
    ["2180", "2178", 40.0],
    ["2180", "2179", 16.0],
    ["2178", "2179", 24.0],
    ["2057", "2179", 35.4400902933387],
    ["2179", "2180", 16.0],
    ["2057", "2180", 20.591260281974],
    ["2058", "2180", 36.71511950137164],
    ["2056", "2181", 17.11724276862369],
    ["2182", "2181", 23.0],
    ["2055", "2181", 24.839484696748443],
    ["2055", "2182", 16.492422502470642],
    ["2054", "2182", 22.627416997969522],
    ["2181", "2182", 23.0],
    ["2063", "2183", 13.92838827718412],
    ["2054", "2183", 17.0],
    ["2053", "2183", 20.248456731316587],
    ["2093", "2184", 19.924858845171276],
    ["2092", "2184", 25.0],
    ["2185", "2184", 25.0],
    ["2094", "2185", 20.223748416156685],
    ["2184", "2185", 25.0],
    ["2093", "2185", 26.870057685088806],
    ["2095", "2186", 20.396078054371138],
    ["2096", "2186", 26.90724809414742],
    ["2185", "2186", 28.0],
    ["2096", "2187", 21.470910553583888],
    ["2188", "2187", 25.0],
    ["2186", "2187", 28.0178514522438],
    ["2187", "2188", 25.0],
    ["2096", "2188", 39.824615503479755],
    ["2189", "2188", 41.012193308819754],
    ["2188", "2189", 41.012193308819754],
    ["2187", "2189", 66.007575322837],
    ["2096", "2189", 78.587530817554],
    ["235", "10785", 15.132745950421556],
    ["236", "10785", 38.07886552931954],
    ["234", "10785", 40.24922359499622],
    ["1026", "10786", 14.317821063276353],
    ["1027", "10786", 28.653097563788805],
    ["1028", "10786", 45.48626166217664],
    ["2071", "29", 21.095023109728988],
    ["2073", "29", 31.622776601683793],
    ["962", "873", 41.23105625617661],
    ["961", "873", 70.11419257183242],
    ["962", "874", 18.439088914585774],
    ["960", "874", 31.78049716414141],
    ["198", "201", 95.0],
    ["195", "202", 21.02379604162864],
    ["110", "876", 18.788294228055936],
    ["254", "876", 27.51363298439521],
    ["256", "877", 16.0312195418814],
    ["257", "877", 27.85677655436824],
    ["258", "878", 12.0],
    ["257", "878", 35.90264614203248],
    ["154", "879", 18.973665961010276],
    ["153", "879", 40.311288741492746],
    ["158", "880", 13.341664064126334],
    ["156", "880", 16.1245154965971],
    ["165", "881", 35.35533905932738],
    ["163", "881", 58.309518948453004],
    ["159", "882", 16.278820596099706],
    ["158", "882", 37.107950630558946],
    ["163", "883", 25.495097567963924],
    ["162", "883", 51.419840528729765],
    ["1053", "884", 18.35755975068582],
    ["1051", "884", 64.76109943476871],
    ["928", "1074", 13.601470508735444],
    ["929", "1074", 31.400636936215164],
    ["932", "1075", 13.0],
    ["934", "1075", 24.20743687382041],
    ["912", "1076", 16.76305461424021],
    ["913", "1076", 46.06517122512408],
    ["913", "1077", 13.601470508735444],
    ["915", "1077", 34.132096331752024],
    ["915", "1078", 11.704699910719626],
    ["917", "1078", 31.622776601683793],
    ["940", "1079", 10.198039027185569],
    ["920", "1079", 13.038404810405298],
    ["970", "971", 23.194827009486403],
    ["938", "1086", 17.08800749063506],
    ["939", "1086", 33.83784863137726],
    ["950", "1089", 39.6232255123179],
    ["948", "1089", 56.568542494923804],
    ["951", "1090", 35.11409973215888],
    ["947", "1090", 41.23105625617661],
    ["937", "964", 15.231546211727817],
    ["106657", "1096", 12.36931687685298],
    ["962", "1097", 17.11724276862369],
    ["960", "1097", 28.160255680657446],
    ["960", "1098", 21.633307652783937],
    ["962", "1098", 31.906112267087632],
    ["231", "1106", 14.317821063276353],
    ["223", "1107", 12.36931687685298],
    ["133", "1107", 25.709920264364882],
    ["240", "1110", 32.2490309931942],
    ["238", "1110", 33.24154027718932],
    ["240", "1111", 31.144823004794873],
    ["239", "1111", 55.71355310873648],
    ["1941", "1114", 18.027756377319946],
    ["1943", "1114", 21.095023109728988],
    ["1944", "1115", 15.524174696260024],
    ["1946", "1115", 30.805843601498726],
    ["46", "1118", 11.045361017187261],
    ["45", "1118", 26.92582403567252],
    ["137", "1120", 11.661903789690601],
    ["138", "1120", 28.42534080710379],
    ["42", "1121", 8.94427190999916],
    ["27", "1121", 21.213203435596427],
    ["1716", "1122", 12.041594578792296],
    ["143", "1123", 11.704699910719626],
    ["24", "1124", 9.899494936611665],
    ["26", "1124", 23.08679276123039],
    ["51", "1126", 14.317821063276353],
    ["49", "1126", 23.08679276123039],
    ["18", "1127", 12.36931687685298],
    ["17", "1127", 29.0],
    ["52", "1128", 12.0],
    ["54", "1128", 26.0],
    ["55", "1129", 10.198039027185569],
    ["54", "1129", 20.8806130178211],
    ["15", "1130", 10.0],
    ["13", "1130", 22.80350850198276],
    ["11", "1131", 10.04987562112089],
    ["10", "1131", 21.93171219946131],
    ["62", "1132", 9.848857801796104],
    ["178", "1132", 19.235384061671343],
    ["1201", "1133", 14.422205101855956],
    ["177", "1133", 20.223748416156685],
    ["1982", "1134", 13.038404810405298],
    ["176", "1134", 13.892443989449804],
    ["1978", "1135", 14.317821063276353],
    ["1980", "1135", 36.05551275463989],
    ["1976", "1136", 14.035668847618199],
    ["217", "1136", 28.30194339616981],
    ["1976", "1137", 24.166091947189145],
    ["167", "1137", 26.0],
    ["149", "1138", 15.556349186104045],
    ["840", "2152", 100],
    ["210", "75512", 28.160255680657446],
    ["185", "214", 34.52535300326414],
    ["205", "200", 20.024984394500787],
    ["190", "1149", 13.038404810405298],
    ["75513", "207", 15],
    ["75509", "214", 29],
    ["75517", "75516", 19],
    ["181", "75513", 19.1049731745428],
    ["75516", "75506", 17.0],
    ["75518", "75517", 10.198039027185569],
    ["213", "75499", 19.6468827043885],
    ["213", "212", 40.01249804748511],
    ["75497", "75500", 22],
    ["202", "212", 45.34313619501854],
    ["75500", "75501", 7.0710678118654755],
    ["199", "212", 55.60575509783138],
    ["75499", "213", 19],
    ["212", "75504", 16.0],
    ["197", "75516", 33.0],
    ["214", "75508", 15.033296378372908],
    ["75513", "181", 19],
    ["75508", "214", 15],
    ["206", "75503", 17.72004514666935],
    ["197", "202", 38.01315561749642],
    ["192", "202", 69.0],
    ["202", "201", 109.00458705944443],
    ["200", "75503", 30.066592756745816],
    ["199", "196", 71.06335201775947],
    ["201", "193", 19.0],
    ["204", "200", 41.012193308819754],
    ["183", "191", 65.11528238439882],
    ["196", "203", 19.026297590440446],
    ["186", "75509", 18.788294228055936],
    ["183", "189", 85.84287972802403],
    ["189", "188", 16.0],
    ["205", "75503", 36.40054944640259],
    ["190", "192", 21.02379604162864],
    ["191", "188", 37.0],
    ["188", "192", 45.044422518220834],
    ["204", "75503", 51.86520991955976],
    ["70", "1161", 13.601470508735444],
    ["175", "1161", 26.92582403567252],
    ["76", "1162", 10.44030650891055],
    ["74", "1162", 23.08679276123039],
    ["78", "1163", 17.0],
    ["80", "1163", 27.073972741361768],
    ["82", "1164", 12.649110640673518],
    ["167", "1164", 26.019223662515376],
    ["171", "1166", 30.01666203960727],
    ["173", "1166", 32.449961479175904],
    ["84", "1167", 13.152946437965905],
    ["215", "1167", 27.459060435491963],
    ["86", "1168", 16.492422502470642],
    ["215", "1168", 22.67156809750927],
    ["89", "1169", 13.0],
    ["216", "1169", 15.264337522473747],
    ["92", "1170", 11.661903789690601],
    ["90", "1170", 26.68332812825267],
    ["97", "1171", 13.45362404707371],
    ["95", "1171", 19.697715603592208],
    ["1962", "1172", 19.235384061671343],
    ["1965", "1172", 22.67156809750927],
    ["101", "1173", 21.633307652783937],
    ["99", "1173", 23.194827009486403],
    ["1964", "1174", 13.601470508735444],
    ["1967", "1174", 30.4138126514911],
    ["1970", "1175", 12.0],
    ["1968", "1175", 30.59411708155671],
    ["102", "1176", 13.416407864998739],
    ["100", "1176", 27.892651361962706],
    ["104", "1177", 11.704699910719626],
    ["102", "1177", 27.018512172212592],
    ["106", "1178", 13.038404810405298],
    ["107", "1178", 29.154759474226502],
    ["1961", "1179", 10.04987562112089],
    ["1959", "1179", 25.079872407968907],
    ["1957", "1180", 14.317821063276353],
    ["1959", "1180", 28.284271247461902],
    ["1955", "1181", 14.142135623730951],
    ["112", "1181", 32.0],
    ["114", "1182", 12.041594578792296],
    ["112", "1182", 19.6468827043885],
    ["1954", "1183", 11.40175425099138],
    ["1952", "1183", 29.614185789921695],
    ["117", "1184", 10.44030650891055],
    ["115", "1184", 29.410882339705484],
    ["1951", "1185", 13.601470508735444],
    ["1953", "1185", 31.76476034853718],
    ["1948", "1186", 11.40175425099138],
    ["120", "1186", 26.419689627245813],
    ["1943", "1187", 12.36931687685298],
    ["1940", "1187", 30.528675044947494],
    ["119", "1188", 14.866068747318506],
    ["1949", "1188", 24.041630560342615],
    ["218", "1189", 15.0],
    ["130", "1189", 24.698178070456937],
    ["221", "1190", 27.459060435491963],
    ["220", "1190", 49.040799340956916],
    ["2149", "1191", 25.0],
    ["2151", "1191", 32.38826948140329],
    ["2150", "1192", 30.0],
    ["2028", "1193", 18.601075237738275],
    ["2026", "1193", 29.614185789921695],
    ["2039", "1195", 19.697715603592208],
    ["2155", "1195", 25.059928172283335],
    ["2042", "1197", 16.278820596099706],
    ["735", "1197", 20.12461179749811],
    ["2044", "1199", 22.80350850198276],
    ["8", "1199", 30.14962686336267],
    ["2046", "1200", 20.248456731316587],
    ["2044", "1200", 30.01666203960727],
    ["2051", "1203", 18.0],
    ["2053", "1203", 26.019223662515376],
    ["2181", "1204", 14.560219778561036],
    ["2055", "1204", 23.08679276123039],
    ["2058", "1205", 15.524174696260024],
    ["2180", "1205", 22.02271554554524],
    ["2060", "1206", 14.7648230602334],
    ["2058", "1206", 39.6232255123179],
    ["2063", "1207", 20.248456731316587],
    ["2065", "1207", 27.294688127912362],
    ["2065", "1208", 20.808652046684813],
    ["2067", "1208", 29.154759474226502],
    ["2068", "1209", 15.297058540778355],
    ["2067", "1209", 29.017236257093817],
    ["2076", "1210", 27.073972741361768],
    ["271", "1210", 30.083217912982647],
    ["2078", "1211", 26.0],
    ["2080", "1211", 29.732137494637012],
    ["1987", "1212", 19.313207915827967],
    ["1985", "1212", 22.135943621178654],
    ["1990", "1213", 20.8806130178211],
    ["1988", "1213", 23.769728648009426],
    ["1992", "1214", 18.788294228055936],
    ["1990", "1214", 29.154759474226502],
    ["1994", "1215", 19.72308292331602],
    ["1992", "1215", 33.015148038438355],
    ["1996", "1216", 22.47220505424423],
    ["1994", "1216", 38.47076812334269],
    ["1999", "1217", 18.35755975068582],
    ["1997", "1217", 32.69556544854363],
    ["2003", "1218", 19.0],
    ["2001", "1218", 29.5296461204668],
    ["2008", "1220", 10.816653826391969],
    ["2006", "1220", 22.20360331117452],
    ["2091", "1221", 11.704699910719626],
    ["2011", "1221", 19.4164878389476],
    ["2096", "1222", 30.886890422961002],
    ["2188", "1222", 34.92849839314596],
    ["2100", "1223", 35.805027579936315],
    ["2098", "1223", 43.139309220245984],
    ["2101", "1224", 35.84689665786984],
    ["2100", "1224", 48.91829923454004],
    ["2013", "1225", 15.0],
    ["2011", "1225", 27.892651361962706],
    ["2121", "1226", 22.360679774997898],
    ["2119", "1226", 24.20743687382041],
    ["2162", "1228", 26.476404589747453],
    ["2160", "1228", 36.22154055254967],
    ["2164", "1229", 22.47220505424423],
    ["2067", "1229", 36.76955262170047],
    ["272", "1230", 26.40075756488817],
    ["2169", "1231", 24.351591323771842],
    ["2109", "1232", 14.317821063276353],
    ["2169", "1232", 38.3275357934736],
    ["2112", "1233", 22.0],
    ["2110", "1233", 39.11521443121589],
    ["1988", "1234", 29.068883707497267],
    ["1987", "1234", 30.479501308256342],
    ["2114", "1235", 13.0],
    ["2112", "1235", 36.013886210738214],
    ["2116", "1236", 32.14031735997639],
    ["2114", "1236", 32.2490309931942],
    ["2116", "1237", 18.681541692269406],
    ["2118", "1237", 30.870698080866262],
    ["2118", "1238", 24.041630560342615],
    ["2120", "1238", 34.058772731852805],
    ["2121", "1239", 13.92838827718412],
    ["2120", "1239", 32.69556544854363],
    ["2124", "1240", 24.515301344262525],
    ["2122", "1240", 33.52610922848042],
    ["2124", "1241", 15.0],
    ["2126", "1241", 29.0],
    ["2127", "1242", 19.1049731745428],
    ["2009", "1242", 32.0624390837628],
    ["2127", "1243", 24.166091947189145],
    ["2010", "1243", 30.4138126514911],
    ["2130", "1244", 27.294688127912362],
    ["2012", "1244", 30.805843601498726],
    ["2136", "1245", 25.942243542145693],
    ["2134", "1245", 27.80287754891569],
    ["2137", "1246", 20.223748416156685],
    ["2139", "1247", 16.1245154965971],
    ["2141", "1247", 32.449961479175904],
    ["2145", "1248", 23.021728866442675],
    ["2143", "1248", 33.54101966249684],
    ["2120", "1252", 28.160255680657446],
    ["2000", "1252", 28.231188426986208],
    ["1771", "1253", 17.88854381999832],
    ["1871", "1253", 30.083217912982647],
    ["1736", "1254", 16.278820596099706],
    ["1734", "1255", 12.041594578792296],
    ["1732", "1255", 23.430749027719962],
    ["1732", "1256", 14.317821063276353],
    ["1728", "1257", 20.09975124224178],
    ["1730", "1257", 39.6232255123179],
    ["1725", "1258", 16.278820596099706],
    ["1872", "1259", 18.601075237738275],
    ["1725", "1259", 27.51363298439521],
    ["1712", "1260", 18.681541692269406],
    ["1860", "1260", 36.05551275463989],
    ["1861", "1261", 20.0],
    ["1859", "1261", 36.6742416417845],
    ["1869", "1263", 24.08318915758459],
    ["1836", "1263", 40.311288741492746],
    ["1823", "1266", 19.6468827043885],
    ["1836", "1266", 26.076809620810597],
    ["1838", "1267", 13.601470508735444],
    ["1825", "1267", 21.95449840010015],
    ["1827", "1268", 14.035668847618199],
    ["1829", "1268", 24.351591323771842],
    ["1708", "1269", 17.029386365926403],
    ["1884", "1269", 22.135943621178654],
    ["1863", "1270", 14.317821063276353],
    ["1708", "1270", 26.019223662515376],
    ["1864", "1271", 17.4928556845359],
    ["1863", "1271", 28.160255680657446],
    ["1682", "1273", 32.202484376209235],
    ["1865", "1273", 35.12833614050059],
    ["1866", "1274", 18.439088914585774],
    ["1701", "1274", 44.654227123532216],
    ["1896", "1275", 26.248809496813376],
    ["1700", "1275", 46.04345773288535],
    ["1896", "1276", 21.02379604162864],
    ["1694", "1276", 34.48187929913333],
    ["1694", "1277", 11.40175425099138],
    ["1685", "1277", 27.459060435491963],
    ["1686", "1278", 10.44030650891055],
    ["1693", "1278", 25.495097567963924],
    ["1893", "1279", 11.661903789690601],
    ["1686", "1279", 33.52610922848042],
    ["1698", "1283", 10.816653826391969],
    ["1800", "1283", 29.614185789921695],
    ["1798", "1284", 20.396078054371138],
    ["1806", "1284", 30.870698080866262],
    ["1887", "1285", 20.615528128088304],
    ["1802", "1285", 26.248809496813376],
    ["1805", "1286", 22.02271554554524],
    ["1886", "1286", 25.96150997149434],
    ["1886", "1287", 36.87817782917155],
    ["1804", "1287", 44.181444068749045],
    ["1904", "1288", 20.0],
    ["1791", "1288", 27.80287754891569],
    ["1793", "1289", 18.681541692269406],
    ["1852", "1289", 21.37755832643195],
    ["1790", "1290", 16.55294535724685],
    ["1791", "1290", 36.87817782917155],
    ["1787", "1291", 19.924858845171276],
    ["1785", "1291", 41.10960958218893],
    ["1782", "1292", 35.77708763999664],
    ["1890", "1292", 37.64306044943742],
    ["1782", "1293", 21.470910553583888],
    ["1846", "1293", 31.827660925679098],
    ["1891", "1294", 32.7566787083184],
    ["1782", "1294", 39.395431207184416],
    ["1890", "1295", 30.870698080866262],
    ["1845", "1295", 33.1058907144937],
    ["1766", "1296", 32.55764119219941],
    ["1908", "1301", 23.021728866442675],
    ["1879", "1301", 24.08318915758459],
    ["1877", "1302", 13.341664064126334],
    ["1878", "1302", 28.231188426986208],
    ["1878", "1303", 32.31098884280702],
    ["1877", "1303", 54.817880294662984],
    ["1879", "1304", 34.48187929913333],
    ["1764", "1304", 49.24428900898052],
    ["1756", "1305", 18.027756377319946],
    ["1754", "1305", 24.839484696748443],
    ["1753", "1306", 12.649110640673518],
    ["1754", "1306", 30.4138126514911],
    ["1747", "1307", 15.524174696260024],
    ["1750", "1307", 31.016124838541646],
    ["1902", "1308", 14.422205101855956],
    ["1903", "1308", 31.622776601683793],
    ["1738", "1309", 16.0312195418814],
    ["1819", "1310", 18.788294228055936],
    ["1817", "1310", 28.284271247461902],
    ["1837", "1311", 19.235384061671343],
    ["1820", "1311", 23.706539182259394],
    ["1824", "1312", 20.024984394500787],
    ["1826", "1312", 26.40075756488817],
    ["1709", "1313", 22.47220505424423],
    ["1838", "1313", 40.0],
    ["1705", "1315", 17.0],
    ["1703", "1315", 35.35533905932738],
    ["1702", "1316", 14.866068747318506],
    ["1704", "1316", 33.24154027718932],
    ["1701", "1317", 19.79898987322333],
    ["1839", "1317", 41.182520563948],
    ["1689", "1318", 15.033296378372908],
    ["1691", "1318", 33.83784863137726],
    ["1692", "1319", 15.811388300841896],
    ["1690", "1319", 34.713109915419565],
    ["1811", "1320", 16.15549442140351],
    ["1692", "1320", 32.0624390837628],
    ["1810", "1321", 19.72308292331602],
    ["1060", "1322", 17.029386365926403],
    ["1062", "1322", 43.86342439892262],
    ["1059", "1323", 19.4164878389476],
    ["1057", "1323", 32.38826948140329],
    ["1059", "1324", 35.34119409414458],
    ["1060", "1324", 36.22154055254967],
    ["1063", "1325", 27.018512172212592],
    ["1061", "1325", 43.657759905886145],
    ["1051", "1327", 21.213203435596427],
    ["1049", "1327", 30.265491900843113],
    ["1014", "1329", 18.027756377319946],
    ["1016", "1329", 35.4400902933387],
    ["1008", "1330", 14.142135623730951],
    ["1009", "1330", 23.323807579381203],
    ["1000", "1331", 18.788294228055936],
    ["1002", "1331", 30.59411708155671],
    ["998", "1332", 14.142135623730951],
    ["997", "1332", 27.16615541441225],
    ["994", "1333", 15.0],
    ["996", "1333", 34.17601498127012],
    ["1021", "1334", 17.69180601295413],
    ["1019", "1334", 35.4682957019364],
    ["1025", "1335", 30.463092423455635],
    ["982", "1335", 34.0147027033899],
    ["1030", "1338", 14.422205101855956],
    ["1034", "1338", 30.675723300355934],
    ["1027", "1339", 20.615528128088304],
    ["1035", "1339", 25.0],
    ["1039", "1340", 34.058772731852805],
    ["1041", "1340", 45.17742799230607],
    ["1040", "1341", 65.03076195155643],
    ["1039", "1341", 88.0],
    ["1033", "1342", 13.92838827718412],
    ["1032", "1342", 29.154759474226502],
    ["1032", "1343", 35.4682957019364],
    ["1033", "1343", 49.57822102496216],
    ["985", "1344", 15.652475842498529],
    ["986", "1344", 29.068883707497267],
    ["990", "1345", 15.0],
    ["1044", "1345", 31.906112267087632],
    ["986", "1346", 14.035668847618199],
    ["985", "1346", 30.083217912982647],
    ["936", "1372", 14.317821063276353],
    ["934", "1372", 25.238858928247925],
    ["65", "1374", 17.46424919657298],
    ["62", "1374", 20.12461179749811],
    ["2008", "1375", 18.439088914585774],
    ["2160", "1376", 29.068883707497267],
    ["2051", "1376", 45.89117562233506],
    ["1796", "1377", 14.866068747318506],
    ["1794", "1377", 20.248456731316587],
    ["2050", "1452", 14.560219778561036],
    ["2046", "1452", 33.52610922848042],
    ["26", "1454", 20.248456731316587],
    ["25", "1454", 24.413111231467404],
    ["2158", "1455", 23.259406699226016],
    ["8", "1455", 39.6232255123179],
    ["2006", "1456", 22.090722034374522],
    ["2004", "1456", 27.294688127912362],
    ["2003", "1457", 22.20360331117452],
    ["2123", "1457", 23.769728648009426],
    ["2034", "1458", 10.44030650891055],
    ["2035", "1458", 25.942243542145693],
    ["1797", "1459", 25.942243542145693],
    ["1795", "1459", 33.60059523282288],
    ["1718", "1912", 18.0],
    ["1717", "1912", 44.384682042344295],
    ["1835", "271", 100],
    ["1835", "272", 100],
    ["1835", "273", 100],
    ["1837", "271", 100],
    ["1837", "272", 100],
    ["1837", "273", 100],
    ["1821", "271", 100],
    ["1821", "272", 100],
    ["1821", "273", 100],
    ["1822", "271", 100],
    ["1822", "272", 100],
    ["1822", "273", 100],
    ["1836", "271", 100],
    ["1836", "272", 100],
    ["1836", "273", 100],
    ["1855", "832", 100],
    ["1855", "833", 100],
    ["1855", "834", 100],
    ["1855", "835", 100],
    ["1857", "832", 100],
    ["1857", "833", 100],
    ["1857", "834", 100],
    ["1857", "835", 100],
    ["1858", "832", 100],
    ["1858", "833", 100],
    ["1858", "834", 100],
    ["1858", "835", 100],
    ["1856", "832", 100],
    ["1856", "833", 100],
    ["1856", "834", 100],
    ["1856", "835", 100],
    ["831", "836", 100],
    ["831", "837", 100],
    ["836", "837", 100],
    ["2152", "840", 100],
    ["1916", "712", 100],
    ["712", "2154", 100],
    ["841", "2154", 100],
    ["712", "1917", 100],
    ["2154", "712", 100],
    ["2154", "841", 100],
    ["1917", "712", 100],
    ["712", "2155", 100],
    ["841", "2155", 100],
    ["712", "839", 100],
    ["2155", "712", 100],
    ["2155", "841", 100],
    ["839", "712", 100],
    ["712", "2153", 100],
    ["841", "2153", 100],
    ["712", "1055", 100],
    ["2153", "712", 100],
    ["2153", "841", 100],
    ["1055", "712", 100],
    ["712", "2152", 100],
    ["841", "2152", 100],
    ["712", "938", 53.16013544000805],
    ["2152", "712", 100],
    ["2152", "841", 100],
    ["938", "712", 53],
    ["712", "1913", 100],
    ["21577", "21578", 12.206555615733702],
    ["21578", "1707", 19.026297590440446],
    ["1707", "21578", 19.026297590440446],
    ["21578", "1900", 21.02379604162864],
    ["1900", "21578", 21.02379604162864],
    ["1900", "1314", 28.635642126552707],
    ["1708", "21577", 1],
    ["21578", "1314", 9.899494936611665],
    ["21577", "1708", 15.297058540778355],
    ["21577", "1314", 14.317821063276353],
    ["1708", "21577", 15.297058540778355],
    ["21578", "21577", 12.206555615733702],
    ["1707", "1314", 13.416407864998739],
    ["1826", "1313", 21.213203435596427],
    ["225", "938", 94.04786015641186],
    ["939", "225", 93.34880824091971],
    ["1812", "1282", 13.0],
    ["1898", "1321", 13.601470508735444],
    ["1797", "1284", 14.212670403551895],
    ["1726", "1258", 15.811388300841896],
    ["1739", "1309", 14.866068747318506],
    ["1890", "1294", 15.524174696260024],
    ["1889", "1294", 39.824615503479755],
    ["1844", "1295", 43.46262762420146],
    ["21651", "2149", 22.02271554554524],
    ["2149", "21651", 22.02271554554524],
    ["2148", "21651", 25.019992006393608],
    ["21651", "2148", 25.019992006393608],
    ["2149", "2148", 15.264337522473747],
    ["2148", "2149", 15.264337522473747],
    ["2138", "1246", 8.246211251235321],
    ["2016", "2131", 39.20459156782532],
    ["2131", "2016", 39.20459156782532],
    ["2028", "21652", 22.0],
    ["21651", "21652", 19.235384061671343],
    ["21652", "21651", 19.235384061671343],
    ["21652", "2027", 24.166091947189145],
    ["2027", "21652", 24.166091947189145],
    ["2010", "1375", 57.697486947006624],
    ["2080", "1985", 49.24428900898052],
    ["1985", "2080", 49.24428900898052],
    ["1986", "1212", 7.280109889280518],
    ["2168", "1231", 13.341664064126334],
    ["230", "1106", 11.180339887498949],
    ["196", "182", 33.54101966249684],
    ["212", "209", 32.0],
    ["245", "22450", 19.1049731745428],
    ["244", "22450", 38.01315561749642],
    ["246", "22450", 59.09314681077663],
    ["248", "22450", 63.56099432828282],
    ["22450", "245", 19.1049731745428],
    ["245", "22450", 19.1049731745428],
    ["22450", "248", 63.56099432828282],
    ["248", "22450", 63.56099432828282],
    ["22450", "246", 59.09314681077663],
    ["246", "22450", 59.09314681077663],
    ["834", "835", 46.389654018972806],
    ["917", "32355", 23.769728648009426],
    ["936", "32355", 25.80697580112788],
    ["916", "32355", 29.154759474226502],
    ["935", "32355", 31.575306807693888],
    ["32355", "936", 25.80697580112788],
    ["32355", "917", 23.769728648009426],
    ["32355", "935", 31.575306807693888],
    ["32355", "916", 29.154759474226502],
    ["151", "32356", 4.0],
    ["150", "32356", 24.20743687382041],
    ["1774", "75535", 17.204650534085253],
    ["193", "203", 36.013886210738214],
    ["152", "32357", 3.605551275463989],
    ["51", "32357", 19.026297590440446],
    ["153", "32357", 24.08318915758459],
    ["52", "32357", 25.079872407968907],
    ["167", "32358", 9.219544457292887],
    ["217", "32358", 12.36931687685298],
    ["82", "32358", 13.416407864998739],
    ["168", "32358", 22.825424421026653],
    ["216", "32359", 11.180339887498949],
    ["89", "32359", 13.45362404707371],
    ["87", "32359", 21.213203435596427],
    ["90", "32359", 24.351591323771842],
    ["1945", "32360", 40.45985664828782],
    ["1946", "32360", 44.14748010928823],
    ["123", "32360", 45.60701700396552],
    ["122", "32360", 45.967379738244816],
    ["32356", "151", 4.0],
    ["197", "75506", 16.0],
    ["32356", "232", 77.1621668954417],
    ["32356", "231", 79.62411694957753],
    ["32356", "243", 75.0],
    ["32357", "152", 3.605551275463989],
    ["32357", "51", 19.026297590440446],
    ["32357", "19", 43.41658669218482],
    ["32357", "19", 43.41658669218482],
    ["32357", "18", 40.0],
    ["32358", "217", 12.36931687685298],
    ["32358", "82", 13.416407864998739],
    ["32358", "167", 9.219544457292887],
    ["32358", "168", 22.825424421026653],
    ["32359", "216", 11.180339887498949],
    ["32359", "89", 13.45362404707371],
    ["32359", "90", 24.351591323771842],
    ["32359", "1970", 28.861739379323623],
    ["32359", "1969", 34.0],
    ["32360", "122", 45.967379738244816],
    ["32360", "1945", 40.45985664828782],
    ["32360", "249", 66.48308055437865],
    ["32360", "250", 61.98386886924694],
    ["32360", "251", 74.43117626371358],
    ["32360", "124", 54.3323108288245],
    ["834", "32361", 10.44030650891055],
    ["2029", "32361", 22.02271554554524],
    ["2028", "32361", 26.30589287593181],
    ["2030", "32361", 31.78049716414141],
    ["2166", "32362", 35.608987629529715],
    ["272", "32362", 37.05401462729781],
    ["273", "32362", 47.92702786528704],
    ["2131", "32363", 10.816653826391969],
    ["2015", "32363", 18.788294228055936],
    ["2130", "32363", 18.973665961010276],
    ["2014", "32363", 28.0178514522438],
    ["2001", "32364", 9.055385138137417],
    ["2000", "32364", 21.02379604162864],
    ["2002", "32364", 29.410882339705484],
    ["1999", "32364", 39.56008088970496],
    ["1048", "32365", 10.44030650891055],
    ["1011", "32365", 14.212670403551895],
    ["1010", "32365", 21.470910553583888],
    ["1012", "32365", 22.80350850198276],
    ["1862", "32366", 18.384776310850235],
    ["1716", "32366", 18.439088914585774],
    ["1818", "32366", 31.76476034853718],
    ["1817", "32366", 36.71511950137164],
    ["1699", "32367", 10.198039027185569],
    ["1894", "32367", 24.698178070456937],
    ["1692", "32367", 25.0],
    ["1811", "32367", 27.892651361962706],
    ["32361", "2029", 22.02271554554524],
    ["32361", "834", 10.44030650891055],
    ["32361", "2030", 31.78049716414141],
    ["32361", "2028", 26.30589287593181],
    ["32362", "2168", 55.00909015790027],
    ["32362", "2166", 35.608987629529715],
    ["32364", "2001", 9.055385138137417],
    ["32364", "2002", 29.410882339705484],
    ["32364", "2121", 43.139309220245984],
    ["32363", "2015", 18.788294228055936],
    ["32363", "2014", 28.0178514522438],
    ["32363", "2131", 10.816653826391969],
    ["733", "1774", 54.08326913195984],
    ["733", "1775", 48.08326112068523],
    ["733", "1840", 38.948684188300895],
    ["733", "1841", 31.400636936215164],
    ["733", "1842", 42.5440947723653],
    ["733", "1766", 54.78138369920935],
    ["733", "1858", 20.024984394500787],
    ["733", "1856", 17.029386365926403],
    ["32366", "1862", 18.384776310850235],
    ["32366", "1818", 31.76476034853718],
    ["32366", "1716", 18.439088914585774],
    ["32367", "1898", 88.11923740024082],
    ["32367", "1870", 77.6659513557904],
    ["32367", "1812", 38.18376618407357],
    ["32367", "1811", 27.892651361962706],
    ["32367", "1894", 24.698178070456937],
    ["32367", "1699", 10.198039027185569],
    ["1811", "1812", 34.0],
    ["1811", "10786", 185.31055015837603],
    ["1728", "86280", 25.495097567963924],
    ["1810", "10786", 185],
    ["10786", "1810", 185],
    ["1811", "10786", 185.31055015837603],
    ["1729", "86280", 11.661903789690601],
    ["1813", "10786", 185],
    ["10786", "1813", 185],
    ["1812", "10786", 185],
    ["10786", "1812", 185],
    ["75514", "193", 33.0],
    ["199", "193", 54.00925846556311],
    ["75503", "207", 26.076809620810597],
    ["205", "207", 31.25699921617557],
    ["261", "1092", 22.47220505424423],
    ["47", "1123", 8.94427190999916],
    ["147", "1913", 100],
    ["1913", "147", 100],
    ["712", "1086", 45.0111097397076],
    ["712", "937", 59.413803110051795],
    ["1913", "712", 100],
    ["712", "1914", 100],
    ["841", "1917", 100],
    ["1917", "841", 100],
    ["147", "839", 200],
    ["839", "147", 200],
    ["147", "1055", 200],
    ["1055", "147", 200],
    ["840", "839", 200],
    ["839", "840", 200],
    ["840", "1055", 200],
    ["1055", "840", 200],
    ["841", "839", 200],
    ["839", "841", 200],
    ["841", "1055", 200],
    ["1055", "841", 200],
    ["841", "45", 35.22782990761707],
    ["45", "841", 35],
    ["841", "134", 27.459060435491963],
    ["134", "841", 27],
    ["841", "840", 32.202484376209235],
    ["840", "841", 32],
    ["841", "133", 32.55764119219941],
    ["133", "841", 32],
    ["841", "1118", 8.48528137423857],
    ["840", "133", 29.206163733020468],
    ["133", "840", 29],
    ["840", "146", 38.118237105091836],
    ["146", "840", 38],
    ["840", "145", 42.15447781671598],
    ["145", "840", 42],
    ["1028", "1029", 58.89821729050889],
    ["1029", "1028", 58],
    ["1028", "1034", 63.06346010171025],
    ["1034", "1028", 63],
    ["1034", "1342", 12.0],
    ["1029", "1338", 9.219544457292887],
    ["147", "1914", 100],
    ["1914", "147", 100],
    ["147", "1915", 100],
    ["1915", "147", 100],
    ["147", "1916", 100],
    ["1916", "147", 100],
    ["147", "1917", 100],
    ["1917", "147", 100],
    ["937", "712", 59],
    ["712", "949", 48.76474136094644],
    ["949", "712", 48],
    ["2154", "2156", 28.30194339616981],
    ["2156", "2154", 28],
    ["2154", "2039", 32.28002478313795],
    ["2039", "2154", 32],
    ["2154", "2155", 41.10960958218893],
    ["1914", "1915", 17.204650534085253],
    ["1915", "1914", 17],
    ["1915", "1916", 17.11724276862369],
    ["1916", "1915", 17],
    ["1915", "1767", 30.265491900843113],
    ["1767", "1915", 30],
    ["1916", "1855", 25.317977802344327],
    ["1855", "1916", 25],
    ["840", "1913", 100],
    ["1913", "840", 100],
    ["840", "1914", 100],
    ["1914", "840", 100],
    ["840", "1915", 100],
    ["1915", "840", 100],
    ["840", "1916", 100],
    ["1916", "840", 100],
    ["840", "1917", 100],
    ["1917", "840", 100],
    ["2155", "2154", 41],
    ["2154", "1195", 40.024992192379],
    ["2155", "2038", 22.67156809750927],
    ["2038", "2155", 22],
    ["2155", "832", 21.400934559032695],
    ["832", "2155", 21],
    ["2155", "1195", 25.059928172283335],
    ["2153", "832", 17.72004514666935],
    ["832", "2153", 17],
    ["2153", "2152", 27.294688127912362],
    ["1916", "1917", 42.01190307520001],
    ["1917", "1916", 42],
    ["1917", "1855", 23.40939982143925],
    ["1855", "1917", 23],
    ["1917", "1918", 23.706539182259394],
    ["1918", "1917", 23],
    ["839", "1065", 15.231546211727817],
    ["1065", "839", 15],
    ["1055", "1056", 23.08679276123039],
    ["1056", "1055", 23],
    ["841", "1913", 100],
    ["1913", "841", 100],
    ["841", "1914", 100],
    ["1914", "841", 100],
    ["2152", "2153", 27],
    ["2152", "2151", 22.02271554554524],
    ["2151", "2152", 22],
    ["2152", "835", 40.607881008493905],
    ["1055", "1051", 25.632011235952593],
    ["1051", "1055", 25],
    ["147", "2154", 100],
    ["2154", "147", 100],
    ["147", "2155", 100],
    ["2155", "147", 100],
    ["147", "2153", 100],
    ["2153", "147", 100],
    ["835", "2152", 40],
    ["2152", "1192", 17.88854381999832],
    ["147", "2152", 100],
    ["2152", "147", 100],
    ["840", "2154", 100],
    ["1913", "1760", 16.1245154965971],
    ["1914", "1760", 30.4138126514911],
    ["1913", "1914", 15.652475842498529],
    ["1914", "1913", 15],
    ["1914", "1737", 21.095023109728988],
    ["1737", "1914", 21],
    ["841", "1915", 100],
    ["1915", "841", 100],
    ["841", "1916", 100],
    ["1916", "841", 100],
    ["147", "146", 36.013886210738214],
    ["146", "147", 36],
    ["147", "148", 18.439088914585774],
    ["148", "147", 18],
    ["2154", "1913", 100],
    ["1913", "2154", 100],
    ["2154", "1914", 100],
    ["1914", "2154", 100],
    ["2154", "1915", 100],
    ["1915", "2154", 100],
    ["2154", "1916", 100],
    ["1916", "2154", 100],
    ["2154", "1917", 100],
    ["1917", "2154", 100],
    ["2154", "839", 100],
    ["839", "2154", 100],
    ["2154", "1055", 100],
    ["1055", "2154", 100],
    ["2155", "1913", 100],
    ["1913", "2155", 100],
    ["2155", "1914", 100],
    ["1914", "2155", 100],
    ["2155", "1915", 100],
    ["1915", "2155", 100],
    ["2155", "1916", 100],
    ["1916", "2155", 100],
    ["2155", "1917", 100],
    ["1917", "2155", 100],
    ["2155", "839", 100],
    ["839", "2155", 100],
    ["2155", "1055", 100],
    ["1055", "2155", 100],
    ["2153", "1913", 100],
    ["1913", "2153", 100],
    ["2153", "1914", 100],
    ["1914", "2153", 100],
    ["2153", "1915", 100],
    ["1915", "2153", 100],
    ["2153", "1916", 100],
    ["1916", "2153", 100],
    ["2153", "1917", 100],
    ["1917", "2153", 100],
    ["2153", "839", 100],
    ["839", "2153", 100],
    ["2153", "1055", 100],
    ["1055", "2153", 100],
    ["2152", "1913", 100],
    ["1913", "2152", 100],
    ["2152", "1914", 100],
    ["1914", "2152", 100],
    ["2152", "1915", 100],
    ["1915", "2152", 100],
    ["2152", "1916", 100],
    ["1916", "2152", 100],
    ["2152", "1917", 100],
    ["1917", "2152", 100],
    ["2152", "839", 100],
    ["839", "2152", 100],
    ["2152", "1055", 100],
    ["1055", "2152", 100],
    ["1913", "839", 100],
    ["839", "1913", 100],
    ["1913", "1055", 100],
    ["1055", "1913", 100],
    ["1914", "839", 100],
    ["839", "1914", 100],
    ["1914", "1055", 100],
    ["1055", "1914", 100],
    ["1915", "839", 100],
    ["839", "1915", 100],
    ["1915", "1055", 100],
    ["1055", "1915", 100],
    ["1916", "839", 100],
    ["839", "1916", 100],
    ["1916", "1055", 100],
    ["1055", "1916", 100],
    ["1917", "839", 100],
    ["839", "1917", 100],
    ["1917", "1055", 100],
    ["1055", "1917", 100],
    ["834", "224", "100"],
    ["224", "834", "100"],
    ["22450", "1795", 410],
    ["1811", "10786", 185.31055015837603],
    ["10786", "1811", 185],
    ["1918", "1888", 57.87054518492115],
    ["1888", "1918", 57],
    ["835", "834", 46],
    ["272", "177", 100],
    ["837", "53925", 100],
    ["176", "272", 100],
    ["272", "176", 100],
    ["837", "53927", 100],
    ["837", "53929", 100],
    ["837", "53930", 100],
    ["837", "53931", 100],
    ["177", "272", 100],
    ["65008", "65009", 18.788294228055936],
    ["1026", "65009", "18"],
    ["65009", "1026", "18"],
    ["65008", "1024", "18"],
    ["1024", "65008", "18"],
    ["65009", "65008", "18"],
    ["2119", "1811", "100"],
    ["1811", "2119", "100"],
    ["1965", "2119", "100"],
    ["2119", "1965", "100"],
    ["186", "188", 56.0357029044876],
    ["75515", "191", 75.64390259630977],
    ["185", "188", 79.05694150420949],
    ["183", "75508", 22.20360331117452],
    ["188", "190", 24.020824298928627],
    ["179", "1155", 10.0],
    ["201", "1160", 7.280109889280518],
    ["75502", "75509", 14.317821063276353],
    ["203", "200", 61.00819617067857],
    ["193", "1160", 12.165525060596439],
    ["196", "200", 80.02499609497022],
    ["182", "1155", 12.0],
    ["203", "1159", 11.40175425099138],
    ["203", "180", 9.219544457292887],
    ["75500", "1154", 10.0],
    ["197", "198", 52.0],
    ["192", "198", 83.00602387778854],
    ["198", "199", 60.00833275470999],
    ["190", "192", 21.02379604162864],
    ["202", "199", 74.0],
    ["75497", "1154", 12.041594578792296],
    ["204", "180", 14.317821063276353],
    ["75500", "1151", 14.142135623730951],
    ["75504", "1145", 9.055385138137417],
    ["205", "184", 10.0],
    ["212", "1145", 7.0710678118654755],
    ["204", "207", 35.35533905932738],
    ["206", "207", 36.359317925395686],
    ["207", "75513", 15.033296378372908],
    ["204", "75513", 30.066592756745816],
    ["75505", "1145", 17.46424919657298],
    ["209", "1144", 9.219544457292887],
    ["75501", "1153", 8.246211251235321],
    ["213", "1153", 8.06225774829855],
    ["200", "184", 13.892443989449804],
    ["75503", "1157", 14.142135623730951],
    ["75510", "1149", 23.194827009486403],
    ["75511", "1149", 21.540659228538015],
    ["151", "75498", 18.788294228055936],
    ["197", "192", 31.016124838541646],
    ["75516", "75506", 17.0],
    ["208", "1144", 9.219544457292887],
    ["207", "1157", 12.0],
    ["202", "195", 21.02379604162864],
    ["197", "75506", 16.0],
    ["75517", "1143", 9.219544457292887],
    ["75499", "1153", 18.24828759089466],
    ["195", "75506", 23.345235059857504],
    ["208", "75506", 24.758836806279895],
    ["198", "75505", 16.1245154965971],
    ["190", "202", 90.00555538409837],
    ["75504", "75505", 17.11724276862369],
    ["189", "75512", 32.7566787083184],
    ["202", "198", 14.035668847618199],
    ["195", "198", 35.0],
    ["202", "198", 14.035668847618199],
    ["75505", "198", 16.1245154965971],
    ["199", "198", 60.00833275470999],
    ["191", "75512", 34.92849839314596],
    ["188", "75512", 39.408120990476064],
    ["203", "204", 20.0],
    ["75518", "1149", 29.068883707497267],
    ["192", "1148", 11.045361017187261],
    ["75513", "1156", 9.055385138137417],
    ["181", "1156", 10.04987562112089],
    ["75501", "75500", 7],
    ["193", "204", 56.00892785976178],
    ["199", "75499", 14.035668847618199],
    ["213", "75499", 19.6468827043885],
    ["75516", "1143", 10.04987562112089],
    ["75499", "875", 7.0],
    ["75501", "75499", 22.825424421026653],
    ["75513", "75520", 18.027756377319946],
    ["204", "75520", 19.209372712298546],
    ["75518", "1142", 19.0],
    ["151", "185", 27.073972741361768],
    ["75511", "1142", 11.045361017187261],
    ["189", "191", 21.0],
    ["199", "875", 16.1245154965971],
    ["75518", "75511", 30.01666203960727],
    ["190", "75511", 33.734255586866],
    ["210", "1140", 14.035668847618199],
    ["188", "75511", 36.235341863986875],
    ["75512", "75502", 22.20360331117452],
    ["213", "875", 22.47220505424423],
    ["75517", "75511", 40.01249804748511],
    ["214", "1140", 64.00781202322104],
    ["197", "1148", 20.09975124224178],
    ["195", "1147", 7.280109889280518],
    ["202", "1147", 14.035668847618199],
    ["202", "187", 7.615773105863909],
    ["193", "201", 19.0],
    ["193", "196", 17.11724276862369],
    ["75519", "196", 14.560219778561036],
    ["203", "196", 19.026297590440446],
    ["75514", "75499", 23.853720883753127],
    ["151", "183", 17.72004514666935],
    ["197", "195", 17.0],
    ["199", "75499", 14.035668847618199],
    ["75515", "1937", 23.40939982143925],
    ["1937", "75515", 23],
    ["212", "75505", 22.02271554554524],
    ["202", "75505", 23.345235059857504],
    ["196", "75519", 14.560219778561036],
    ["179", "75519", 19.4164878389476],
    ["182", "75519", 19.4164878389476],
    ["193", "75519", 20.615528128088304],
    ["185", "183", 26.627053911388696],
    ["185", "186", 23.021728866442675],
    ["75511", "210", 12.36931687685298],
    ["188", "210", 29.427877939124322],
    ["191", "75502", 30.083217912982647],
    ["186", "75502", 32.89376840679705],
    ["195", "187", 18.973665961010276],
    ["198", "1146", 5.0],
    ["203", "204", 20.0],
    ["75520", "204", 19.209372712298546],
    ["75510", "210", 14],
    ["210", "75512", 28.160255680657446],
    ["205", "204", 21.0],
    ["205", "200", 20.024984394500787],
    ["206", "200", 14.7648230602334],
    ["1976", "200", 11.180339887498949],
    ["190", "210", 35.510561809129406],
    ["1840", "75535", 21.95449840010015],
    ["191", "75507", 12.041594578792296],
    ["189", "75507", 14.422205101855956],
    ["204", "181", 36.235341863986875],
    ["181", "179", 15.033296378372908],
    ["209", "75516", 36.0],
    ["213", "212", 40.01249804748511],
    ["207", "181", 34.0147027033899],
    ["212", "213", 40],
    ["1977", "200", 31.016124838541646],
    ["75515", "75508", 18.027756377319946],
    ["191", "186", 19.1049731745428],
    ["189", "188", 16.0],
    ["189", "75502", 41.012193308819754],
    ["75505", "1146", 16.278820596099706],
    ["201", "75497", 33.54101966249684],
    ["182", "75497", 15.033296378372908],
    ["181", "182", 37.013511046643494],
    ["75514", "75501", 34.20526275297414],
    ["75514", "75500", 32.0624390837628],
    ["75497", "75500", 22.02271554554524],
    ["179", "75497", 37.013511046643494],
    ["193", "75497", 35.4682957019364],
    ["75497", "75501", 29.0],
    ["75500", "75501", 7.0710678118654755],
    ["182", "75500", 37.0],
    ["201", "75500", 35.77708763999664],
    ["75504", "212", 16.0],
    ["212", "75504", 16],
    ["75505", "212", 22.02271554554524],
    ["212", "75505", 22],
    ["202", "209", 34.058772731852805],
    ["75505", "75505", 0.0],
    ["213", "75501", 16.0312195418814],
    ["75504", "209", 16],
    ["209", "208", 18.0],
    ["75505", "75505", 0],
    ["198", "209", 36.6742416417845],
    ["209", "208", 18.0],
    ["195", "208", 33.015148038438355],
    ["75505", "75504", 17.11724276862369],
    ["208", "75517", 37.013511046643494],
    ["75508", "75515", 18],
    ["75517", "75518", 10.198039027185569],
    ["197", "75517", 38.948684188300895],
    ["192", "75518", 33.06055050963308],
    ["75516", "75518", 29.017236257093817],
    ["199", "75514", 21.02379604162864],
    ["1841", "75534", 18.867962264113206],
    ["75514", "201", 14.0],
    ["199", "1146", 55.00909015790027],
    ["183", "75508", 22.20360331117452],
    ["199", "1152", 9.219544457292887],
    ["75514", "1152", 12.36931687685298],
    ["75512", "210", 28],
    ["75502", "75512", 22.20360331117452],
    ["75512", "75502", 22],
    ["75502", "75509", 14.317821063276353],
    ["75509", "75502", 14],
    ["75517", "75518", 10],
    ["214", "75509", 29.68164415931166],
    ["181", "179", 15.033296378372908],
    ["179", "181", 15],
    ["75519", "179", 19.4164878389476],
    ["179", "75519", 19],
    ["182", "179", 22.0],
    ["179", "182", 22],
    ["182", "75497", 15.033296378372908],
    ["75497", "182", 15],
    ["75518", "75511", 30.01666203960727],
    ["75511", "75518", 30],
    ["75508", "183", 22],
    ["151", "75498", 18.788294228055936],
    ["183", "75498", 15.0],
    ["185", "75498", 11.661903789690601],
    ["190", "1141", 15.132745950421556],
    ["75510", "1141", 19.313207915827967],
    ["188", "1141", 9.486832980505138],
    ["213", "198", 64],
    ["199", "212", 55.60575509783138],
    ["212", "199", 55],
    ["225", "151", 40.24922359499622],
    ["151", "225", 40],
    ["224", "151", 84.87638069569178],
    ["151", "224", 84],
    ["75508", "151", 39.35733730830886],
    ["151", "75508", 39],
    ["1842", "75534", 18.867962264113206],
    ["1858", "75534", 38.01315561749642],
    ["1798", "75525", 12.206555615733702],
    ["1798", "75525", 12.206555615733702],
    ["223", "75530", 24.020824298928627],
    ["2029", "75531", 8.246211251235321],
    ["2030", "75531", 15.132745950421556],
    ["834", "75531", 23.021728866442675],
    ["32361", "75531", 18.35755975068582],
    ["1955", "75527", 14.212670403551895],
    ["114", "75527", 15.264337522473747],
    ["113", "75527", 21.633307652783937],
    ["1960", "75528", 17.69180601295413],
    ["21", "75524", 17.46424919657298],
    ["48", "75524", 21.02379604162864],
    ["49", "75524", 25.079872407968907],
    ["1977", "75529", 15.524174696260024],
    ["1979", "75521", 16.15549442140351],
    ["1980", "75521", 16.55294535724685],
    ["10", "75522", 12.206555615733702],
    ["2", "75522", 21.840329667841555],
    ["16", "75523", 12.529964086141668],
    ["15", "75523", 19.72308292331602],
    ["22", "75524", 20.8806130178211],
    ["145", "75530", 18.027756377319946],
    ["226", "75530", 9.219544457292887],
    ["227", "75530", 20.615528128088304],
    ["2169", "75533", 16.492422502470642],
    ["2170", "75533", 32.31098884280702],
    ["2080", "75533", 29.832867780352597],
    ["2110", "75532", 24.758836806279895],
    ["2109", "75532", 20.12461179749811],
    ["1987", "75532", 33.54101966249684],
    ["1986", "75532", 30.4138126514911],
    ["1961", "75528", 19.6468827043885],
    ["103", "75528", 24.698178070456937],
    ["101", "75528", 32.69556544854363],
    ["1949", "75526", 14.317821063276353],
    ["1948", "75526", 18.027756377319946],
    ["120", "75526", 8.54400374531753],
    ["949", "75496", 27.85677655436824],
    ["951", "75496", 25.709920264364882],
    ["950", "75496", 8.0],
    ["183", "97070", 45.09988913511872],
    ["75508", "97070", 59.90826320300064],
    ["185", "97070", 69.3757882838098],
    ["949", "75495", 14.422205101855956],
    ["942", "75495", 27.65863337187866],
    ["947", "1093", 14.317821063276353],
    ["946", "1093", 13.892443989449804],
    ["940", "1085", 10.0],
    ["939", "1085", 39.11521443121589],
    ["1096", "106657", 12.36931687685298],
    ["2055", "2057", 85.0],
    ["2057", "2055", 85],
    ["75502", "1139", 13.038404810405298],
    ["214", "1139", 15.033296378372908],
    ["214", "1139", 15.033296378372908],
    ["936", "964", 9.0],
    ["2121", "86282", 26.248809496813376],
    ["2122", "86282", 31.76476034853718],
    ["2002", "86282", 23.08679276123039],
    ["2001", "86282", 25.80697580112788],
    ["32364", "86282", 22.80350850198276],
    ["2120", "86282", 34.20526275297414],
    ["837", "53926", 135.79396157414365],
    ["1965", "2119", 250],
    ["2119", "1965", 250],
    ["1026", "2119", 250],
    ["23", "97065", 13.341664064126334],
    ["22", "97065", 9.433981132056603],
    ["2", "97066", 27.018512172212592],
    ["1201", "97066", 17.4928556845359],
    ["1984", "97066", 27.018512172212592],
    ["177", "97066", 22.20360331117452],
    ["175", "97066", 44.01136216933077],
    ["178", "97066", 49.09175083453431],
    ["2119", "1026", 250],
    ["214", "97070", 74.73285756613352],
    ["1057", "97067", 36.6742416417845],
    ["1059", "97067", 13.0],
    ["142", "97068", 19.1049731745428],
    ["24", "97068", 22.20360331117452],
    ["144", "97068", 19.026297590440446],
    ["25", "97068", 27.16615541441225],
    ["18", "97069", 25.80697580112788],
    ["19", "97069", 19.924858845171276],
    ["20", "97069", 32.202484376209235],
    ["51", "97069", 18.439088914585774],
    ["152", "97069", 24.758836806279895],
    ["52", "97069", 39.05124837953327],
    ["2160", "97071", 11.40175425099138],
    ["2161", "97071", 17.11724276862369],
    ["953", "106650", 22],
    ["971", "970", 23],
    ["953", "955", 25],
    ["956", "958", 26.0],
    ["958", "956", 26],
    ["106658", "1096", 26.92582403567252],
    ["959", "960", 1],
    ["959", "959", 1],
    ["1094", "958", 18.0],
    ["970", "106645", 19.697715603592208],
    ["106645", "970", 19],
    ["953", "106652", 14.422205101855956],
    ["955", "106652", 15.811388300841896],
    ["956", "106653", 13.0],
    ["958", "106653", 14.866068747318506],
    ["106640", "106647", 15.231546211727817],
    ["106641", "106647", 16.15549442140351],
    ["106642", "106648", 9.219544457292887],
    ["106643", "106648", 18.35755975068582],
    ["106644", "106649", 11.40175425099138],
    ["106645", "106649", 29.120439557122072],
    ["106640", "952", 17.72004514666935],
    ["952", "106640", 17],
    ["106640", "106641", 29.0],
    ["106641", "106640", 29],
    ["106642", "106641", 22.0],
    ["106641", "106642", 22],
    ["106642", "106643", 18.0],
    ["106643", "106642", 18],
    ["106644", "106643", 29.0],
    ["106643", "106644", 29],
    ["106644", "106645", 35.014282800023196],
    ["106645", "106644", 35],
    ["265", "923", 20],
    ["923", "921", 24.515301344262525],
    ["921", "923", 24],
    ["922", "921", 20.615528128088304],
    ["920", "1085", 1],
    ["920", "920", 1],
    ["262", "106656", 15.033296378372908],
    ["106656", "262", 15],
    ["978", "980", 1],
    ["978", "979", 1],
    ["978", "978", 1],
    ["1096", "106656", 21.0],
    ["106656", "1096", 21],
    ["262", "925", 16.0],
    ["925", "262", 16],
    ["268", "925", 11.0],
    ["925", "268", 11],
    ["268", "265", 12.165525060596439],
    ["921", "922", 20],
    ["922", "924", 24.020824298928627],
    ["924", "922", 24],
    ["926", "924", 25.495097567963924],
    ["265", "268", 12],
    ["976", "926", 30],
    ["977", "976", 25.0],
    ["976", "977", 25],
    ["924", "926", 25],
    ["923", "265", 20.024984394500787],
    ["264", "263", 25.96150997149434],
    ["263", "264", 25],
    ["977", "263", 29.410882339705484],
    ["926", "976", 30.805843601498726],
    ["267", "266", 21.095023109728988],
    ["266", "267", 21],
    ["264", "266", 24.08318915758459],
    ["263", "977", 29],
    ["267", "106662", 18.027756377319946],
    ["266", "264", 24],
    ["106660", "978", 23.194827009486403],
    ["978", "106660", 23],
    ["106660", "106659", 22.20360331117452],
    ["106659", "106660", 22],
    ["106658", "106659", 18.0],
    ["106659", "106658", 18],
    ["106658", "106657", 14.560219778561036],
    ["106657", "106658", 14],
    ["955", "956", 16.1245154965971],
    ["1094", "106655", 11.313708498984761],
    ["954", "106655", 12.806248474865697],
    ["956", "955", 16],
    ["106650", "920", 14.317821063276353],
    ["920", "106650", 14],
    ["1799", "1272", 27.65863337187866],
    ["1698", "1272", 13.892443989449804],
    ["977", "113305", 16.401219466856727],
    ["263", "113305", 15.033296378372908],
    ["970", "113306", 23.194827009486403],
    ["971", "113306", 28.284271247461902],
    ["1870", "1272", 19.209372712298546],
    ["273", "176", 1],
    ["273", "32362", 1],
    ["273", "1230", 1],
    ["273", "2168", 1],
    ["273", "99977", 1],
    ["273", "177", 1],
    ["958", "119972", 24.698178070456937],
    ["955", "119972", 34.66987164671943],
    ["1094", "119972", 37.44329045369811],
    ["106641", "119972", 37.64306044943742],
    ["119973", "2012", 13.601470508735444],
    ["1721", "126635", 9.219544457292887],
    ["126635", "1721", 9.219544457292887],
    ["126635", "1722", 38.05259518088089],
    ["126635", "1720", 23.259406699226016],
  ],
};
export default NAVIGATION;
