import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

// Teach Autosuggest how to calculate suggestions for any given input value.


function Contact(props) {
    const {t, i18n } = useTranslation();


  return (
    <div className="Contact" id={props.id}>
        <div className="ListContainer">
            <div>
                <h2>{t('contact.contact_header')}</h2>
                <h3>Złote Tarasy</h3>
                <p>ul. Złota 59</p>
                <p>00-120 Warszawa</p>
                <h3>{i18n.language === 'pl' ? 'Informacja' : 'INFO'}</h3>
                <p>+48 22 222 22 00</p>
                <p>+48 22 222 22 02</p>
                <p>info@zlotetarasy.pl</p>
            </div>
            <div>
                <h2>{t('contact.opening_hours_header')}</h2>
                
                <p>{i18n.language === 'pl' ? 'poniedziałek - sobota: 9:00 - 22:00' : 'Monday - Saturday: 9:00 am - 10:00 pm'}</p>
                <p>{i18n.language === 'pl' ? 'niedziela: 9:00 - 21:00' : 'Sunday: 9:00 - 21:00'}</p>
                
                <h3>Multikino</h3>
                <p>{i18n.language === 'pl' ? 'poniedziałek - niedziela: 9:00 - do ostatniego seansu' : 'Monday - Sunday: 9:00 a.m. - until the last screening'}</p>
                <h3>Calypso Fitness Club</h3>
                <p>{i18n.language === 'pl' ? 'poniedziałek - piątek: 5:00 - 24:00' : 'Monday - Friday: 5:00 am - midnight'}</p>
                <p>{i18n.language === 'pl' ? 'sobota - niedziela: 8:00 - 22:00' : 'Saturday - Sunday: 8:00 - 22:00'}</p>
            </div>
        </div>
        
    </div>
  );
}

export default Contact;
