import React, { useState, useEffect } from 'react';
import Icon from '@mdi/react';
import {
  mdiWalletGiftcard,
  mdiCalendarCheck,
  
  mdiMapMarkerMultiple,
  mdiParking,
  mdiShopping,
  mdiCellphoneIphone,
  
 } from '@mdi/js';
import './App.css';
import MobileApp from './components/MobileApp';
import Iframe from './components/Iframe';
import Back from './components/Back';
import ShopList from './components/ShopList';
import MapNavigation from './components/MapNavigationList';
import OfferList from './components/OfferList';
import OfferListApi from './components/OfferListApi';
import Contact from './components/Contact';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';



function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
function App() {


  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    renderModule: null,
    showModule: false,
    shop_id: null
  });
  const [lastTouch, setLastTouch] = useState(null);
  const [showSaver, setShowSaver] = useState(true);
  const testLoyalty = getParameterByName('loyalty')
  const useApi = getParameterByName('useapi')

  useEffect(() => {
    
    const interval = setInterval(() => {
      checkIfClose();
    }, 3000);
    return () => clearInterval(interval);
  }, [lastTouch]);

  function hideModule() {
    setState({renderModule: null, showModule:false});
  }

  function openNavigationShop(shop_id){
    setState({renderModule: 'MapNavigation', showModule: true, shop_id: shop_id});

  }
  function chooseModule(moduleName) {
    if(moduleName){
      setState({renderModule: moduleName, showModule: true})
    }else{
      setState({renderModule: null, showModule: false})
    }
  } 
  function renderModule(moduleName) {
 
    switch(moduleName){
      case 'Promotions':
        if (useApi) {
          return <OfferListApi type={'promotions'} id="Promotions"/>
        }else{
          return <OfferList type={'promotions'} id="Promotions"/>
        }
        
        
      case 'Events':
        if (useApi) {
          return <OfferListApi type={'events'} id="Events"/>
        }else{
          return <OfferList type={'events'} id="Events"/>
        }
        
      case 'MobileApp':
        return <MobileApp/>;
      case 'MapNavigation':
        if(state.shop_id){
          
          return <MapNavigation shop_id={state.shop_id} hideModule={hideModule}/>;
        }else{
          return <MapNavigation hideModule={hideModule}/>;
        }
      case 'Contact':
        return <Contact hideModule={hideModule}/>
      case 'ShopList':
        return <ShopList openNavigationShop={openNavigationShop} hideModule={hideModule}/>
      case 'ParkingEN':
        return <Iframe url={'https://zlotetarasy.pl/en/parking-app'} id="ParkingENFrame"/>
      case 'AboutEN':
        return <Iframe url={'https://zlotetarasy.pl/en/o-nas-app'} id="AboutENFrame"/>
      case 'Parking':
        return <Iframe url={'https://zlotetarasy.pl/parking-app'} id="ParkingFrame"/>
      case 'About':
        return <Iframe url={'https://zlotetarasy.pl/o-nas-app'} id="AboutFrame"/>
      case 'Capsule':
          return <Iframe url={'https://www.kapsulamody.pl/'}/>
      default:
          return null
    }
  }
  
  

  function checkIfClose() {

    let current = new Date().getTime() / 1000;
    if(lastTouch && state.renderModule ){
      if(current - lastTouch > 30 ){
        chooseModule(null)
      }
    }
    if(lastTouch){
      if(current - lastTouch > 120 ){
        setShowSaver(true);
      }
    }
    if(!lastTouch){
      setShowSaver(true);
    }
    
  }
  function clickPrevent(e) {
    e.preventDefault();
    setLastTouch(new Date().getTime() / 1000);
    
    setShowSaver(false);
  }
  // function touch(e){
  //   setLastTouch(new Date().getTime() / 1000);
  // }
 
  return (
    <div className="App" onClick={clickPrevent}>
      {showSaver ? <div className="saver"></div> : null}
      <div className="Row">
        <div className="IconWrapper">
          <div id="MapNavigationButton" className="InnerIcon ripple" onClick={()=>{chooseModule('MapNavigation')}}>
            <Icon path={mdiMapMarkerMultiple}
              title="User Profile"
              size={2.4}
              color="white"/>
              <p>{t('menu.navigation')}</p>
            </div>
        </div>
        <div className="IconWrapper">
          <div id="AboutButton" className="InnerIcon ripple" onClick={()=>{
              chooseModule('Contact');
            // if(i18next.language === "en"){
            //   chooseModule('AboutEN')
            // }else{
            //   chooseModule('About')
            // }
              }}>
              <div className="IconImage">
                <img src="/static/zlote-logo-ikona.png" alt="logo"/>
              </div>
              <p>{t('menu.opening_hours')}</p>
            </div>
        </div>
      </div>
      <div className="Row">
        <div className="IconWrapper">
          <div id="EventsButton" className="InnerIcon ripple" onClick={()=>{chooseModule('Events')}}>
            <Icon path={mdiCalendarCheck}
              title="User Profile"
              size={2.4}
              color="white"/>
              <p>{t('menu.events')}</p>
            </div>
        </div>
        <div className="IconWrapper">
          <div id="ShopListButton" className="InnerIcon ripple" onClick={()=>{chooseModule('ShopList')}}>
            <Icon path={mdiShopping}
              title="User Profile"
              size={2.4}
              color="white"/>
              <p>{t('menu.shops')}</p>
            </div>
        </div>
      </div>
      <div className="Row">
      <div className="IconWrapper">
          <div id="PromotionsButton" className="InnerIcon ripple" onClick={()=>{chooseModule('Promotions')}}>
            <Icon path={mdiWalletGiftcard}
              title="User Profile"
              size={2.4}
              color="white"/>
              <p>{t('menu.promotions')}</p>
            </div>
        </div>
        <div className="IconWrapper">
          <div id="ParkingButton" className="InnerIcon ripple" onClick={()=>{
            if(i18next.language === "en"){
              chooseModule('ParkingEN')
            }else{
              chooseModule('Parking')
            }}}>
            <Icon path={mdiParking}
              title="Parking"
              size={2.4}
              color="white"/>
              <p>{t('menu.parking')}</p>
            </div>
        </div>
      </div>
      
      <div className="Row">
      {/*
          <div className="MainInfoWrapper">
              <div class="InnerInfo">
                <p>{t('menu.corona')}</p>
                </div>
          </div>
           */}
      {testLoyalty ?
      <div className="IconWrapper">
       
          <div className="InnerIcon ripple" onClick={()=>{chooseModule('MobileApp')}}>
            <Icon path={mdiCellphoneIphone}
              title="User Profile"
              size={2}
              color="white"
              />
              <p>{t('menu.loyalty')}</p>
            </div>
        </div> : null}
        
        <div className="IconWrapper">
    {/*
          <div className="InnerIcon ripple"  onClick={()=>{chooseModule('Capsule')}}>
            <div className="IconImage">
                <img src="/static/kapsula.png" />
              </div>
              <p>{t('menu.capsule')}</p>
            </div>
        */}
        </div>
       
       
      </div>
      <div className="SmallRow">
        <div className="FlagWrapper">
          <div id="plFlagButton" className="plFlag" onClick={()=>i18n.changeLanguage('pl')}></div>
          <div id="enFlagButton" className="enFlag" onClick={()=>i18n.changeLanguage('en')}></div>
        </div>
      </div>
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "MapNavigation" ?  "show": "")}>
        {renderModule("MapNavigation")}
      </div>
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "Contact" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackContact"/>
        {renderModule("Contact")}
      </div>
      {/* <div className={"ModuleContainer " + (state.showModule && state.renderModule === "AboutEN" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackAboutEn"/>
        {renderModule("AboutEN")}
      </div> */}
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "Events" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackEvents"/>
        {renderModule("Events")}
      </div>
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "ShopList" ?  "show": "")}>
        {renderModule("ShopList")}
      </div>
      {/*
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "Capsule" ?  "show": "")}>
        <Back onBack={() => hideModule()}/>
        {renderModule("Capsule")}
      </div>
      */}
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "Parking" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackParking"/>
        {renderModule("Parking")}
      </div>
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "ParkingEN" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackParkingEn"/>
        {renderModule("ParkingEN")}
      </div>
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "Promotions" ?  "show": "")}>
        <Back onBack={() => hideModule()} id="BackPromotions"/>
        {renderModule("Promotions")}
      </div>
      {testLoyalty ?
      <div className={"ModuleContainer " + (state.showModule && state.renderModule === "MobileApp" ?  "show": "")}>
        <Back onBack={() => hideModule()}/>
        {renderModule("MobileApp")}
      </div> : null}
    </div>
    
  );
}

export default App;
