import React from 'react';
import Swiper from 'react-id-swiper';
import { useTranslation } from 'react-i18next';
import './MobileApp.css';

function App() {
    const { i18n } = useTranslation();
    const params = {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
  return (
    <div className="MobileApp">
        <Swiper {...params}>
            <div>
                <img src={"/static/app_1" + (i18n.language === 'en' ? '_en' : '' ) + ".png"} alt="slide_1" className="FullScreenSlide"/>
            </div>
            <div>
                <img src={"/static/app_2" + (i18n.language === 'en' ? '_en' : '' ) + ".png"} alt="slide_2" className="FullScreenSlide"/>
            </div>
        </Swiper>
    </div>
  );
}

export default App;
