import React from 'react';
import './Iframe.css';

function Iframe(props) {
  return (
    <div className="Iframe" id={props.id}>
        <iframe src={props.url} className="frame" sandbox="allow-same-origin allow-scripts allow-top-navigation" title="iframe"></iframe>
    </div>
  );
}

export default Iframe;
