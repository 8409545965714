import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      contact: {
        contact_header: "Contact",
        opening_hours_header: "Opening hours"
      },
      alert: {
          login_error: "We are sorry, an error occured while logging in.",
          other_request: "Please, try again",
          play_services: "Play Services are not available on this device",
          canceled: "Login cancelled",
          unknown: "Unknown error",
          invalid: "The e-mail address or password are incorrect or the account has not been activated yet",
          register: "An error occured during registration or the provided e-mail is already in use",
          store_error: "We are sorry",
          store_error_info: "We cannot display information about the chosen store at the moment",
          no_fb: "We are sorry",
          no_fb_info: "The provided Facebook account cannot be used for registration. Please choose a different method."
      },
      common: {
          check_all: "Check all consents",
          collapse: "collapse",
          expand: "expand",
          login: "Login",
          logout: "Log out",
          next: "Next",
          password: "Password",
          email: "E-mail",
          register: "Register",
          save: "Save changes",
          skip: "skip",
          back: "Back",
          yes: "Yes",
          no: "No"
      },
      confirm_email: {
          activation: "Account activation",
          info: "An activation link has been sent to your email address. Activate your account to get the most out of your app."
      },
      discounts:{
          rewards: "Shopper's prizes",
          special: "Special discounts"
      },
      faq: {
          answer_1: "To get special discounts, log in to the Złote Tarasy application, go to the main menu, and click on the “Rewards and Discounts” icon. "
                  + "By selecting “Special Discounts”, you will be given access to current discounts. "
                  + "Choose one of them and go to the POS/store in which you can redeem the discount. "
                  + "After clicking on “Get Discount”, a code will be displayed on your phone screen. Show it to the cashier. ",
          answer_2: "Every logged in user of the Złote Tarasy application can scan receipts, exchange PLN for points, and subsequently get rewards. To get a prize, log in to the Złote Tarasy application, collect the appropriate number of points, then go to the main menu and click on the “Rewards and Discounts” icon. By selecting “Rewards for Points”, you will gain access to a gift catalog. After selecting one of them, go to the relevant POS/store. Click on “Receive Reward” and a code will be displayed on your phone screen. Show it to the cashier. After receiving the reward, the user is obliged to enter the assigned ID number (available in “My Profile” tab) into the prize collection protocol.",
          answer_3: "Every logged in user of the Złote Tarasy application may use the parking code to enter and exit the parking garage. You don't have to watch your parking ticket anymore! To do this, log in to the Złote Tarasy application, go to the main menu, and click on the “P” icon. A QR code will appear on your phone screen. Scan it (at a distance of approx. 30 centimeters) next to the barrier. Then, the barrier will raise. Upon leaving, go to the parking ticket office and scan the same code. A parking fee will be charged based thereon.",
          answer_4: "Złote Tarasy Shopping Mall offers their customers free consultations with stylists and make-up artists. To get professional advice and sign up for a consultation, go to the main menu, click on the “Stylists & Make Up Artist” icon, and then select the person you want to consult. Having clicked on “Save” and selected the date and time of the consultation, upon entering your contact details, you will receive a confirmation to the specified email address. ",
          answer_5: "Registered and logged in users have access to special discounts in stores and POSs in Złote Tarasy Shopping Mall. Additionally, they can scan receipts, exchange spent PLN for points, and subsequently get exceptional prizes. ",
          answer_6: "The navigation function in Złote Tarasy application was designed to make it easier to move around the shopping mall. To use it, go to the main menu, click on the “Navigation” icon, enter the name of the POS/store you want to reach, press “Navigate”, and then enter the name of the POS/store you are currently at. Press “Navigate” again and the tool will show you the shortest way to the selected point. If the sought store is located on another floor, the navigation will direct you to the nearest elevator and show you the way on the right floor.",
          question_1: "How to benefit from special discounts?",
          question_2: "How to receive prizes?",
          question_3: "How to use the parking code?",
          question_4: "How to sign up for a style consultation?",
          question_5: "How do I benefit from logging in to the application?",
          question_6: "How do I navigate in the mall?",
      },
      home: {
          events: "Events",
          last_used: "Last used",
          news: "News",
          promotions: "Special offers",
          receive_reward: "Receive prizes"
      },
      interests: {
          info: "Please select the categories which you are interested in (max. 5), if you want to get the personalized offers",
          header: "Interests"
      },
      license_plates:{
          header: "License plates",
          info:  "Enter your license plate number and check your consents if you want to enter the parking  with the app.",
          regulations: "Złote Tarasy Shopping Mall Parking Terms and Conditions"
      },
      login: {
          administrator: "The personal data controller is Złote Tarasy Warsaw III S.á r.l. sp. j. with its registered office in Warsaw. More information on the processing of personal data can be found in the Terms of Use of the \"Złote Tarasy\" Application",
          app_regulations: "Terms of Use of the Złote Tarasy Application",
          check_all: "Check all",
          check_required_social: "Check the required consents and select the login method again",
          choose_different: "Select another login method",
          email_button: "Email / Password",
          forgotten_password: "Forgotten password",
          loyalty_regulations: "Terms and Conditions of the Złote Tarasy Shopping Mall Customer Loyalty Program",
          terms: "By using the application you accept its Terms of Use.",
          login_header: "Login",
          invalid_email: "Invalid e-mail",
          invalid_email_pass: "Invalid e-mail or password or the account has not been activated"
      },
      menu: {
          capsule: "CAPSULE OF FASHION MAGAZINE",
          cinema: "CINEMA",
          events: "EVENTS",
          navigation: "NAVIGATION",
          opening_hours: "OPENING HOURS",
          parking: "PARKING",
          rewards: "REWARDS AND DISCOUNTS",
          settings: "SETTINGS",
          shops: "STORES",
          stylists: "STYLISTS AND MAKE UP ARTIST",
          game: "GOLDEN THOMAS GAME",
          loyalty: "LOYALTY PROGRAM",
          mobile: "MOBILE APP",
          promotions: "PROMOTIONS",
          corona: "From March 14, 2020 to March 28, 2020, only grocery stores, drugstores, pharmacy and laundry will be opened (the list above may change). "
          + "Hard Rock Cafe will process only online orders. On Sunday ban, the Mall is closed. "
          + "Opening hours of the shopping center remain unchanged."
      },
      navigation:{
          navigate: "Navigate",
          where: "Enter where you are",
          where_input: "Enter the store's name",
          search: "Find a store",
          go_to: "Go to level",
          go_to_up: "Go to level",
          open_store: "Open store"
      },
      parking: {
          info: "No more lost parking tickets. Enter, pay, and leave using the parking code",
          login: "LOG IN TO USE A PARKING CODE!",
          add_plates: "To use the parking code, add your license plates in the settings",
          go_to_settings: "Go to settings"
      },
      password: {
          min_length: "Your password needs to be at least 6 characters long",
          new_password: "New password",
          no_match: "Passwords do not match",
          repeat_password: "Repeat the password"
      },
      profile: {
          birth_info: "Specify if you want to receive special discounts or gifts on your birthday.",
          delete_account: "Delete account",
          expand: "Press to expand",
          invalid_date: "Invalid date",
          name_info: "Fill in if you want to receive prizes in the Złote Tarasy Loyalty Program. We remind you that by registering, you agreed to join the loyalty program. You can find the Terms and Conditions of the Loyalty Program here.",
          enter_name: "Name and surname",
          male: "Male",
          female: "Female",
          sex: "Sex",
          birth_date: "Birth date",
          agreements: "Consents",
          language: "Language",
          name_surname: "Name and surname",
          account_info: "Account info",
          email: "E-mail"
      },
      settings: {
          app_regulations: "Terms of Use of the Złote Tarasy Application",
          change_password: "Change password",
          clear_data: "Clear application data",
          interests: "Your interests",
          loyalty_regulations: "Terms and Conditions of the Loyalty Program",
          other: "Other",
          parking: "Parking",
          parking_regulations: "Parking Garage Terms and Conditions",
          profile: "Your data and consents",
          stylists_regulations: "Terms and Conditions of Styling Services",
          user_settings: "User Settings"
      },
      shop: {
          floor: 'floor',
          venue: 'venue',
          map: 'View on map',
          opening_hours: 'Opening hours',
          contact: 'Contact',
          find: 'Find a store'
      },
      special_login: {
          discounts: "Get discounts!",
          parking: "Park comfortably!",
          rewards: "Receive prizes",
          register_and: "Register and"
      },
      stylists: {
          consultation: 'Stylist consultation',
          no_consultations: 'Unfortunately, there are no free consultations at the moment. We will add new consultations soon. Please feel invited.',
          stylist: 'Stylist',
          make_up: 'Make-up artist',
          register: 'Sign up for a free consultation',
          sign_up: 'Sign up',
          fill_form: 'Please fill out the form',
          about_me: "About me",
          name: "Name",
          email: "E-mail",
          year: "Year of birth",
          phone: "Phone",
          marta_1: "A certified personal development coach and personal shopper.",
          marta_2: "For a year now, she has been forming the team of Złote Tarasy stylists.",
          marta_3: "She loves sports, fashion and being around people. She is a fan of sequins and openly admits to being addicted to adrenaline, sales in Zara, and Michałki candy. A ball of energy and a sophisticated fashionista in one. She will gladly help you with casual daily outfits, unique party stylings, as well as full-blown metamorphoses.",
          marta_4: "She worked with: TARSAGO Polska Sp. z o.o., Reserved, Huzior, New Look, Master Look, GO POLSKA, Studio Bajka.",
          asia_1: "A personal shopper and style metamorphosis specialist. She gained her knowledge of fashion and style at the prestigious London College of Style, and her experience at the London FashionWeek backstage. She helps individuals increase their confidence and attractiveness for the opposite sex, and assists corporate representatives in building more effective visual communication. Styling men is her forté.",
          asia_2: "She worked with: Pytanie na Śniadanie, COTY, Mary Kay, Perfetto360, Remington.",
          martaz_1: "A business image creator and personal shopper. She believes that clothes are a form of self-expression. While creating an image, she puts emphasis on her client's personality and character of work so that their look is consistent with their internal “self”. She claims that the success of her work can be measured in the number of compliments her client hears after going shopping with her. If you want to follow the dress code, but also be true to your personality, or if you are looking for something edgy, or simply you appreciate the sophisticated smart casual, Marta is at your disposal.",
          martaz_2: "She worked with: TVN S.A., Pytanie na Śniadanie, TVN Style, Top Secret, Troll, Cubus, T.K. Max.",
          piotr_1: "A make-up artist present on the beauty market for 15 years.",
          piotr_2: "He values skin make-up the most. As he claims, it’s the skin look, structure, and condition that gives the make-up its final touch.",
          piotr_3: "If you want to finish your look with a perfect make-up, and you do not know how to match beauty products to your skin, or you just want to learn how to do everyday and non-everyday make-up, come and consult Piotr.",
          piotr_4: "He worked with: Armani Cosmetics, Inglot, Mac Cosmetics.",
          header: "Stylists & Make-up Artist",
          info_1: "A relaunch of Złote Tarasy stylist team brings new faces and new services. The project is designed for entrepreneurs as well as active, fashion-forward people. This year, apart from new stylists, we also give you a new service: PROFESSIONAL MAKE-UP ARTIST CONSULTATION. "
          + "Our stylists will make sure that your outfit is in synergy with your personality, and that the selected clothes and accessories not only follow the most up-to-date fashion trends, but also match your posture and preference. Each stylist specializes in a very narrow domain, which will make their services even more tailored to our customers’ needs.",
          info_2: "This year, you will have the opportunity to go shopping with:",
          info_3: "Marta Zalega, a stylist dedicated to broadly understood business and smart casual look. "
          + "Asia Lelejko, SO SPECIAL stylist focused on creating the image of men and singles. "
          + "Marta Pożarlik, our full-time crazy fashionista known as PANI OD WIZERUNKU, who we probably don’t have to introduce to you. ",
          info_4: "Your skin will be flawless, and your make-up will match every occasion thanks to NAMALOWANY, that is Piotr Chmielewski. He will help you choose the best foundation, lipstick and eye shadow matching your skin complexion. He will also show you how to perfectly contour your face and highlight your natural beauty.",
          
      },
      mobile: {
          scan: 'Scan the QRCode to get the Złote Tarasy mobile app'
      }
    }
  },
  pl: {
    translation: {
      contact: {
        contact_header: "Kontakt",
        opening_hours_header: "Godziny otwarcia",
      },
      alert: {
          login_error: "Przepraszamy, wystąpił błąd przy próbie logowania.",
          other_request: "Spróbuj zalogować się ponownie",
          play_services: "Play Services nie są dostępne na tym urządzeniu",
          canceled: "Anulowano logowanie",
          unknown: "Nieznany błąd",
          invalid: "Podany e-mail lub hasło są niepoprawne lub konto nie zostało aktywowane.",
          register: "Wystąpił błąd podczas rejestracji lub podany e-mail jest już w użyciu",
          store_error: "Przepraszamy",
          store_error_info: "Chwilowo nie możemy wyświetlić informacji o wybranym sklepie",
          no_fb: "Przepraszamy",
          no_fb_info: "Nie możemy użyć podanego konta Facebook. Prosimy wybrać inną metodę."
      },
      common: {
          check_all: "ZAZNACZ WSZYSTKIE ZGODY",
          collapse: "zwiń",
          expand: "rozwiń",
          login: "Zaloguj",
          logout: "Wyloguj",
          next: "Dalej",
          password: "Hasło",
          email: "E-mail",
          register: "ZAREJESTRUJ SIĘ",
          save: "ZAPISZ",
          skip: "pomiń",
          back: "Wróć",
          yes: "Tak",
          no: "Nie"
      },
      confirm_email: {
          activation: "Aktywacja konta",
          info: "Na Twój adres email został wysłany link aktywacyjny. Aktywuj konto, aby móc korzystać z pełni możliwości aplikacji."
      },
      discounts:{
          rewards: "Nagrody za zakupy",
          special: "Specjalne zniżki"
      },
      faq: {
          answer_1: "Aby skorzystać ze zniżek specjalnych, należy zalogować się w aplikacji Złote Tarasy, przejść do menu głównego i kliknąć w ikonę Nagrody i Rabaty. "
                    + "Wybierając opcję Specjalne Zniżki, użytkownik otrzyma dostęp do aktualnych rabatów. "
                    + "Po wybraniu jednego z nich, należy udać się do punktu/sklepu, którego rabat dotyczy. "
                    + "Po kliknięciu w Odbierz rabat na ekranie telefonu użytkownika wyświetli się kod, który należy pokazać kasjerowi celem jego weryfikacji.  ",
          answer_2: "Każdy zalogowany użytkownik aplikacji Złote Tarasy ma możliwość skanowania paragonów, wymiany złotówek na punkty, a następnie na nagrody. Aby zdobyć nagrodę, należy zalogować się w aplikacji Złote Tarasy, zebrać odpowiednią liczbę punktów upoważniających do jej odbioru, a następnie przejść do menu głównego i kliknąć w ikonę Nagrody i Rabaty. Wybierając opcję Nagrody za punkty, użytkownik otrzyma dostęp do katalogu upominków. Po wybraniu jednego z nich, należy udać się do punktu/sklepu, którego on dotyczy. Po kliknięciu w Odbierz nagrodę na ekranie telefonu użytkownika wyświetli się kod, który należy pokazać kasjerowi celem jego weryfikacji. Po odbiorze nagrody obowiązkiem użytkownika jest wpisanie przypisanego mu numeru ID (dostępny w zakładce Mój Profil) do protokołu odbioru nagrody.",
          answer_3: "Każdy zalogowany użytkownik aplikacji Złote Tarasy ma możliwość skorzystania z kodu parkingowego, ułatwiającego wjazdy i wyjazdy. Już nie musisz pilnować biletu parkingowego! Aby to zrobić należy zalogować się do aplikacji Złote Tarasy, przejść do menu głównego i kliknąć w ikonę oznaczoną literą P. Na telefonie pojawi się kod QR, który należy przyłożyć do skanera (w odległości ok. 30 centymetrów) znajdującego się przy szlabanie. Po wykonanej czynności szlaban podniesie się. Po zakończonej wizycie, Użytkownik powinien udać się do kasy parkingowej i przyłożyć ten sam kod do skanera przy kasie. Na tej podstawie zostanie naliczona opłata parkingowa.",
          answer_4: "Złote Tarasy oferują swoim klientom bezpłatne konsultacje ze stylistkami oraz wizażystą. Aby skorzystać z porad profesjonalistów i zapisać się na konsultację, należy przejść do menu głównego, kliknąć w ikonę Styliści i Wizażysta, a następnie wybrać osobę, z którą użytkownik chce odbyć konsultacje. Po wybraniu opcji Zapisz się i wybraniu daty i godziny spotkania, a następnie wpisaniu danych kontaktowych, użytkownik otrzyma potwierdzenie zapisu na wskazany adres e-mail. ",
          answer_5: "Zarejestrowany i zalogowany użytkownik aplikacji ma dostęp do specjalnych zniżek obowiązujących w sklepach i punktach usługowych na terenie Złotych Tarasów. Dodatkowo ma możliwość skanowania paragonów za zakupy w centrum handlowym, wymiany wydanych złotówek na punkty, a następnie na wyjątkowe nagrody. ",
          answer_6: "Funkcja nawigacji w aplikacji Złote Tarasy ma za zadanie ułatwić poruszanie się po centrum handlowym. Aby z niej skorzystać należy przejść do menu głównego, kliknąć w ikonę Nawigacja, wpisać nazwę punktu/lokalu, do którego użytkownik chce dotrzeć, wcisnąć przycisk Nawiguj, a następnie podać nazwę punktu/lokalu, przy którym użytkownik obecnie się znajduje. Po ponownej komendzie Nawiguj, narzędzie wskaże użytkownikowi najkrótszą drogę do wybranego punktu. W przypadku, gdy lokal znajduje się na innym piętrze, nawigacja skieruje użytkownika do najbliższej windy, a następnie pokaże trasę na piętrze, na którym znajduje się poszukiwany lokal.",
          question_1: "Jak korzystać ze zniżek specjalnych?",
          question_2: "Jak odbierać nagrody?",
          question_3: "Jak korzystać z kodu parkingowego?",
          question_4: "Jak zapisać się na konsultację stylistyczną?",
          question_5: "Co daje mi zalogowanie do aplikacji?",
          question_6: "Jak korzystać z nawigacji po centrum?",
      },
      home: {
          events: "Wydarzenia",
          last_used: "Ostatnio używane",
          news: "Aktualności",
          promotions: "Promocje",
          receive_reward: "Odbierz nagrodę"
      },
      interests: {
          info: "Podaj, jeśli chcesz otrzymywać spersonalizowane oferty (max. 5)",
          header: "Zainteresowania"
      },
      license_plates:{
          header: "Tablica rejestracyjna",
          info:  "Podaj numery tablic i zaznacz zgody, jeśli chcesz wjeżdzać na parking korzystając z aplikacji.",
          regulations: "Regulamin parkingu Centrum Handlowego Złote Tarasy"
      },
      login: {
          administrator: "Administratorem Twoich danych osobowych jest Złote Tarasy Warsaw III S.á r.l. sp. j. z siedzibą w Warszawie. Więcej informacji na temat przetwarzania danych osobowych znajdziesz w Regulaminie korzystania z aplikacji „Złote Tarasy”",
          app_regulations: "Regulamin aplikacji Złote Tarasy",
          check_all: "Zaznacz wszystko",
          check_required_social: "Zaznacz wymagane zgody i kliknij ponownie w wybraną metodę logowania",
          choose_different: "Wybierz inną metodę logowania",
          email_button: "E-mail / Hasło",
          forgotten_passworrd: "Zapomniałem hasła",
          loyalty_regulations: "Regulamin programu lojalnościowego Centrum Handlowego Złote Tarasy",
          terms: "Korzystając z aplikacji akceptujesz postanowienia regulaminu",
          login_header: "Zaloguj się",
          invalid_email: "Niepoprawny e-mail",
          invalid_email_pass: "Niepoprawny e-mail lub hasło lub konto nie zostało aktywowane"
          
      },
      menu: {
          capsule: "MAGAZYN KAPSUŁA MODY",
          cinema: "KINO",
          events: "WYDARZENIA",
          navigation: "NAWIGACJA",
          opening_hours: "GODZINY OTWARCIA",
          parking: "PARKING",
          rewards: "NAGRODY I RABATY",
          settings: "USTAWIENIA",
          shops: "SKLEPY",
          stylists: "STYLIŚCI I WIZAŻYSTA",
          game: "GRA ZŁOTY TOMASZ",
          loyalty: "PROGRAM LOJALNOŚĆIOWY",
          mobile: "APLIKACJA MOBILNA",
          promotions: "PROMOCJE",
          corona: "Od dnia 14 marca 2020 do dnia 28 marca 2020 (z możliwością przedłużenia), otwarte będą: sklepy z art. spożywczymi, drogerie, apteka i pralnia (powyższa lista może ulec zmianie). "
          + "Lokale gastronomiczne będą nieczynne, a Hard Rock Cafe będzie realizować jedynie zamówienia online. W niedziele objęte zakazem handlu centrum jest zamknięte. "
          + "Godziny pracy Centrum pozostają bez zmian."
      
      },
      navigation:{
          navigate: "Nawiguj",
          where: "Wpisz gdzie jesteś",
          where_input: "Wpisz nazwę sklepu",
          search: "Znajdź sklep i nawiguj",
          go_to: "Zjedź na poziom",
          go_to_up: "Wjedź na poziom",
          open_store: "Przejdź do sklepu",
      },
      parking: {
          info: "Zapomij o zgubionym bilecie parkingowym. Wjeżdżaj, płać i wyjeżdżaj korzystając z kodu parkingowego",
          login: "ZALOGUJ SIĘ ABY KORZYSTAĆ Z KODU PARKINGOWEGO!",
          add_plates: "Aby skorzystać z kodu parkingowego dodaj tablice rejestracyjne w ustawieniach",
          go_to_settings: "Przejdź do ustawień"
      },
      password: {
          min_length: "Hasło musi mieć co najmniej 6 znaków",
          new_password: "Nowe hasło",
          no_match: "Hasła się nie zgadzają",
          repeat_password: "Powtórz hasło"
      },
      profile: {
          birth_info: "Podaj jeśli chcesz otrzymać specjalne rabaty lub prezenty w dniu swoich urodzin.",
          delete_account: "Usuń konto",
          expand: "Naciśnij, aby rozwinąć",
          invalid_date: "Niepoprawna data",
          name_info: "Wypełnij jeśli chcesz odbierać nagrody w programie lojalnościowym Złotych Tarasów. Przypominamy że rejestrując się wyraziłeś zgodę na uczestnictwo w programie lojalnościowym. Regulamin programu lojalnościowego znajdziesz tutaj.",
          enter_name: "Wpisz imię i nazwisko",
          male: "Mężczyzna",
          female: "Kobieta",
          sex: "Płeć",
          birth_date: "Data urodzenia",
          agreements: "Zgody",
          language: "Język",
          name_surname: "Imię i nazwisko",
          account_info: "Dane konta",
          email: "Adres e-mail"
      },
      settings: {
          app_regulations: "Regulamin aplikacji Złote Tarasy",
          change_password: "Zmień hasło",
          clear_data: "Wyczyść dane aplikacji",
          interests: "Twoje zainteresowania",
          loyalty_regulations: "Regulamin programu lojalnościowego",
          other: "Pozostałe",
          parking: "Parkowanie",
          parking_regulations: "Regulamin parkingu",
          profile: "Twoje dane i zgody",
          stylists_regulations: "Regulamin usługi stylistów",
          user_settings: "Ustawienia użytkownika"
      },
      shop: {
          floor: 'poziom',
          venue: 'lokal',
          map: 'Zobacz na mapie',
          opening_hours: 'Godziny otwarca',
          contact: 'Dane kontaktowe',
          find: 'Znajdź sklep'
      },
      special_login: {
          discounts: "Łap rabaty",
          parking: "Parkuj wygodnie",
          rewards: "Odbieraj nagody",
          register_and: "Zarejestruj się i"
      },
      stylists: {
          consultation: 'Konsultacja stylistyczna',
          no_consultations: 'Niestety w tej chwili nie ma wolnyh terminów na konsultacje. Wkrótce dodane zostaną nowe terminy. Zapraszamy.',
          stylist: 'Stylistka',
          make_up: 'Wizażysta',
          sign_up: 'Zapisz się',
          fill_form: 'Uzupełnij formularz',
          register: 'Zapisz się na darmowe konsultacje',
          about_me: "O mnie",
          name: "Imię",
          email: "E-mail",
          year: "Rok urodzenia",
          phone: "Telefon",
          asia_1: "Personal shopper i specjalistka w metamorfozach stylu. Swoją wiedzę na temat mody i stylu zdobyła w prestiżowej szkole London College of Style, a doświadczenie szlifowała na backstage London FashionWeek. Pomaga klientom indywidualnym zwiększyć ich pewność siebie i atrakcyjność dla płci przeciwnej, a ludziom biznesu efektywniej budować komunikację wizualną. Jej szczególną specjalizacją jest stylizowanie mężczyzn.",
          asia_2:"Pracowała z markami: Pytanie na Śniadanie, COTY, Mary Kay, Perfetto360, Remington.",
          marta_1: "Dyplomowana trenerka rozwoju osobistego i personal shopper.",
          marta_2: "Od roku związana z serwisem stylistów w Złotych Tarasach.",
          marta_3: "Kocha sport, modę i kontakt z ludźmi. Uwielbia cekiny i otwarcie przyznaje się do uzależnienia od michałków, adrenaliny oraz wyprzedaży w Zarze. Wulkan energii, ale też wysublimowana Pani od wizerunku sprawdzi się gdy szukacie czegoś na co dzień, na imprezę, od święta a także gdy chcecie przeprowadzić całkowitą metamorfozę siebie.",
          marta_4: "Pracowała z markami: TARSAGO Polska Sp. z o.o., Reserved, Huzior, New Look, Master Look, GO POLSKA, Studio Bajka.",
          martaz_1: "Kreator wizerunku biznesowego i personalshopper. Uważa, że ubrania są formą wyrażania siebie. Budując wizerunek klientów stawia na osobowość i charakter pracy, tak by był on spójny z ich wewnętrznym “JA”. Twierdzi, że miarą jej pracy jest ilość komplementów jakie klientka otrzymuje po zakupach z nią. Jeśli chcesz być zgodny z dress code-m, ale też z własną osobowością, a także, gdy szukasz czegoś z pazurem bądź po prostu cenisz sobie wyrafinowany smart casual, Marta pozostaje do Twojej dyspozycji.",
          martaz_2: "Pracowała z markami: TVN S.A., Pytanie na Śniadanie, TVN Style, Top Secret, Troll, Cubus, T.K. Max.",
          piotr_1: "Wizażysta, od 15 lat silnie związany z rynkiem kosmetycznym.",
          piotr_2: "W makijażu najbardziej ceni sobie makijaż skóry, bo jak sam twierdzi – to właśnie jej wygląd, struktura oraz kondycja dają makijażowi ostateczny szlif.",
          piotr_3: "Jeśli chcesz wykończyć swój look idealnym make–up-em, a nie wiesz jak dobrać kosmetyki do swojej skóry, albo po prostu chcesz się dowiedzieć jak się malować na co dzień i od święta – zapraszamy na konsultacje z Piotrem.",
          piotr_4: "Współpracował z markami: Armani Cosmetics, Inglot, Mac Cosmetics.",
          header: "Styliści i wizażysta",
          info_1: "Nowa odsłona serwisu stylistów w Złotych Tarasach to nowe twarze i nowe usługi. Projekt powstał zarówno z myślą o klientach szeroko pojętego świata biznesu jak i osobach aktywnych, podążających za trendami. W tym roku usługa, poza nowymi stylistkami w zespole, została poszerzona o KONSULTACJĘ PROFESJONALNEGO WIZAŻYSTY.",
          info_2: "Styliści zadbają, aby Twój ubiór był spójny z Twoją osobowością. Dopasują, zgodne z najnowszymi trendami, stylizacje odpowiednie dla Twojej sylwetki oraz dobiorą odpowiednie dodatki i akcesoria. Każdy stylista to indywidualna specjalizacja modowa, która sprawi, że usługi będą jeszcze bardziej dopasowane do potrzeb Klienta. "
          + "W tym roku, będziecie mieli okazję wybrać się na wspólne zakupy z:",
          info_3: "Martą Zalega – stylistką dedykowaną szeroko pojętemu biznesowi i smart casual. "
          + "Asią Lelejko – SO SPECIAL stylist, skupioną na kreowaniu wizerunku mężczyzn, singli i singielek. "
          + "Niezmiennie – z naszą postrzeloną PANIĄ OD WIZERUNKU – Martą Pożarlik, której właściwie… Przedstawiać Wam nie musimy",
          info_4: "O nieskazitelny stan Twojej cery i makijaż skrojony na miarę każdej okoliczności, zadba NAMALOWANY – Piotr Chmielewski. Dobierze najlepszy dla Twojej skóry podkład, pomadkę, cień… Pokaże jak idealnie wykonturować twarz i zdradzi sekret naturalnego piękna.",
  
      },
      mobile: {
          scan: 'Zeskanuj poniższy kod, aby pobrać Aplikacje Złote Tarasy'
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pl"
  });

  export default i18n;
