import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import Floor from "./FloorList";
import { mdiClose } from "@mdi/js";
import Autosuggest from "react-autosuggest";
import Back from "./Back";
import Svg, { Path } from "react-native-svg";
import NAVIGATION from "./constants/navinew";
import "./MapNavigation.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { dijkstra } from "./utils";
import NEW_SHOPS from "./constants/newshops";
import SHOP_CATEGORIES from "./constants/shops_categories";

// import { TextInput } from 'react-native-ui-lib';

const shops = NEW_SHOPS.results;
const ztShops = SHOP_CATEGORIES.results;
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Main Map Navigation Component
 */
function MapNavigation(props) {
  const keyboardInput = useRef();
  const { t } = useTranslation();
  const [current_floor_state, setCurrentFloor] = useState(null);
  const [navi_paths_state, setNaviPaths] = useState(null);
  const [navigation_path_state, setNavigationPath] = useState(null);
  const [next_index_state, setNextIndex] = useState(null);
  const [marker_state, setMarker] = useState(null);
  const [floor_marker_state, setFloorMarker] = useState(null);
  const [pressed_item_state, setPressedItem] = useState(null);
  const [active_item_state, setActiveItem] = useState(null);
  const [from_item_state, setFromItem] = useState(null);
  const [suggestions_state, setSuggestions] = useState([]);
  const [suggestion_state, setSuggestion] = useState("");
  const [show_shop_state, setShopShow] = useState(false);
  const [wf_marker_state, setWfMarker] = useState(null);
  const [show_keyboard_state, setShowKeyboard] = useState(null);

  const wfName = getParameterByName("wf_name");
  const softKeyboard = getParameterByName("soft_keyboard");

  const graphData = NAVIGATION;

  graphData.nid2id = {};
  graphData.node = {};

  graphData.nodes.forEach((node) => {
    if (node.nid) {
      graphData.nid2id[node.nid] = node.id;
    }
    graphData.node[node.id] = node;
  });
  function unEntity(str) {
    return str
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }
  function hideModule() {
    // console.log(marker_state);
    //setCurrentFloor(marker_state.z+2)
    if (softKeyboard) {
      setShowKeyboard(false);
    }

    clearNavigation();
    setSuggestion("");
    props.hideModule();
  }

  function changeFloor(floor) {
    clearNavigation();
    setCurrentFloor(floor);
  }
  function getWfMarker() {
    let res = findPathByNids(
      getParameterByName("wf_name"),
      getParameterByName("wf_name")
    );
    return res[0];
  }
  function getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let results = [];
    if (inputLength !== 0) {
      for (const [, v] of Object.entries(ztShops)) {
        for (const [, v2] of Object.entries(v.shops)) {
          if (v2.shop_title.toLowerCase().indexOf(inputValue) >= 0) {
            results.push({ category: v.category, ...v2 });
          }
        }
      }
    }

    return results;
  }
  function getSuggestionValue(suggestion) {
    return suggestion.shop_title;
  }

  function onSuggestionsFetchRequested({ value }) {
    setSuggestions(getSuggestions(value));
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onChange(event) {
    if (event.target.value) {
      setSuggestions(getSuggestions(event.target.value));
      setSuggestion(event.target.value);
      setPressedItem(null);
    } else {
      setSuggestions([]);
      setSuggestion("");
    }
  }

  function onChangeKeyboard(input) {
    setSuggestions(getSuggestions(input));
    setSuggestion(input);
    setPressedItem(null);
  }
  /**
   * sets next navigation path to be displayed
   */
  function navigateNextPath(index) {
    setNextIndex(index + 1);

    let currentPath = navi_paths_state[index];
    setCurrentFloor(currentPath.floor);
    setMarker(currentPath.marker);
    setFloorMarker(null);

    currentPath.paths.forEach((p, ind) => {
      setTimeout(() => {
        setNavigationPath(p);
      }, 180 * ind);
      if (
        index < navi_paths_state.length - 1 &&
        ind === currentPath.paths.length - 1 &&
        navi_paths_state.length > 1
      ) {
        setTimeout(() => {
          setFloorMarker({
            x: currentPath.last.x,
            y: currentPath.last.y,
            nextFloor: navi_paths_state[index + 1].floor,
          });
        }, 180 * ind);
      }
    });
  }
  /**
   * sets new navigation path to be displted
   */

  useEffect(() => {
    if (!wf_marker_state) {
      let wfm = getWfMarker();

      setWfMarker(wfm);
      if (!current_floor_state) {
        setCurrentFloor(wfm.z);
      }
    }

    if (navi_paths_state && !next_index_state) {
      navigateNextPath(0);
    }
    if (props.shop_id) {
      pressShop(props.shop_id);
    }
  }, [
    navigateNextPath,
    pressShop,
    props.shop_id,
    current_floor_state,
    navi_paths_state,
    next_index_state,
    wf_marker_state,
  ]);
  /**
   * asks the wayfinder api to provide  navigation route
   */
  function findPath(wf_name, item) {
    setPressedItem(null);
    setActiveItem(null);
    let responseJson = findPathByNids(wf_name, item.shop_id);
    if (responseJson.length <= 0) {
      return;
    }

    let singlePath = {};
    let naviPaths = [];
    let partPath = "";
    responseJson.forEach((element, index) => {
      if (index === 0) {
        singlePath = {
          marker: element,
          floor: String(parseInt(element.z)),
          paths: [],
        };
        //this.setState({marker: element})
        partPath = "M " + element.x + " " + element.y;
        singlePath.paths.push(partPath);
        //this.setState({navigationPath: partPath})
        //this.setState({currentFloor: String(parseInt(element.z)+2)})
      } else {
        if (responseJson[index - 1].z !== element.z) {
          singlePath.last = {
            x: responseJson[index - 1].x,
            y: responseJson[index - 1].y,
            floor: responseJson[index - 1].z,
          };
          if (singlePath.paths.length > 1) {
            naviPaths.push(singlePath);
          }

          singlePath = {
            marker: element,
            floor: String(parseInt(element.z)),
            paths: [],
          };
          //this.setState({marker: element})
          partPath = "M " + element.x + " " + element.y;
          singlePath.paths.push(partPath);
        } else if (index === responseJson.length - 1) {
          partPath += " L " + element.x + " " + element.y;
          singlePath.paths.push(partPath);
          naviPaths.push(singlePath);
        } else {
          partPath += " L " + element.x + " " + element.y;
          singlePath.paths.push(partPath);
        }
        //this.setNewPath(element, index, responseJson[index-1].z);
      }
    });

    setNaviPaths(naviPaths);
    //navigateNextPath(0);
  }

  /**
   * sets starting point from navigation
   */
  function setFrom(item) {
    if (!item) {
      setFromItem(null);
    } else if (item.floor !== current_floor_state) {
      setCurrentFloor(item.floor);
      setFromItem(item);
    }
    setFromItem(item);
  }
  /**
   * sets the overlay color on the store
   */
  function showOverlay(item) {
    if (!item) {
      setActiveItem(null);
    } else if (item.floor !== current_floor_state) {
      setCurrentFloor(item.floor);
      setActiveItem(item);
    }

    setNavigationPath(null);
    setActiveItem(item);
  }

  function renderNavigation() {
    return (
      <div className="NaviPath">
        <Svg height={980} width={1080} viewBox="0 0 1080 1080">
          <Path
            fillOpacity="0"
            d={navigation_path_state}
            stroke="black"
            y="-80"
            strokeWidth="15"
            strokeDasharray="15"
          />
        </Svg>
      </div>
    );
  }

  function pressShop(nid) {
    let s = null;

    if (pressed_item_state && pressed_item_state.shop_id !== nid) {
      try {
        let responseJson = findPathByNids(nid, nid);
        //console.log(responseJson)
        if (responseJson) {
          let shop_id = null;

          let toShop = props.shops[props.currentFloor][shop_id];

          let found = false;
          for (const [key, value] of Object.entries(ztShops)) {
            for (const s in value.shops) {
              for (const sl in value.shops[s].shop_locals) {
                if (value.shops[s].shop_locals[sl][0] === shop_id) {
                  found = true;
                  //console.log({category: value.category, shop_id: shop_id, name:toShop.shop_name, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, ...value.shops[s]})
                  setPressedItem({
                    category: value.category,
                    shop_id: shop_id,
                    name: toShop.shop_name,
                    x: responseJson[0].x,
                    y: responseJson[0].y,
                    z: parseFloat(responseJson[0].z),
                    ...value.shops[s],
                  });
                  break;
                }
              }
              if (found === true) {
                break;
              }
            }
            if (found === true) {
              break;
            }
          }
          //     let cat = props
          //     let s = props.shops[x]
          //     if(s.number === shop_id){
          //         props.setPressedItem({category: toShop.category, shop_id: nid, name:toShop.title, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, ...s})
          //     break;
          //     }
          // }

          //props.setPressedItem({category: toShop.category, shop_id: nid, name:toShop.title, x: responseJson[0].x, y: responseJson[0].y, z: parseFloat(responseJson[0].z) + 2, number: nid})

          //props.pressStore(shop_id);
        }
      } catch (e) {
        // console.log(e);
        alert(t("alert.store_error_info"));
      }
    }
  }

  function goToShop() {
    setShopShow(true);
  }

  function closeShop() {
    setShopShow(false);
  }

  function onFocus() {
    setShowKeyboard(true);
  }

  function renderSuggestion(suggestion) {
    return <div>{suggestion.shop_title}</div>;
  }

  function clearNavigation() {
    setShopShow(false);
    setNaviPaths(null);
    setNavigationPath(null);
    setNextIndex(null);
    setMarker(null);
    setActiveItem(null);
    setPressedItem(null);
    setFloorMarker(null);
  }
  function findPathByNids(start, end) {
    let start_id = graphData.nid2id[start];
    let end_id = graphData.nid2id[end];

    if (start_id && end_id) {
      let route = [];
      let [path, length] = dijkstra(graphData.graph, start_id, end_id);

      path.forEach((node_id) => {
        route.push(graphData.node[node_id]);
      });

      return route;
    }
    return [];
  }
  function onSuggestionSelected(event, { suggestion }) {
    clearNavigation();
    if (softKeyboard) {
      keyboardInput.current.setInput(suggestion.name);
      setShowKeyboard(false);
    }
    let responseJson = findPathByNids(suggestion.shop_id, suggestion.shop_id);
    setActiveItem({
      x: responseJson[0].x,
      y: responseJson[0].y,
      ...suggestion,
    });
    setSuggestion(suggestion.shop_title);
    setCurrentFloor(suggestion.shop_locals[0][1]);
    // setSuggestions(null);
  }
  function renderShop() {
    let shop_id = pressed_item_state.shop_id;
    let toShop = pressed_item_state;

    return (
      <div className="InnerContent">
        <div className="Close" onClick={closeShop}>
          <Icon path={mdiClose} title="Back" size={2} color="black" />
        </div>
        <div className="Sidebar">
          <div className="TitleWrapper">
            <div className="Text">{unEntity(toShop.shop_title)}</div>
          </div>
          {toShop.info ? (
            <div className="Info">
              <div className="ContactHeader">{t("shop.contact")}</div>
              {toShop.info.map((el) => {
                return (
                  <div className="InfoElement">
                    <div className="InfoLabel">{el[0]}</div>
                    <div className="InfoValue">{el[1]}</div>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="Location">
            <div className="InfoLine">
              <div className="LocationText">{t("shop.floor")}</div>
              <div className="LocationNumber">{toShop.shop_floors}</div>
            </div>
            <div className="InfoLine">
              <div className="LocationText">{t("shop.venue")}</div>
              <div className="LocationNumber">{toShop.shop_numbers}</div>
            </div>
          </div>
        </div>
        <div className="Content">
          <div className="ImageWrapper">
            <img src={toShop.display_image} />
          </div>
          <div className="TextWrapper">
            <div
              className="Description"
              dangerouslySetInnerHTML={{
                __html: unEntity(toShop.shop_description),
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
  function renderFloor(floor) {
    return (
      <Floor
        floor={floor}
        wfMarker={wf_marker_state}
        activeItem={active_item_state}
        currentFloor={current_floor_state}
        nextIndex={next_index_state}
        fromItem={from_item_state}
        pressedItem={pressed_item_state}
        shops={shops}
        setPressedItem={setPressedItem}
        setActiveItem={setActiveItem}
        marker={marker_state}
        pressStore={pressShop}
        goToShop={goToShop}
        ztShops={ztShops}
        floorMarker={floor_marker_state}
        navigationPath={navigation_path_state}
        navigateNextPath={navigateNextPath}
        findPathByNids={findPathByNids}
      />
    );
  }

  const inputProps = {
    placeholder: t("navigation.search"),
    value: suggestion_state,
    onChange: onChange,
    className: "MapSearch",
    onFocus: onFocus,
  };

  return (
    <div className="MapNavigation">
      <Back onBack={() => hideModule()} />
      {show_keyboard_state && softKeyboard ? (
        <div className="Keyboard">
          <Keyboard
            onChange={onChangeKeyboard}
            layout={{
              default: [
                "1 2 3 4 5 6 7 8 9 0 - {bksp}",
                "Q W E R T Y U I O P",
                "A S D F G H J K L &",
                "Z X C V B N M , . /",
                "{space}",
              ],
            }}
            layoutName={"default"}
            keyboardRef={(r) => (keyboardInput.current = r)}
          />
        </div>
      ) : null}
      <div className="FloorContainer">
        <div className="InnerFloor">
          {current_floor_state ? renderFloor(current_floor_state) : null}
        </div>
      </div>
      <div className="ButtonContainer">
        <div className="SearchContainer">
          <Autosuggest
            alwaysRenderSuggestions={false}
            suggestions={suggestions_state}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={onSuggestionSelected}
          />
          {active_item_state ? (
            <button
              className="FloorButton Navigate"
              onClick={() => findPath(wfName, active_item_state)}
            >
              <div className="Text Large">{t("navigation.navigate")}</div>
            </button>
          ) : null}
        </div>
        <div className="ButtonWrapper">
          <div
            className={
              current_floor_state === "-1"
                ? "FloorButton active"
                : "FloorButton"
            }
            onClick={() => {
              setFrom(null);
              setActiveItem(null);
              setPressedItem(null);
              setNaviPaths(null);
              setNavigationPath(null);
              changeFloor("-1");
            }}
          >
            <div className="Text">-1</div>
          </div>
        </div>
        <div className="ButtonWrapper">
          <div
            className={
              current_floor_state === "0" ? "FloorButton active" : "FloorButton"
            }
            onClick={() => {
              setFrom(null);
              setActiveItem(null);
              setPressedItem(null);
              setNaviPaths(null);
              setNavigationPath(null);
              changeFloor("0");
            }}
          >
            <div className="Text">0</div>
          </div>
        </div>
        <div className="ButtonWrapper">
          <div
            className={
              current_floor_state === "1" ? "FloorButton active" : "FloorButton"
            }
            onClick={() => {
              setFrom(null);
              setActiveItem(null);
              setPressedItem(null);
              setNaviPaths(null);
              setNavigationPath(null);
              changeFloor("1");
            }}
          >
            <div className="Text">1</div>
          </div>
        </div>
        <div className="ButtonWrapper">
          <div
            className={
              current_floor_state === "2" ? "FloorButton active" : "FloorButton"
            }
            onClick={() => {
              setFrom(null);
              setActiveItem(null);
              setPressedItem(null);
              setNaviPaths(null);
              setNavigationPath(null);
              changeFloor("2");
            }}
          >
            <div className="Text">2</div>
          </div>
        </div>
        <div className="ButtonWrapper">
          <div
            className={
              current_floor_state === "3" ? "FloorButton active" : "FloorButton"
            }
            onClick={() => {
              setFrom(null);
              setActiveItem(null);
              setPressedItem(null);
              setNaviPaths(null);
              setNavigationPath(null);
              changeFloor("3");
            }}
          >
            <div className="Text">3</div>
          </div>
        </div>
      </div>
      <div className={show_shop_state ? "ShopContainer show" : "ShopContainer"}>
        {show_shop_state ? renderShop() : null}
      </div>
    </div>
  );
}

export default MapNavigation;
